import {
  Fragment,
  useState,
  useCallback,
  FC,
  PropsWithChildren,
  useEffect,
} from 'react'
import { FullScreenModal } from './fullscreen-modal'
import { DataContainer } from './data-container'
import type { WebsiteListProps } from '@app/types/websitelist'
import { WebSitesDashboard } from '@app/components/general/lists/websites-dashboard'
import { AppManager } from '@app/managers'
import { SortDirection } from './dashboard-website-list'

const defaultModalState = {
  open: false,
  data: null,
  title: '',
  url: '',
  error: '',
}

// returns a list of websites with top level modal for displaying issues.
export const WebsiteList: FC<
  PropsWithChildren<
    WebsiteListProps & {
      onlyLive?: boolean
      storageless?: boolean
      analyticsEnabled?: boolean
      removeActiveCrawl?(x: any): void
      onSortEvent?(_: string, __: SortDirection): void
    }
  >
> = ({
  data,
  error,
  loading,
  removePress,
  emptyHeaderTitle = 'Empty',
  emptyHeaderSubTitle = 'Add your website below',
  refetch,
  crawlWebsite,
  setModal,
  mutatationLoading,
  activeCrawls,
  children,
  onlyLive,
  storageless,
  analyticsEnabled,
  removeActiveCrawl,
  onSortEvent,
  dashboardLayout,
}) => {
  const [modal, setOpen] = useState(defaultModalState)

  const handleClickOpen = useCallback(
    (data: any, title: any, url: any, er: any) => {
      AppManager.setModalActive(true)
      setOpen({ open: true, data, title, url, error: er })
    },
    [setOpen]
  )

  const handleClose = useCallback(() => {
    AppManager.setModalActive(false)
    setOpen((m) => ({ ...m, open: false }))
  }, [setOpen])

  useEffect(() => {
    AppManager.clickOpen = handleClickOpen
  }, [handleClickOpen])

  return (
    <Fragment>
      <DataContainer
        avatar={false}
        dashboard
        emptyHeaderTitle={emptyHeaderTitle}
        emptyHeaderSubTitle={emptyHeaderSubTitle}
        data={data}
        loading={loading}
        error={error}
        onlyLive={onlyLive}
        storageless={storageless}
      >
        <WebSitesDashboard
          handleClickOpen={handleClickOpen}
          onSortEvent={onSortEvent}
          refetch={refetch}
          removePress={removePress}
          crawlWebsite={crawlWebsite}
          setModal={setModal}
          removeActiveCrawl={removeActiveCrawl}
          loading={loading}
          data={data}
          mutatationLoading={mutatationLoading}
          activeCrawls={activeCrawls}
          onlyLive={onlyLive}
          storageless={storageless}
          analyticsEnabled={analyticsEnabled}
          dashboardLayout={dashboardLayout}
        >
          {children}
        </WebSitesDashboard>
      </DataContainer>
      <FullScreenModal
        {...modal}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
        refetch={refetch}
      />
    </Fragment>
  )
}
