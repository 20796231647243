import { memo } from 'react'
import { GrAddCircle, GrSubtractCircle } from 'react-icons/gr'
import { Button } from '../buttons'
import { InputActionsEvents } from './input-actions-events'
import { Input } from '@app/@/components/ui/input'

// TODO: translations
export const InputActionsComponent = (props: any) => {
  const {
    customActions,
    customFields,
    removeFormField,
    addFormField,
    updateFormField,
  } = props

  if (customActions) {
    return (
      <ul className='list-none py-3 space-y-3'>
        {customFields?.map((item: any, index: number) => {
          const onChangeFormPath = (event: any) =>
            updateFormField(event.target.value, index, 'path')

          const updateFormEvents = (value: string) => {
            updateFormField(value, index, 'events')
          }

          return (
            <li
              className={`flex flex-1 px-1 gap-2 place-items-center`}
              key={index}
            >
              <div className='flex flex-1 flex-col gap-y-2'>
                <Input
                  autoFocus
                  value={item.path}
                  placeholder={'Path name'}
                  required
                  onChange={onChangeFormPath}
                />
                <InputActionsEvents
                  updateFormEvents={updateFormEvents}
                  path={item.path}
                  events={item.events}
                />
              </div>
              {customFields.length > 1 ? (
                <Button
                  aria-label='add Actions field'
                  onClick={() => removeFormField(index)}
                  iconButton
                  className='hover:text-gray-600 hover:border-gray-600'
                  border={false}
                >
                  <GrSubtractCircle className='grIcon' />
                </Button>
              ) : null}
              {index === customFields.length - 1 ? (
                <Button
                  aria-label='add Actions field'
                  onClick={addFormField}
                  className='hover:text-blue-600 hover:border-blue-600'
                  iconButton
                  border={false}
                >
                  <GrAddCircle className='grIcon' />
                </Button>
              ) : null}
            </li>
          )
        })}
      </ul>
    )
  }

  return null
}

export const InputActions = memo(InputActionsComponent)
