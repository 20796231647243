import { memo } from 'react'
import { labelStyle, selectStyle } from '../cells/blocks/input-style'
import { FormControl } from '../form-control'
import { InfoBlock } from '../cells'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { inputContainerStyle } from './css'

const RobotsBoxWrapperInput = ({
  robots,
  url,
  activeSubscription,
  domain,
  onChange,
  translation,
  defaultChecked,
}: {
  robots?: boolean
  url?: string
  domain?: string
  activeSubscription?: boolean
  onChange?(x: any): void
  translation?: string
  defaultChecked?: boolean
}) => {
  const labelId = `${domain || url}-robots-input`

  const { t } = useTranslation(translation || 'dashboard')

  const robotsText = t('robots')

  return (
    <InfoBlock toolTip={t('dashboard-inputs:robots-tip')}>
      <div className={inputContainerStyle}>
        <FormControl
          htmlFor={labelId}
          disabled={!activeSubscription}
          className={labelStyle}
          visible
        >
          {robotsText}
        </FormControl>
        <input
          checked={robots}
          type='checkbox'
          id={labelId}
          defaultChecked={defaultChecked}
          disabled={!activeSubscription}
          onChange={onChange}
          name={'robots'}
          className={selectStyle(activeSubscription)}
        ></input>
      </div>
    </InfoBlock>
  )
}

export const RobotsInput = memo(RobotsBoxWrapperInput)
