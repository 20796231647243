import { Translate } from 'next-translate'

export const APIText = ({
  t,
  textHidden,
}: {
  t: Translate
  textHidden?: boolean
}) => {
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M5 16.75C4.59 16.75 4.25 16.41 4.25 16V9C4.25 8.59 4.59 8.25 5 8.25C5.41 8.25 5.75 8.59 5.75 9V16C5.75 16.41 5.41 16.75 5 16.75Z' />
        <path d='M5.25 9.25C3.04 9.25 1.25 7.46 1.25 5.25C1.25 3.04 3.04 1.25 5.25 1.25C7.46 1.25 9.25 3.04 9.25 5.25C9.25 7.46 7.46 9.25 5.25 9.25ZM5.25 2.75C3.87 2.75 2.75 3.87 2.75 5.25C2.75 6.63 3.87 7.75 5.25 7.75C6.63 7.75 7.75 6.63 7.75 5.25C7.75 3.87 6.63 2.75 5.25 2.75Z' />
        <path d='M5 22.75C2.93 22.75 1.25 21.07 1.25 19C1.25 16.93 2.93 15.25 5 15.25C7.07 15.25 8.75 16.93 8.75 19C8.75 21.07 7.07 22.75 5 22.75ZM5 16.75C3.76 16.75 2.75 17.76 2.75 19C2.75 20.24 3.76 21.25 5 21.25C6.24 21.25 7.25 20.24 7.25 19C7.25 17.76 6.24 16.75 5 16.75Z' />
        <path d='M19 22.75C16.93 22.75 15.25 21.07 15.25 19C15.25 16.93 16.93 15.25 19 15.25C21.07 15.25 22.75 16.93 22.75 19C22.75 21.07 21.07 22.75 19 22.75ZM19 16.75C17.76 16.75 16.75 17.76 16.75 19C16.75 20.24 17.76 21.25 19 21.25C20.24 21.25 21.25 20.24 21.25 19C21.25 17.76 20.24 16.75 19 16.75Z' />
        <path d='M18.1696 16.7905C17.8596 16.7905 17.5696 16.6005 17.4596 16.2905C16.7296 14.1905 14.7496 12.7805 12.5196 12.7805C12.5096 12.7805 12.5096 12.7805 12.4996 12.7805L9.06962 12.7905C9.05962 12.7905 9.05962 12.7905 9.04962 12.7905C6.85962 12.7905 4.94962 11.3105 4.39962 9.19048C4.29962 8.79048 4.53962 8.38048 4.93962 8.28048C5.33962 8.18048 5.74962 8.42049 5.84962 8.82049C6.22962 10.2805 7.53962 11.3005 9.04962 11.3005H9.05962L12.4896 11.2905C12.4996 11.2905 12.5096 11.2905 12.5196 11.2905C15.3796 11.2905 17.9296 13.1005 18.8696 15.8105C19.0096 16.2005 18.7996 16.6305 18.4096 16.7605C18.3296 16.7705 18.2496 16.7905 18.1696 16.7905Z' />
      </svg>
      <span className={`${textHidden ? 'hidden' : ''}`}>{t('api-info')}</span>
    </>
  )
}
