import gql from 'graphql-tag'

export const UPDATE_WEBSITE = gql`
  mutation UpdateWebsite(
    $url: String
    $customHeaders: [CreatePageHeaders]
    $mobile: Boolean
    $standard: String
    $ua: String
    $actions: [PageActionsInput]
    $tld: Boolean
    $subdomains: Boolean
    $ignore: [String]
    $rules: [String]
    $runners: [String]
    $proxy: String
    $monitoringEnabled: Boolean
    $crawlDelay: Int
    $sitemap: Int
    $rootElement: String
    $hideElements: [String]
    $theme: [String]
    $warningsEnabled: Boolean
    $crawlLimit: Int
    $robots: Boolean
    $blacklist: [String]
    $webhooks: WebhooksInput
    $profileVisible: Boolean
    $label: String
  ) {
    updateWebsite(
      url: $url
      customHeaders: $customHeaders
      mobile: $mobile
      standard: $standard
      ua: $ua
      actions: $actions
      tld: $tld
      subdomains: $subdomains
      ignore: $ignore
      rules: $rules
      runners: $runners
      proxy: $proxy
      monitoringEnabled: $monitoringEnabled
      crawlDelay: $crawlDelay
      sitemap: $sitemap
      rootElement: $rootElement
      hideElements: $hideElements
      theme: $theme
      warningsEnabled: $warningsEnabled
      crawlLimit: $crawlLimit
      blacklist: $blacklist
      robots: $robots
      webhooks: $webhooks
      profileVisible: $profileVisible
      label: $label
    ) {
      code
      success
      message
      website {
        _id
        url
        domain
        userId
        mobile
        tld
        subdomains
        proxy
        standard
        monitoringEnabled
        crawlDelay
        sitemap
        rootElement
        hideElements
        warningsEnabled
        ua
        crawlLimit
        blacklist
        robots
        profileVisible
        webhooks {
          signature
          website {
            created
            updated
            deleted
          }
          crawl {
            started
            finished
            shutdown
            canceled
          }
          account {
            exceeded
          }
          endpoint
        }
        pageHeaders {
          key
          value
        }
        actions {
          _id
          path
          events
        }
        runners
        rules
        ignore
        theme
      }
    }
  }
`
