import {
  createContext,
  useContext,
  FC,
  Fragment,
  PropsWithChildren,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import {
  useDynamicModal,
  defaultProps,
  ModalDataProps,
} from '@app/data/local/useDynamicModal'
import { useMiniPlayer } from '@app/data'
import { Website } from '@app/types'

type BaseProps = FC<PropsWithChildren<{ load?: boolean; demo?: boolean }>>
type SelectedWebsite = Website | undefined | null

// used for detailed split tabs view websites and settings
export enum SelectedWebsiteTabs {
  Overview, // shared with General tab
  Issues,
  History,
  Settings,
  // Main tabs for settings
  Notifications,
  Billing,
  ScheduleScan,
  Delete,
}

// The settings tab.
export enum SettingsTabs {
  General,
  SiteScan,
  SiteConfigurations,
  ActionsAndWebhooks,
  PublishSite,
  DeleteSite,
}

const AppContext = createContext({
  modelData: defaultProps, // dynamic modal data
  miniPlayer: {
    open: false,
    title: '',
    data: null,
  },
  setModal: (_x: ModalDataProps) => {},
  setMiniPlayerContent: (_open?: boolean, _data?: any, _title?: string) => {},
  selectedWebsite: null as SelectedWebsite, // view mode for main display
  setSelectedWebsite: (_website?: Website) => {},
  setSelectedTab: (_tab: SelectedWebsiteTabs) => {},
  selectedTab: SelectedWebsiteTabs.Overview, // view mode for main display
  setSelectedSettingsTab: (_tab: SettingsTabs) => {},
  selectedSettingsTab: SettingsTabs.General, // view mode for settings display
  openDrawer: false,
  setDrawerOpen: ((_x: any) => {}) as Dispatch<SetStateAction<boolean>>,
})

export const InteractiveProviderWrapper = AppContext.Provider

export const InteractiveProviderMain: BaseProps & { demo?: boolean } = ({
  children,
  demo,
}) => {
  const [openDrawer, setDrawerOpen] = useState<boolean>(false)
  const { miniPlayer, setMiniPlayerContent } = useMiniPlayer()
  const { modelData, setModal } = useDynamicModal({ demo })
  const [selectedWebsite, setSelectedWebsite] =
    useState<SelectedWebsite>(undefined)
  const [selectedTab, setSelectedTab] = useState<SelectedWebsiteTabs>(
    SelectedWebsiteTabs.Overview
  )
  const [selectedSettingsTab, setSelectedSettingsTab] = useState<SettingsTabs>(
    SettingsTabs.General
  )

  return (
    <InteractiveProviderWrapper
      value={{
        modelData,
        setModal,
        miniPlayer,
        setMiniPlayerContent,
        selectedWebsite,
        setSelectedWebsite,
        setSelectedTab,
        selectedTab,
        selectedSettingsTab,
        setSelectedSettingsTab,
        openDrawer,
        setDrawerOpen,
      }}
    >
      {children}
    </InteractiveProviderWrapper>
  )
}

export const InteractiveProvider: BaseProps = ({ children, load, demo }) => {
  if (!load) {
    return <Fragment>{children}</Fragment>
  }
  return (
    <InteractiveProviderMain demo={demo}>{children}</InteractiveProviderMain>
  )
}

export function useInteractiveContext() {
  return useContext(AppContext)
}
