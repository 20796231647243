import React, { memo } from 'react'
import { AnalyticsCard } from './card/analytics-card'
import { classNames } from '@app/utils/classes'
import { listHeight } from './card/analytics-style'
import { IssueMeta, User } from '@app/types'
import { LiveItem } from '@app/types/websitelist'

interface RenderAnalyticsProps {
  account: User & { analyticsDisabled?: boolean }
  issuesInfo?: IssueMeta
  domain?: string
  liveData: LiveItem[]
  onlyLive?: boolean
  analyticsEnabled?: boolean
}

// analytics cell inner component
const RenderAnalyticsComponent = ({
  account,
  issuesInfo, // the issues info returned from the network
  domain,
  liveData,
  onlyLive,
  analyticsEnabled,
  pageUrl,
}: RenderAnalyticsProps & { pageUrl?: string }) => {
  // prevent view from loading
  if (
    !account ||
    account.analyticsDisabled ||
    !analyticsEnabled ||
    !account.activeSubscription
  ) {
    return null
  }

  // liveFeed exist
  const liveItems = !!liveData?.length

  if (issuesInfo || liveItems) {
    // we would need the live feed item to prevent live items rendering when empty too
    const hideChart = !liveItems && issuesInfo?.totalIssues == 0

    // prevent the analytics recursive component from firing.
    if (hideChart) {
      return null
    }

    return (
      <div className={'relative'}>
        <AnalyticsCard
          domain={domain}
          pageUrl={pageUrl}
          liveData={liveData}
          onlyLive={onlyLive}
        />
      </div>
    )
  }

  return (
    <div className='relative'>
      <div
        className={classNames(
          'w-full bg-gray-100 dark:bg-gray-800',
          listHeight
        )}
      ></div>
    </div>
  )
}

export const RenderAnalytics = memo(RenderAnalyticsComponent)
