import { memo } from 'react'
import { labelStyle, selectStyle } from '../cells/blocks/input-style'
import { FormControl } from '../form-control'
import { InfoBlock } from '../cells'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { inputContainerStyle } from './css'

const MobileBoxWrapperInput = ({
  mobile,
  url,
  domain,
  activeSubscription,
  onChange,
  translation,
  defaultChecked,
}: {
  mobile?: boolean
  url?: string
  domain?: string
  activeSubscription?: boolean
  onChange?(a: any): void
  translation?: string
  defaultChecked?: boolean
}) => {
  const labelId = `${domain || url}-mobile-form`
  const { t } = useTranslation(translation || 'dashboard')

  return (
    <InfoBlock toolTip={t('dashboard-inputs:mobile-tip')}>
      <div className={inputContainerStyle}>
        <FormControl
          htmlFor={labelId}
          disabled={!activeSubscription}
          className={labelStyle}
          visible
        >
          {t('mobile')}
        </FormControl>

        <input
          checked={mobile}
          type='checkbox'
          id={labelId}
          onChange={onChange}
          disabled={!activeSubscription}
          name={'mobile_viewport'}
          defaultChecked={defaultChecked}
          className={selectStyle(activeSubscription)}
        ></input>
      </div>
    </InfoBlock>
  )
}

export const MobileInput = memo(MobileBoxWrapperInput)
