import { memo, useMemo } from 'react'
import {
  GrDocumentCsv,
  GrDocumentExcel,
  GrHide,
  GrTrash,
  GrView,
} from 'react-icons/gr'
import { Link } from '@app/components/stateless/typo/link'
import { apiRoute } from '@app/configs/api-route'
import { classNames } from '@app/utils/classes'
import type { Translate } from 'next-translate'
import { useTranslation } from '@app/lib/hooks/useTranslation'

type CellHeaderProps = {
  url: string
  encodedUrl?: string
  setVisible(x: any): void
  onRemovePress?(x?: string): Promise<void>
  onBlacklistEvent?(x?: string): Promise<void>
  visible?: boolean
  totalIssues?: number
  warningCount?: number
  errorCount?: number
  domain: string
  totalErrors: number
  extraActive?: boolean | string
  hidden?: boolean
  selected?: boolean
  demo?: boolean
  active?: boolean | string
  blacklist?: string[]
  t: Translate
}

const ActiveButtonRow = ({
  encodedUrl,
  demo,
  active,
  onRemovePress,
  url,
  onBlacklistEvent,
  blacklist,
}: Partial<CellHeaderProps>) => {
  const { t } = useTranslation('edit-forms')

  const removeItems = async () => {
    onRemovePress && (await onRemovePress(url))
  }

  const onBlacklistClick = async () => {
    onBlacklistEvent && (await onBlacklistEvent(url))
  }

  // get the pathname to the url
  const pName = useMemo(() => {
    let p = url

    if (!url || active !== 'edit') {
      return ''
    }

    try {
      p = new URL(url).pathname
    } catch (e) {
      console.error(e)
    }
    return p
  }, [url, active])

  if (active === 'edit') {
    const showItem = blacklist && blacklist.some((u) => u === pName)

    return (
      <div className='grid grid-cols-2 gap-4 auto-cols-max place-items-center ltr:text-right pr-4'>
        <button
          type={'button'}
          disabled={demo}
          title={showItem ? t('a-blacklist') : t('r-blacklist')}
          onClick={onBlacklistClick}
          className={`min-w-[3.75rem] ${
            showItem ? 'hover:text-blue-700' : 'hover:text-purple-700'
          } flex text-xs place-items-center place-content-end`}
        >
          <>
            <span className='sr-only'>{t('blist-path')}</span>
            {showItem ? (
              <GrView className='grIcon text-xs' />
            ) : (
              <GrHide className='grIcon text-sm' />
            )}
          </>
        </button>
        <button
          type={'button'}
          disabled={demo}
          onClick={removeItems}
          className='min-w-[3.75rem] hover:text-red-700 flex place-content-end'
        >
          <>
            <span className='sr-only'>{t('r-page')}</span>
            <GrTrash className='grIcon text-xs' />
          </>
        </button>
      </div>
    )
  }
  return (
    <div className='grid grid-cols-2 gap-4 auto-cols-max place-items-center pr-4'>
      {demo ? (
        <button
          type={'button'}
          disabled
          className='min-w-[3.75rem] hover:text-blue-700 flex place-content-end'
        >
          <>
            <span className='sr-only'>Download Excel</span>
            <GrDocumentExcel className='grIcon text-xs' />
          </>
        </button>
      ) : (
        <Link
          href={`${apiRoute}/report?download=true&q=${encodedUrl}`}
          className='min-w-[3.75rem] hover:text-blue-700 flex place-content-end'
        >
          <>
            <span className='sr-only'>Download Excel</span>
            <GrDocumentExcel className='grIcon text-xs' />
          </>
        </Link>
      )}
      {demo ? (
        <button
          type={'button'}
          disabled
          className={'hover:text-blue-700 flex place-content-end w-16'}
        >
          <>
            <span className='sr-only'>Download CSV</span>
            <GrDocumentCsv className='grIcon text-xs' />
          </>
        </button>
      ) : (
        <Link
          href={`${apiRoute}/report?csv=true&q=${encodedUrl}`}
          className={'hover:text-blue-700 flex place-content-end w-16'}
        >
          <>
            <span className='sr-only'>Download CSV</span>
            <GrDocumentCsv className='grIcon text-xs' />
          </>
        </Link>
      )}
    </div>
  )
}

const ListCellAnalyticsHeaderW = ({
  url,
  setVisible,
  visible,
  warningCount,
  errorCount,
  totalErrors,
  extraActive,
  hidden,
  selected,
  demo,
  onRemovePress,
  onBlacklistEvent,
  blacklist,
  t,
}: CellHeaderProps) => {
  const errorPercentage = useMemo(
    () =>
      (
        Math.ceil((((errorCount || 1) / (totalErrors || 1)) * 100) / 5) * 5
      ).toFixed(0),
    [totalErrors, errorCount]
  )

  const { encodedUrl, toggleText } = useMemo(
    () =>
      url
        ? {
            toggleText: url.replace(
              url.startsWith('https://') ? 'https://' : 'http://',
              ''
            ),
            encodedUrl: encodeURIComponent(url),
          }
        : {
            toggleText: '',
            encodedUrl: '',
          },
    [url]
  )

  const dynamicErrorClassName = `w-${errorPercentage}-after`
  const disabled = !warningCount && !errorCount

  // prevent empty pages safety
  if (!url) {
    return null
  }

  // return a small single row of the page and issues with a dropdown
  return (
    <>
      <div
        className={classNames(
          'flex place-items-center text-xs md:text-sm row-bg ltr:after:left-[4px] rtl:after:right-[4px] relative hover:after:bg-transparent',
          errorCount ? dynamicErrorClassName : '',
          hidden ? 'hidden' : 'block',
          selected
            ? 'border-b dark:border-gray-600'
            : 'border-b border-transparent'
        )}
        aria-hidden={!!hidden}
      >
        <button
          className={classNames(
            `px-4 py-2 ltr:text-left rtl:text-right place-items-center flex-1 max-w-2/3 md:w-auto line-clamp-1 truncate`,
            !disabled ? 'hover:text-blue-600' : ''
          )}
          onClick={setVisible}
          aria-expanded={visible}
          disabled={disabled}
          aria-label={t('dashboard:toggle-visible', { url })}
          type={'button'}
        >
          {toggleText}
        </button>
        {extraActive ? (
          <ActiveButtonRow
            demo={demo}
            encodedUrl={encodedUrl}
            url={url}
            active={extraActive}
            onBlacklistEvent={onBlacklistEvent}
            onRemovePress={onRemovePress}
            blacklist={blacklist}
          />
        ) : (
          <div className='grid grid-cols-2 gap-4 auto-cols-max pr-4 text-right'>
            <div>{warningCount}</div>
            <div className='w-[3.8rem]'>{errorCount}</div>
          </div>
        )}
      </div>
    </>
  )
}

export const ListCellAnalyticsHeader = memo(ListCellAnalyticsHeaderW)
