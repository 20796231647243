import { Card, CardContent } from '@app/@/components/ui/card'
import { Website } from '@app/types'
import { format } from 'date-fns'
import { Translate } from 'next-translate'
import { useMemo } from 'react'

export const SyncStatus = ({
  website,
  t,
  isLiveData,
}: {
  website: Website
  t: Translate
  isLiveData?: boolean
}) => {
  const formatedTime = useMemo(() => {
    let _formatedTime = null

    const dateStr =
      typeof website?.lastScanDate === 'string'
        ? parseInt(website?.lastScanDate, 10)
        : website?.lastScanDate

    const dateTime =
      typeof dateStr === 'number'
        ? new Date(dateStr)
        : website?.lastScanDate
          ? new Date(website?.lastScanDate)
          : new Date()

    try {
      _formatedTime = format(dateTime, 'MM/dd/yy')
    } catch (_e) {}

    return _formatedTime
  }, [website])

  return (
    <Card>
      <div className='flex gap-3 flex-1 pt-6'>
        <div className='flex-1'>
          <CardContent className='space-y-3'>
            <h4 className='font-bold text-lg flex gap-2 items-center'>
              {isLiveData ? (
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 48 48'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <title>{t('sync')}</title>
                  <circle cx='8' cy='24' r='4' fill='#006CF2' />
                  <circle cx='24' cy='24' r='4' fill='#006CF2' />
                  <circle cx='40' cy='24' r='4' fill='#006CF2' />
                </svg>
              ) : (
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    cx='12'
                    cy='12'
                    r='12'
                    fill='#08AF04'
                    fillOpacity='0.15'
                  />
                  <circle
                    cx='12'
                    cy='12'
                    r='8'
                    fill='#08AF04'
                    fillOpacity='0.15'
                  />
                  <circle cx='12' cy='12' r='4' fill='#08AF04' />
                </svg>
              )}
              {isLiveData ? t('syncing') : t('sync-complete')}
            </h4>

            <h5 className='text-gray-600 dark:text-gray-300 leading-8 text-base'>
              {t('last-sync')}
            </h5>
            <time className='font-bold' dateTime={website?.lastScanDate}>
              {formatedTime}
            </time>
          </CardContent>
        </div>
        <div className='px-4'>
          <svg
            width='48'
            height='48'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              width='48'
              height='48'
              rx='24'
              fill='#006CF2'
              fillOpacity='0.1'
            />
            <path
              d='M24 34.75C18.07 34.75 13.25 29.93 13.25 24C13.25 18.07 18.07 13.25 24 13.25C29.93 13.25 34.75 18.07 34.75 24C34.75 29.93 29.93 34.75 24 34.75ZM24 14.75C18.9 14.75 14.75 18.9 14.75 24C14.75 29.1 18.9 33.25 24 33.25C29.1 33.25 33.25 29.1 33.25 24C33.25 18.9 29.1 14.75 24 14.75Z'
              fill='#006CF2'
            />
            <path
              d='M27.7106 27.9298C27.5806 27.9298 27.4506 27.8998 27.3306 27.8198L24.2306 25.9698C23.4606 25.5098 22.8906 24.4998 22.8906 23.6098V19.5098C22.8906 19.0998 23.2306 18.7598 23.6406 18.7598C24.0506 18.7598 24.3906 19.0998 24.3906 19.5098V23.6098C24.3906 23.9698 24.6906 24.4998 25.0006 24.6798L28.1006 26.5298C28.4606 26.7398 28.5706 27.1998 28.3606 27.5598C28.2106 27.7998 27.9606 27.9298 27.7106 27.9298Z'
              fill='#006CF2'
            />
          </svg>
        </div>
      </div>
    </Card>
  )
}
