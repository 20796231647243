import { FC, PropsWithChildren } from 'react'

export const P: FC<PropsWithChildren & { className?: string }> = ({
  children,
  className,
}) => {
  return (
    <p
      className={`text-gray-800 dark:text-gray-200 leading-relaxed${className ? ` ${className}` : ''}`}
    >
      {children}
    </p>
  )
}
