import React, { memo, useState } from 'react'
import { InputActions } from '@app/components/general'
import { InputHeaders } from './forms/input-headers'
import { useWebsiteContext } from '../providers/website'
import { WCAGSelectInput } from './select/select-input'
import { FormControl } from './form-control'
import { useAuthContext } from '../providers/auth'
import { Checkbox } from './check-box'
import { RunnerSelect } from './runner-select'
import {
  SitemapKeys,
  SiteMapOption,
  SitemapSelectInput,
} from './select/sitemap-input'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { ThemeInput } from './select/theme-input'
import { CrawlLimitBoxInput } from './inputs/crawl-limit-input'
import { BlacklistInput } from './inputs/blacklist-input'
import { ProxyBoxInput } from './inputs/proxy-input'
import { HideElementsInput } from './inputs/hide-elements-input'
import { RootSelectorInput } from './inputs/root-selector-input'
import { AgentInput } from './inputs/ua-input'
import { CrawlDelayBoxInput } from './inputs/crawl-delay-input'
import { TooltipProvider } from '@app/@/components/ui/tooltip'
import { IgnoreInput } from './select/ignore-input'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@app/@/components/ui/collapsible'
import { Separator } from '@app/@/components/ui/separator'
import { InfoBlock } from './cells'
import { Website } from '@app/types'

interface FormDialogProps {
  buttonTitle?: string | JSX.Element
  okPress?: (a: any) => void
  buttonStyles?: string
  icon?: boolean | 'add' // show btn with icon
  iconButton?: boolean
  dialogDisabled?: boolean
  translation?: string
  website?: Website
}

const checkBoxContainerStyles =
  'flex py-2.5 place-items-center min-w-[85px] gap-x-1.5'

const checkboxStyle = 'w-6 h-6'

function DashboardFormDialogWrapper({ translation, website }: FormDialogProps) {
  const { t } = useTranslation(translation || 'dashboard')
  const { account } = useAuthContext()
  const [collaspeOpen, onSetOpenCollaspe] = useState<boolean>(false)
  const { addWebsiteForm, updateWebsite } = useWebsiteContext()

  // fields
  const customActions = addWebsiteForm.actions.customActions
  // headers
  const customHeader = addWebsiteForm.headers.customHeader

  const onMobileViewPortEvent = async () => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          mobile: !website?.mobile,
        },
      })
    } else {
      addWebsiteForm?.onMobileViewPortEvent()
    }
  }

  const onChangeRobotsEvent = async () => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          robots: !website?.robots,
        },
      })
    } else {
      addWebsiteForm?.onChangeRobotsEvent()
    }
  }

  const onChangeMonitoringEvent = async () => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          monitoringEnabled: !website?.monitoringEnabled,
        },
      })
    } else {
      addWebsiteForm?.onChangeMonitoringEvent()
    }
  }

  const onToggleWarnings = async () => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          warningsEnabled: !website?.warningsEnabled,
        },
      })
    } else {
      addWebsiteForm?.onToggleWarnings()
    }
  }

  const onChangeActionsEvent = async () => {
    addWebsiteForm?.onChangeActionsEvent()
  }
  const onChangeSubdomainsEvent = async () => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          subdomains: !website?.subdomains,
        },
      })
    } else {
      addWebsiteForm?.onChangeSubdomainsEvent()
    }
  }

  const onChangeTldEvent = async () => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          tld: !website?.tld,
        },
      })
    } else {
      addWebsiteForm?.onChangeTldEvent()
    }
  }

  const onCrawlLimitChange = async (event: React.ChangeEvent<any>) => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          crawlLimit: Number(event.target.value),
        },
      })
    } else {
      addWebsiteForm?.onChangeUA(event)
    }
  }

  const onChangeUA = async (event: React.ChangeEvent<any>) => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          ua: event.target.value,
        },
      })
    } else {
      addWebsiteForm?.onCrawlLimitChange(event)
    }
  }

  const onChangeCrawlDelay = async (event: React.ChangeEvent<any>) => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          crawlDelay: Number(event.target.value || 0),
        },
      })
    } else {
      addWebsiteForm?.onChangeCrawlDelay(event)
    }
  }

  const onChangeRootSelector = async (event: React.ChangeEvent<any>) => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          rootElement: event.target.value,
        },
      })
    } else {
      addWebsiteForm?.onChangeRootSelector(event)
    }
  }

  const onChangeProxy = async (event: React.ChangeEvent<any>) => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          proxy: event.target.value,
        },
      })
    } else {
      addWebsiteForm?.onChangeProxy(event)
    }
  }

  const onChangeHideElements = async (event: React.ChangeEvent<any>) => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          hideElements: event.target.value,
        },
      })
    } else {
      addWebsiteForm?.onChangeHideElements(event)
    }
  }
  const onBlacklistChange = async (event: React.ChangeEvent<any>) => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          blacklist: event.target.value,
        },
      })
    } else {
      addWebsiteForm?.onBlacklistChange(event)
    }
  }

  const setIgnore = async (ignored: (arr: any[]) => string[]) => {
    if (website) {
      const ignoreList = ignored(website?.ignore ?? [])
      await updateWebsite({
        variables: {
          url: website.url,
          ignore: ignoreList,
        },
      })
    } else {
      addWebsiteForm?.setIgnore(ignored)
    }
  }

  const onStandardChange = async (event: React.ChangeEvent<any>) => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          standard: event.target.value,
        },
      })
    } else {
      addWebsiteForm?.onStandardChange(event)
    }
  }
  const onChangeSitemapEvent = async (event: React.ChangeEvent<any>) => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          sitemap: SiteMapOption[event.target.value as SitemapKeys],
        },
      })
    } else {
      addWebsiteForm?.onChangeSitemapEvent(event)
    }
  }
  const onRunnerEvent = async (event: string[]) => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          runners: event,
        },
      })
    } else {
      addWebsiteForm?.onRunnerEvent(event)
    }
  }
  const onThemeChange = async (event: React.ChangeEvent<any>) => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          theme: event.target.value,
        },
      })
    } else {
      addWebsiteForm?.onThemeChange(event)
    }
  }

  return (
    <TooltipProvider>
      <form onSubmit={addWebsiteForm.submit} noValidate className='pt-4 w-full'>
        <div className='space-y-3'>
          <div className='overflow-y-auto max-h-[50vh] gap-3 px-1 grid grid-cols-1 md:grid-cols-2'>
            <div className='overflow-hidden'>
              <h3 className='text-base font-semibold'>Scan Configurations</h3>
              <div className={`grid grid-cols-2 py-2`}>
                {account?.role === 11 ? null : (
                  <InfoBlock
                    simpleClass={checkBoxContainerStyles}
                    toolTip={t('dashboard-inputs:mobile-tip')}
                  >
                    <Checkbox
                      onChange={onMobileViewPortEvent}
                      defaultChecked={
                        website?.mobile ?? addWebsiteForm.mobileViewport
                      }
                      id={'mobile'}
                      className={checkboxStyle}
                    />
                    <FormControl htmlFor='mobile' visible>
                      {t('mobile')}
                    </FormControl>
                  </InfoBlock>
                )}

                <InfoBlock
                  simpleClass={checkBoxContainerStyles}
                  toolTip={t('dashboard-inputs:robots-tip')}
                >
                  <Checkbox
                    onChange={onChangeRobotsEvent}
                    id={'robots'}
                    defaultChecked={website?.robots ?? addWebsiteForm.robots}
                    className={checkboxStyle}
                  />
                  <FormControl htmlFor='robots' visible>
                    {t('robots')}
                  </FormControl>
                </InfoBlock>

                {account?.role === 11 ? null : (
                  <InfoBlock
                    simpleClass={checkBoxContainerStyles}
                    toolTip={t('dashboard-inputs:monitor-tip')}
                  >
                    <Checkbox
                      onChange={onChangeMonitoringEvent}
                      defaultChecked={
                        website?.monitoringEnabled ??
                        addWebsiteForm.monitoringEnabled
                      }
                      id={'monitoring'}
                      className={checkboxStyle}
                    />
                    <FormControl htmlFor='monitoring' visible>
                      {t('monitor')}
                    </FormControl>
                  </InfoBlock>
                )}

                <InfoBlock
                  simpleClass={checkBoxContainerStyles}
                  toolTip={t('dashboard-inputs:warnings-tip')}
                >
                  <Checkbox
                    onChange={onToggleWarnings}
                    defaultChecked={
                      website?.warningsEnabled ?? addWebsiteForm.warningsEnabled
                    }
                    id={'warnings'}
                    className={checkboxStyle}
                  />
                  <FormControl htmlFor='warnings' visible>
                    {t('warnings')}
                  </FormControl>
                </InfoBlock>

                {account?.role === 11 ? null : (
                  <InfoBlock
                    simpleClass={checkBoxContainerStyles}
                    toolTip={t('dashboard-inputs:actions-tip')}
                  >
                    <Checkbox
                      onChange={onChangeActionsEvent}
                      defaultChecked={
                        website?.actionsEnabled ?? addWebsiteForm.customActions
                      }
                      id={'actions'}
                      className={checkboxStyle}
                    />
                    <FormControl htmlFor='actions' visible>
                      {t('actions')}
                    </FormControl>
                  </InfoBlock>
                )}

                <InfoBlock
                  simpleClass={checkBoxContainerStyles}
                  toolTip={t('dashboard-inputs:headers-tip')}
                >
                  <Checkbox
                    onChange={addWebsiteForm.onChangeHeadersEvent}
                    id={'headers'}
                    defaultChecked={
                      website?.pageHeaders ?? addWebsiteForm.customHeader
                    }
                    className={checkboxStyle}
                  />
                  <FormControl htmlFor='headers' visible>
                    {t('headers')}
                  </FormControl>
                </InfoBlock>

                <InfoBlock
                  simpleClass={checkBoxContainerStyles}
                  toolTip={t('dashboard-inputs:subdomains-tip')}
                >
                  <Checkbox
                    defaultChecked={
                      website?.subdomains ?? addWebsiteForm.subdomains
                    }
                    onChange={onChangeSubdomainsEvent}
                    id={'subdomains'}
                    className={checkboxStyle}
                  />
                  <FormControl htmlFor='subdomains' visible>
                    {t('subdomains')}
                  </FormControl>
                </InfoBlock>

                <InfoBlock
                  simpleClass={checkBoxContainerStyles}
                  toolTip={t('dashboard-inputs:tlds-tip')}
                >
                  <Checkbox
                    onChange={onChangeTldEvent}
                    defaultChecked={website?.tld ?? addWebsiteForm.tld}
                    id={'tlds'}
                    className={checkboxStyle}
                  />
                  <FormControl htmlFor='tlds' visible>
                    {t('tlds')}
                  </FormControl>
                </InfoBlock>
              </div>
              {customHeader ? (
                <InputHeaders {...addWebsiteForm.headers} />
              ) : null}
              {customActions ? (
                <InputActions {...addWebsiteForm.actions} />
              ) : null}
            </div>
            <div>
              <h3 className='text-base font-semibold'>Site Personalizations</h3>
              <div className={`grid grid-cols-1 w-full flex-1 gap-3 py-2`}>
                <WCAGSelectInput
                  onStandardChange={onStandardChange}
                  defaultValue={website?.standard ?? addWebsiteForm.standard}
                  className={'w-full flex-1'}
                  spacing
                  borderLess
                />

                <SitemapSelectInput
                  defaultValue={
                    SiteMapOption[
                      website?.sitemap ?? addWebsiteForm.sitemap
                    ] as SitemapKeys
                  }
                  onSitemapChange={onChangeSitemapEvent}
                  label={t('sitemap')}
                  labelOptions={t('sitemap-options')}
                  borderLess
                  t={t}
                  className={'w-full flex-1'}
                />

                <RunnerSelect
                  cb={onRunnerEvent}
                  borderLess
                  defaultRunners={
                    website?.runners ?? addWebsiteForm.defaultRunnerData
                  }
                  className={'w-full flex-1'}
                />

                <ThemeInput
                  theme={website?.theme ?? addWebsiteForm.theme}
                  onStandardChange={onThemeChange}
                  spacing
                  borderLess
                  className={'w-full flex-1'}
                />
              </div>
            </div>
          </div>

          <Separator />

          <div className='py-3'>
            <Collapsible onOpenChange={onSetOpenCollaspe}>
              <CollapsibleTrigger
                className={`w-full border flex place-content-between px-4 py-3 rounded md:text-lg font-bold ${collaspeOpen ? 'rounded-b-none' : ''}`}
              >
                {t('display-more')}
                <svg
                  width='24'
                  height='25'
                  viewBox='0 0 24 25'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <title>Collaspe Icon</title>
                  <path
                    d='M3.33047 9.49973C3.33047 9.68973 3.40047 9.87973 3.55047 10.0297L10.0705 16.5497C11.1305 17.6097 12.8705 17.6097 13.9305 16.5497L20.4505 10.0297C20.7405 9.73973 20.7405 9.25973 20.4505 8.96973C20.1605 8.67973 19.6805 8.67973 19.3905 8.96973L12.8705 15.4897C12.3905 15.9697 11.6105 15.9697 11.1305 15.4897L4.61047 8.96973C4.32047 8.67973 3.84047 8.67973 3.55047 8.96973C3.41047 9.11973 3.33047 9.30973 3.33047 9.49973Z'
                    fill='#020817'
                  />
                </svg>
              </CollapsibleTrigger>
              <CollapsibleContent className='border border-t-0 px-5'>
                <div
                  className={`overflow-hidden grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 py-6 items-center gap-x-4 gap-y-5`}
                >
                  <AgentInput
                    onChangeUA={onChangeUA}
                    ua={addWebsiteForm.ua}
                    defaultValue={website?.ua}
                    id='ua-dialog'
                    activeSubscription={account.activeSubscription}
                    dialog
                  />

                  <CrawlDelayBoxInput
                    id='crawl-delay-dialog'
                    activeSubscription={account.activeSubscription}
                    value={addWebsiteForm.crawlDelay}
                    onChange={onChangeCrawlDelay}
                    dialog
                    defaultValue={website?.crawlDelay}
                  />

                  <RootSelectorInput
                    onChange={onChangeRootSelector}
                    value={addWebsiteForm.rootElement}
                    id='root-dialog'
                    activeSubscription={account.activeSubscription}
                    dialog
                    defaultValue={website?.rootElement}
                  />

                  <HideElementsInput
                    onChangeHideElements={onChangeHideElements}
                    hideElements={addWebsiteForm.hideElements}
                    id='hide-dialog'
                    text={t('hide-elements')}
                    activeSubscription={account.activeSubscription}
                    dialog
                    defaultValue={website?.hideElements?.join(',')}
                  />

                  <ProxyBoxInput
                    onChangeProxy={onChangeProxy}
                    value={addWebsiteForm.proxy}
                    id='proxy-dialog'
                    text={t('proxy')}
                    activeSubscription={account.activeSubscription}
                    dialog
                    defaultValue={website?.proxy}
                  />

                  <CrawlLimitBoxInput
                    id='climit-dialog'
                    onChange={onCrawlLimitChange}
                    activeSubscription={account.activeSubscription}
                    defaultValue={website?.crawlLimit}
                    dialog
                  />

                  <BlacklistInput
                    id='blacklist-dialog'
                    blacklist={addWebsiteForm.blacklist}
                    onChangeBlacklist={onBlacklistChange}
                    activeSubscription={account.activeSubscription}
                    defaultValue={website?.blacklist?.join(',')}
                    dialog
                  />

                  <IgnoreInput
                    t={t}
                    onLoadRulesEvent={addWebsiteForm.onLoadRulesEvent}
                    ignoreList={addWebsiteForm.ignoreList}
                    setIgnore={setIgnore as any}
                    ignore={website?.ignore ?? addWebsiteForm.ignore}
                    activeSubscription={account.activeSubscription}
                    dialog
                    tooltip
                  />
                </div>
              </CollapsibleContent>
            </Collapsible>
          </div>
        </div>
      </form>
    </TooltipProvider>
  )
}

export const DashboardFormDialog = memo(DashboardFormDialogWrapper)
