import { forwardRef, ReactElement, Ref } from 'react'
import { Button } from '@app/components/general/buttons'
import { classNames } from '@app/utils/classes'
import type { Translate } from 'next-translate'

// paging search props
type PagingProps = {
  searchVisible?: boolean
  source: any[]
  blocked: boolean
  loading: boolean
  issueIndex: number
  pages?: boolean
  onLoadEvent(x: any): any
  onPrevSelect(x: any): any
  onSearchSubmit(x: any): any
  onSearchEvent(x: any): any
  t: Translate
  children?: ReactElement
  visiblity?: boolean
  noSearch?: boolean // prevent searching
}

// TODO: translate Prev, Next
const PagingSearch = forwardRef(
  (props: PagingProps, searchRef: Ref<HTMLInputElement>) => {
    const {
      searchVisible: _searchVisible,
      source,
      blocked,
      issueIndex,
      loading,
      onLoadEvent,
      onPrevSelect,
      onSearchSubmit,
      onSearchEvent,
      pages,
      t,
      visiblity = true,
      children,
      noSearch,
    } = props

    const { id, searchName, labelName } = pages
      ? {
          id: 'pages-search',
          searchName: 'q-pages',
          labelName: `${t('search')} ${t('pages').toLowerCase()}`,
        }
      : {
          id: 'issue-search',
          searchName: 'q-issues',
          labelName: `${t('search')} ${t('issues').toLowerCase()}`,
        }

    return (
      <>
        <div
          className={classNames(
            'flex place-items-center place-content-between py-2',
            visiblity ? '' : 'hidden'
          )}
        >
          {noSearch ? null : (
            <div className={'flex place-items-center'}>
              <form
                className={'px-2 flex place-items-center text-lg'}
                onSubmit={onSearchSubmit}
                noValidate
              >
                <label htmlFor={id} className='sr-only'>
                  {labelName}
                </label>
                <input
                  type={'search'}
                  placeholder={labelName}
                  id={id}
                  name={searchName}
                  aria-hidden={!visiblity ? true : undefined}
                  maxLength={300}
                  className={
                    'px-3 py-1.5 border rounded rounded-r-none max-w-[64vw] md:max-w-auto md:max-w-auto dark:border-gray-700 bg-white dark:bg-black'
                  }
                  onChange={onSearchEvent}
                  ref={searchRef}
                />
                <Button
                  className='border-l-0 py-1.5 rounded-l-none bg-primary text-secondary hover:bg-opacity-70'
                  type='submit'
                >
                  {t('search')}
                </Button>
              </form>
            </div>
          )}
        </div>

        <div>{children}</div>
        <div
          className={classNames(
            source && source.length >= 1 ? '' : 'invisible',
            `flex place-items-center place-content-end px-2 gap-2 py-1`
          )}
          aria-hidden={!source || (source && !source?.length)}
        >
          <Button
            onClick={onPrevSelect}
            className={classNames(
              'hover:text-blue-700 border',
              issueIndex ? 'visible' : 'invisible'
            )}
            border={false}
            aria-hidden={!issueIndex}
          >
            <>
              <span>{t('prev-page')}</span>
            </>
          </Button>
          <Button
            disabled={loading}
            border={false}
            onClick={onLoadEvent}
            className={classNames(
              'hover:text-blue-700 border',
              blocked ? 'invisible' : 'visible'
            )}
            aria-hidden={blocked}
          >
            <>
              <span>{t('next-page')}</span>
            </>
          </Button>
        </div>
      </>
    )
  }
)

PagingSearch.displayName = 'PagingSearch'

export { PagingSearch }
