import { memo } from 'react'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { labelStyle, selectStyle } from '../cells/blocks/input-style'
import { FormControl } from '../form-control'
import { InfoBlock } from '../cells'
import { inputContainerStyle } from './css'

type MonotoringEnabledProps = {
  checked?: boolean
  domain?: string
  activeSubscription?: boolean
  onChange(x: any): void
  defaultChecked?: boolean
}

const MonitoringEnabledInputWrapper = ({
  checked,
  onChange,
  activeSubscription,
  domain,
  defaultChecked,
}: MonotoringEnabledProps) => {
  const { t } = useTranslation('dashboard')

  const labelId = `${domain}-monitoring-enabled-form`

  return (
    <InfoBlock toolTip={t('dashboard-inputs:monitor-tip')}>
      <div className={inputContainerStyle}>
        <FormControl
          htmlFor={labelId}
          disabled={!activeSubscription}
          className={labelStyle}
          visible
        >
          {t('monitor')}
        </FormControl>
        <input
          checked={checked}
          type='checkbox'
          id={labelId}
          disabled={!activeSubscription}
          onChange={onChange}
          defaultChecked={defaultChecked}
          name={'monitor_viewport'}
          className={selectStyle(activeSubscription)}
        ></input>
      </div>
    </InfoBlock>
  )
}

export const MonitoringEnabledInput = memo(MonitoringEnabledInputWrapper)
