import { format } from 'date-fns'

// format the time value safely
export const formatDateTime = (
  date?: Date | string | null,
  options?: string
) => {
  let value = null

  try {
    value = format(date || new Date(), options ?? 'MM dd')
  } catch (_e) {}

  if (!value) {
    try {
      value = format(new Date(parseInt(date as string, 10)), options ?? 'MM dd')
    } catch (_e) {}
  }

  return value
}
