import { P } from '@app/components/stateless/typo/p'
import { getStepBtnStyle } from './styles'
import { CompletedCheck } from './completed-check'

export const StepOneMenuItem = ({
  active,
  completed,
}: {
  active?: boolean
  completed?: boolean
}) => {
  return (
    <li className='flex gap-2 min-w-[10rem]'>
      <div>
        {completed && !active ? (
          <CompletedCheck />
        ) : (
          <div className={getStepBtnStyle(active)}>
            <svg
              width='28'
              height='28'
              viewBox='0 0 28 28'
              xmlns='http://www.w3.org/2000/svg'
              className={active ? 'fill-blue-700' : 'fill-gray-700'}
            >
              <path d='M17.5007 15.1084H9.33398C8.85565 15.1084 8.45898 14.7117 8.45898 14.2334C8.45898 13.7551 8.85565 13.3584 9.33398 13.3584H17.5007C17.979 13.3584 18.3757 13.7551 18.3757 14.2334C18.3757 14.7117 17.979 15.1084 17.5007 15.1084Z' />
              <path d='M14.444 19.7754H9.33398C8.85565 19.7754 8.45898 19.3787 8.45898 18.9004C8.45898 18.4221 8.85565 18.0254 9.33398 18.0254H14.444C14.9223 18.0254 15.319 18.4221 15.319 18.9004C15.319 19.3787 14.9223 19.7754 14.444 19.7754Z' />
              <path d='M16.334 7.87467H11.6673C10.5473 7.87467 8.45898 7.87467 8.45898 4.66634C8.45898 1.45801 10.5473 1.45801 11.6673 1.45801H16.334C17.454 1.45801 19.5423 1.45801 19.5423 4.66634C19.5423 5.78634 19.5423 7.87467 16.334 7.87467ZM11.6673 3.20801C10.5123 3.20801 10.209 3.20801 10.209 4.66634C10.209 6.12467 10.5123 6.12467 11.6673 6.12467H16.334C17.7923 6.12467 17.7923 5.82134 17.7923 4.66634C17.7923 3.20801 17.489 3.20801 16.334 3.20801H11.6673Z' />
              <path d='M17.5 26.5412H10.5C3.94333 26.5412 2.625 23.5312 2.625 18.6662V11.6662C2.625 6.34618 4.55 4.07118 9.28667 3.82618C9.75333 3.80285 10.185 4.16451 10.2083 4.65451C10.2317 5.14451 9.85833 5.54118 9.38 5.56451C6.06667 5.75118 4.375 6.74285 4.375 11.6662V18.6662C4.375 22.9828 5.22667 24.7912 10.5 24.7912H17.5C22.7733 24.7912 23.625 22.9828 23.625 18.6662V11.6662C23.625 6.74285 21.9333 5.75118 18.62 5.56451C18.1417 5.54118 17.7683 5.12118 17.7917 4.64285C17.815 4.16451 18.235 3.79118 18.7133 3.81451C23.45 4.07118 25.375 6.34618 25.375 11.6545V18.6545C25.375 23.5312 24.0567 26.5412 17.5 26.5412Z' />
            </svg>
          </div>
        )}
      </div>
      <div>
        <h4 className='xl:text-lg leading-tight'>Step 1/5</h4>
        <P className='text-xs md:text-sm text-muted-foreground'>
          Choose how many sites you want to add?
        </P>
      </div>
    </li>
  )
}
