import { ChangeEventHandler } from 'react'
import { InfoBlock } from '../cells'
import { inputStyle } from '../cells/blocks/input-style'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { InputBoxProps } from '@app/types/dashboard'
import { Input } from '@app/@/components/ui/input'

interface CrawlDelayProps extends InputBoxProps {
  url?: string
  value?: number
  activeSubscription?: boolean
  demo?: boolean
  tooltip?: boolean
  translation?: string
  id?: string
}

interface CrawlDelayInputProps extends CrawlDelayProps {
  onChange?: ChangeEventHandler<HTMLInputElement>
}

export const CrawlDelayBoxInput = ({
  value,
  url,
  activeSubscription,
  tooltip = true,
  translation,
  onChange,
  id,
  dialog,
  defaultValue,
}: CrawlDelayInputProps) => {
  const { t } = useTranslation(translation || 'dashboard')

  const crawlD = `${url || id}-crawl-delay-id`
  const crawlDl = `${url || id}-crawl-delay-list-id`

  const crawlText = t('crawl-throttle')

  return (
    <InfoBlock
      tipID={'delay-id'}
      toolTip={tooltip ? t('dashboard-inputs:crawl-tip') : undefined}
      noPx={dialog}
      labelTip={<label htmlFor={crawlD}>{crawlText}</label>}
      noClass
    >
      <Input
        className={inputStyle(activeSubscription, dialog)}
        onChange={onChange}
        value={value || undefined}
        id={crawlD}
        placeholder={crawlText}
        type='number'
        max={2000}
        min={0}
        defaultValue={defaultValue}
        list={crawlDl}
        name={'crawl_delay'}
      />
      <datalist id={crawlDl}>
        <option value='0'></option>
        <option value='250'></option>
        <option value='500'></option>
        <option value='1000'></option>
        <option value='1500'></option>
        <option value='2000'></option>
      </datalist>
    </InfoBlock>
  )
}
