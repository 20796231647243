import React from 'react'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { inputStyle } from '../cells/blocks/input-style'
import { InfoBlock } from '../cells'
import { InputBoxProps } from '@app/types/dashboard'
import { Input } from '@app/@/components/ui/input'

interface BlacklistProps extends InputBoxProps {
  blacklist?: string
  activeSubscription?: boolean
  translation?: string
  tooltip?: boolean
  id?: string
}

// the agent input
export const BlacklistInput = ({
  activeSubscription,
  onChangeBlacklist,
  blacklist,
  translation,
  tooltip = true,
  url,
  id,
  dialog,
  defaultValue,
}: BlacklistProps & { onChangeBlacklist?(x: any): any }) => {
  const { t } = useTranslation(translation || 'dashboard')

  const agentLabel = t('blacklist')

  const uaLabel = `${url || id || ''}-blacklist-id`

  return (
    <InfoBlock
      tipID={`${url}-blacklist-id`}
      toolTip={tooltip ? t('dashboard-inputs:blacklist-tip') : undefined}
      noPx={dialog}
      labelTip={<label htmlFor={uaLabel}>{agentLabel}</label>}
      noClass
    >
      <Input
        className={inputStyle(activeSubscription, dialog)}
        onChange={onChangeBlacklist}
        value={blacklist}
        id={uaLabel}
        placeholder={agentLabel}
        defaultValue={defaultValue}
        type='text'
        name={'blacklist'}
      />
    </InfoBlock>
  )
}
