import { P } from '@app/components/stateless/typo/p'
import { getStepBtnStyle } from './styles'
import { CompletedCheck } from './completed-check'

export const StepFiveMenuItem = ({
  active,
  completed,
}: {
  active?: boolean
  completed?: boolean
}) => {
  return (
    <li className='flex gap-2 min-w-[10rem]'>
      <div>
        {completed && !active ? (
          <CompletedCheck />
        ) : (
          <div className={getStepBtnStyle(active)}>
            <svg
              width='28'
              height='28'
              viewBox='0 0 28 28'
              xmlns='http://www.w3.org/2000/svg'
              className={active ? 'fill-blue-700' : 'fill-gray-700'}
            >
              <path d='M12.588 17.6985C12.3547 17.6985 12.133 17.6052 11.9697 17.4418L9.14633 14.6185C8.80799 14.2802 8.80799 13.7202 9.14633 13.3818C9.48466 13.0435 10.0447 13.0435 10.383 13.3818L12.588 15.5868L17.6047 10.5702C17.943 10.2318 18.503 10.2318 18.8413 10.5702C19.1797 10.9085 19.1797 11.4685 18.8413 11.8068L13.2063 17.4418C13.043 17.6052 12.8213 17.6985 12.588 17.6985Z' />
              <path d='M14.0007 26.5413C13.2657 26.5413 12.5307 26.2963 11.959 25.8063L10.1157 24.2197C9.92901 24.0563 9.46234 23.893 9.21734 23.893H7.21068C5.48401 23.893 4.08401 22.493 4.08401 20.7663V18.7713C4.08401 18.5263 3.92068 18.0713 3.75734 17.8847L2.18234 16.0297C1.22568 14.898 1.22568 13.113 2.18234 11.9813L3.75734 10.1263C3.92068 9.93967 4.08401 9.48467 4.08401 9.23967V7.23301C4.08401 5.50634 5.48401 4.10634 7.21068 4.10634H9.22901C9.47401 4.10634 9.94068 3.93134 10.1273 3.77967L11.9707 2.19301C13.114 1.21301 14.899 1.21301 16.0423 2.19301L17.8857 3.77967C18.0723 3.94301 18.539 4.10634 18.784 4.10634H20.7673C22.494 4.10634 23.894 5.50634 23.894 7.23301V9.21634C23.894 9.46134 24.069 9.92801 24.2323 10.1147L25.819 11.958C26.799 13.1013 26.799 14.8863 25.819 16.0297L24.2323 17.873C24.069 18.0597 23.894 18.5263 23.894 18.7713V20.7547C23.894 22.4813 22.494 23.8813 20.7673 23.8813H18.784C18.539 23.8813 18.0723 24.0563 17.8857 24.208L16.0423 25.7947C15.4707 26.2963 14.7357 26.5413 14.0007 26.5413ZM7.21068 5.85634C6.45234 5.85634 5.83401 6.47467 5.83401 7.23301V9.22801C5.83401 9.89301 5.51901 10.7447 5.08734 11.2463L3.51234 13.1013C3.10401 13.5797 3.10401 14.408 3.51234 14.8863L5.08734 16.7413C5.51901 17.2547 5.83401 18.0947 5.83401 18.7597V20.7547C5.83401 21.513 6.45234 22.1313 7.21068 22.1313H9.22901C9.90568 22.1313 10.7573 22.4463 11.2707 22.8897L13.114 24.4763C13.5923 24.8847 14.4323 24.8847 14.9107 24.4763L16.754 22.8897C17.2673 22.458 18.119 22.1313 18.7957 22.1313H20.779C21.5373 22.1313 22.1557 21.513 22.1557 20.7547V18.7713C22.1557 18.0947 22.4707 17.243 22.914 16.7297L24.5007 14.8863C24.909 14.408 24.909 13.568 24.5007 13.0897L22.914 11.2463C22.4707 10.733 22.1557 9.88134 22.1557 9.20467V7.23301C22.1557 6.47467 21.5373 5.85634 20.779 5.85634H18.7957C18.119 5.85634 17.2673 5.54134 16.754 5.09801L14.9107 3.51134C14.4323 3.10301 13.5923 3.10301 13.114 3.51134L11.2707 5.10967C10.7573 5.54134 9.89401 5.85634 9.22901 5.85634H7.21068Z' />
            </svg>
          </div>
        )}
      </div>

      <div>
        <h4 className='xl:text-lg leading-tight'>Step 5/5</h4>
        <P className='text-xs md:text-sm text-muted-foreground'>
          Publish You’re Site
        </P>
      </div>
    </li>
  )
}
