import { Button, buttonVariants } from '@app/@/components/ui/button'
import { useWebsiteContext } from '@app/components/providers/website'
import { AddWebsiteStep } from '@app/data/external/website/websites'
import { Translate } from 'next-translate'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@app/@/components/ui/dialog'
import { twMerge } from 'tailwind-merge'
import { softGradient } from '@app/styles/container'

// General step bar
export const StepBar = ({
  title,
  step,
  setAddWebsiteStep,
  t,
}: {
  title?: string
  step: number
  setAddWebsiteStep: (_step: AddWebsiteStep) => void
  t: Translate
}) => {
  const { addWebsiteForm } = useWebsiteContext()

  const onSubmit = () => {
    addWebsiteForm.submit()
  }

  const onBackEvent = () => {
    setAddWebsiteStep(step === 0 ? 0 : --step)
  }

  const onNextEvent = async () => {
    if (step === 4) {
      const valid = (await onSubmit()) as any

      if (valid) {
        addWebsiteForm.setOpen(false)
      }
    }
    setAddWebsiteStep(step >= 4 ? 4 : ++step)
  }

  // set the default settings.
  const onSetDefaultSettings = async () => {
    addWebsiteForm.resetDefaultConfigurations()
    await onNextEvent()
  }

  return (
    <div className={`flex gap-3 items-center py-3 px-2`}>
      <Button
        variant={'secondary'}
        className={`${step === 0 ? 'hidden' : ''}`}
        onClick={onBackEvent}
      >
        <svg
          width='24'
          height='25'
          viewBox='0 0 24 25'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <title>{t('prev-icon')}</title>
          <path
            d='M15.0003 21.17C14.8103 21.17 14.6203 21.1 14.4703 20.95L7.95027 14.43C6.89027 13.37 6.89027 11.63 7.95027 10.57L14.4703 4.05002C14.7603 3.76002 15.2403 3.76002 15.5303 4.05002C15.8203 4.34002 15.8203 4.82002 15.5303 5.11002L9.01027 11.63C8.53027 12.11 8.53027 12.89 9.01027 13.37L15.5303 19.89C15.8203 20.18 15.8203 20.66 15.5303 20.95C15.3803 21.09 15.1903 21.17 15.0003 21.17Z'
            fill='#006CF2'
          />
        </svg>
        <span className='sr-only'>{t('dashboard:back')}</span>
      </Button>

      <div className='px-4 py-2 flex-1'>
        <span className='text-sm text-blue-400'>
          {t('dashboard:step')} {step + 1}/5
        </span>
        <h3 className='text-base md:text-xl font-bold'>{title}</h3>
      </div>
      <div className='flex gap-3 items-center'>
        {step === 2 ? (
          <Dialog>
            <DialogTrigger
              className={twMerge(
                buttonVariants({ variant: 'outline' }),
                `border-primary text-primary`
              )}
            >
              {t('dashboard:default-settings')}
            </DialogTrigger>
            <DialogContent
              className={`${softGradient} text-center text-black dark:text-white`}
            >
              <DialogHeader>
                <DialogTitle className='text-2xl text-center text-black dark:text-white'>
                  {t('dashboard:apply-d-settings')}
                </DialogTitle>
                <DialogDescription className='text-base text-black dark:text-white'>
                  {t('dashboard:apply-d-details')}
                </DialogDescription>
              </DialogHeader>
              <DialogFooter className='place-self-center gap-6 py-4'>
                <Button
                  className='text-white'
                  onClick={onSetDefaultSettings}
                  type='button'
                >
                  {t('dashboard:confirm')}
                </Button>
                <DialogClose className='text-primary'>
                  {t('dashboard:cancel')}
                </DialogClose>
              </DialogFooter>
              <span className='text-sm text-muted-foreground'>
                {t('dashboard:apply-d-notes')}
              </span>
            </DialogContent>
          </Dialog>
        ) : null}
        <Button className='text-white' onClick={onNextEvent}>
          {step === 4 ? (
            <div className='flex gap-3 items-center'>
              <svg
                width='24'
                height='25'
                viewBox='0 0 24 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M18 13.25H6C5.59 13.25 5.25 12.91 5.25 12.5C5.25 12.09 5.59 11.75 6 11.75H18C18.41 11.75 18.75 12.09 18.75 12.5C18.75 12.91 18.41 13.25 18 13.25Z'
                  fill='white'
                />
                <path
                  d='M12 19.25C11.59 19.25 11.25 18.91 11.25 18.5V6.5C11.25 6.09 11.59 5.75 12 5.75C12.41 5.75 12.75 6.09 12.75 6.5V18.5C12.75 18.91 12.41 19.25 12 19.25Z'
                  fill='white'
                />
              </svg>
              {t('dashboard:add-publish')}
            </div>
          ) : (
            'Next'
          )}{' '}
          {step === 4 ? null : (
            <svg
              width='25'
              height='25'
              viewBox='0 0 25 25'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <title>{t('dashboard:next-icon')}</title>
              <path
                d='M9.40961 21.1695C9.21961 21.1695 9.02961 21.0995 8.87961 20.9495C8.58961 20.6595 8.58961 20.1795 8.87961 19.8895L15.3996 13.3695C15.8796 12.8895 15.8796 12.1095 15.3996 11.6295L8.87961 5.10953C8.58961 4.81953 8.58961 4.33953 8.87961 4.04953C9.16961 3.75953 9.64961 3.75953 9.93961 4.04953L16.4596 10.5695C16.9696 11.0795 17.2596 11.7695 17.2596 12.4995C17.2596 13.2295 16.9796 13.9195 16.4596 14.4295L9.93961 20.9495C9.78961 21.0895 9.59961 21.1695 9.40961 21.1695Z'
                fill='white'
              />
            </svg>
          )}
        </Button>
      </div>
    </div>
  )
}
