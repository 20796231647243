import { Translate } from 'next-translate'

export const SpinnerLoader = ({
  t,
  className,
}: {
  className?: string
  t: Translate
}) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className ? `${className} ` : ''}animate-spin motion-reduce:animate-none`}
    >
      <title>{t('sync')}</title>
      <path d='M16 2.66797V8.0013V2.66797Z' fill='#006CF2' />
      <path
        d='M21.5999 10.4012L25.4666 6.5345L21.5999 10.4012Z'
        fill='#006CF2'
      />
      <path d='M24 16.0013H29.3333H24Z' fill='#006CF2' />
      <path
        d='M21.5999 21.6012L25.4666 25.4679L21.5999 21.6012Z'
        fill='#006CF2'
      />
      <path d='M16 24.0013L16 29.3346L16 24.0013Z' fill='#006CF2' />
      <path
        d='M6.5332 25.4679L10.3999 21.6012L6.5332 25.4679Z'
        fill='#006CF2'
      />
      <path d='M2.66666 16.0013H8H2.66666Z' fill='#006CF2' />
      <path d='M6.5332 6.5345L10.3999 10.4012L6.5332 6.5345Z' fill='#006CF2' />
      <path
        d='M16 2.66797V8.0013M21.5999 10.4012L25.4666 6.5345M24 16.0013H29.3333M21.5999 21.6012L25.4666 25.4679M16 24.0013L16 29.3346M6.5332 25.4679L10.3999 21.6012M2.66666 16.0013H8M6.5332 6.5345L10.3999 10.4012'
        stroke='#334155'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
