import React from 'react'

export const UpArrow = () => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 12 12'
      className='fill-green-600 dark:fill-green-700'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_836_131300'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='24'
        height='24'
      >
        <rect width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_836_131300)'>
        <path d='M10.0004 4.7125L7.76289 6.9375C7.47122 7.22917 7.11706 7.375 6.70039 7.375C6.28372 7.375 5.92956 7.22917 5.63789 6.9375L5.05039 6.35C4.95872 6.25833 4.84206 6.2125 4.70039 6.2125C4.55872 6.2125 4.44206 6.25833 4.35039 6.35L2.05039 8.65C1.95872 8.74167 1.84206 8.7875 1.70039 8.7875C1.55872 8.7875 1.44206 8.74167 1.35039 8.65C1.25872 8.55833 1.21289 8.44167 1.21289 8.3C1.21289 8.15833 1.25872 8.04167 1.35039 7.95L3.65039 5.65C3.94206 5.35833 4.29622 5.2125 4.71289 5.2125C5.12956 5.2125 5.48372 5.35833 5.77539 5.65L6.35039 6.225C6.45039 6.325 6.56914 6.375 6.70664 6.375C6.84414 6.375 6.96289 6.325 7.06289 6.225L9.28789 4H8.50039C8.35872 4 8.23997 3.95208 8.14414 3.85625C8.04831 3.76042 8.00039 3.64167 8.00039 3.5C8.00039 3.35833 8.04831 3.23958 8.14414 3.14375C8.23997 3.04792 8.35872 3 8.50039 3H10.5004C10.6421 3 10.7608 3.04792 10.8566 3.14375C10.9525 3.23958 11.0004 3.35833 11.0004 3.5V5.5C11.0004 5.64167 10.9525 5.76042 10.8566 5.85625C10.7608 5.95208 10.6421 6 10.5004 6C10.3587 6 10.24 5.95208 10.1441 5.85625C10.0483 5.76042 10.0004 5.64167 10.0004 5.5V4.7125Z' />
      </g>
    </svg>
  )
}

export const DownArrow = () => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      className='fill-red-600 dark:fill-red-700'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_836_132520'
        maskUnits='userSpaceOnUse'
        x='-12'
        y='-12'
        width='24'
        height='24'
      >
        <rect
          x='12'
          y='12'
          width='24'
          height='24'
          transform='rotate(180 12 12)'
          fill='#D9D9D9'
        />
      </mask>
      <g mask='url(#mask0_836_132520)'>
        <path d='M1.99961 7.2875L4.23711 5.0625C4.52878 4.77083 4.88294 4.625 5.29961 4.625C5.71628 4.625 6.07044 4.77083 6.36211 5.0625L6.94961 5.65C7.04128 5.74167 7.15794 5.7875 7.29961 5.7875C7.44128 5.7875 7.55794 5.74167 7.64961 5.65L9.94961 3.35C10.0413 3.25833 10.1579 3.2125 10.2996 3.2125C10.4413 3.2125 10.5579 3.25833 10.6496 3.35C10.7413 3.44167 10.7871 3.55833 10.7871 3.7C10.7871 3.84167 10.7413 3.95833 10.6496 4.05L8.34961 6.35C8.05794 6.64167 7.70378 6.7875 7.28711 6.7875C6.87044 6.7875 6.51628 6.64167 6.22461 6.35L5.64961 5.775C5.54961 5.675 5.43086 5.625 5.29336 5.625C5.15586 5.625 5.03711 5.675 4.93711 5.775L2.71211 8H3.49961C3.64128 8 3.76003 8.04792 3.85586 8.14375C3.95169 8.23958 3.99961 8.35833 3.99961 8.5C3.99961 8.64167 3.95169 8.76042 3.85586 8.85625C3.76003 8.95208 3.64128 9 3.49961 9H1.49961C1.35794 9 1.23919 8.95208 1.14336 8.85625C1.04753 8.76042 0.999609 8.64167 0.999609 8.5V6.5C0.999609 6.35833 1.04753 6.23958 1.14336 6.14375C1.23919 6.04792 1.35794 6 1.49961 6C1.64128 6 1.76003 6.04792 1.85586 6.14375C1.95169 6.23958 1.99961 6.35833 1.99961 6.5V7.2875Z' />
      </g>
    </svg>
  )
}
