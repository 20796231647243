import { useEffect, SyntheticEvent, memo } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from '@app/lib/hooks/useTranslation'

import { Menu } from '@headlessui/react'
import { Link } from '@app/components/stateless/typo/link'
import { UserManager } from '@app/managers'
import { LOGGIN_ROUTES } from '@app/configs'
import { useMutation } from '@apollo/client'
import { LOGOUT } from '@app/mutations'
import { NavItem } from './navigation/nav-item'
import { useWasmContext } from '../providers'
import { FilterManager } from '@app/managers/filters'
import { MenuList } from './menu'
import { classNames } from '@app/utils/classes'
import { btnStyles, getActiveStyles } from './cells/menu/styles'
import { routeParseAbsolute } from '@app/lib/router-handler'
import { ThemeSelect } from './themes/theme-select'

type AuthMenuComponentProps = {
  authenticated?: boolean // user logged in
  icon?: 'settings' | 'more' // display settings icon
  className?: string
  dashboard?: boolean // is this the dashboar dpage
}

export const useLogout = () => {
  try {
    return useMutation(LOGOUT)
  } catch (e) {
    return [null, {}]
  }
}

// render logout button if authenticated - TODO: make it more composable for re-use.
export const LogoutButton = ({
  logoutText,
  raw,
  className,
}: {
  logoutText?: string
  raw?: boolean
  className?: string
}) => {
  // @ts-ignore
  const [logoutMutation, { data, client }] = useLogout()

  const { feed } = useWasmContext()
  const router = useRouter()

  useEffect(() => {
    if (data) {
      ;async () => {
        try {
          await client?.clearStore()
        } catch (e) {
          console.error(e)
        }
      }
    }
  }, [data, client])

  // simple logout
  const logout = async (e: SyntheticEvent<HTMLButtonElement>) => {
    e?.preventDefault()

    try {
      feed?.clear_data()
      if (logoutMutation && typeof logoutMutation === 'function') {
        await logoutMutation()
      }
      UserManager.clearUser()
      FilterManager.clearFilters()
    } catch (e) {
      console.error(e)
    }

    // just go home
    await router.push('/')
  }

  if (raw) {
    return (
      <button
        onClick={logout}
        className={
          className
            ? className
            : classNames(btnStyles, 'border-t dark:border-gray-800')
        }
        type={'button'}
      >
        {logoutText}
      </button>
    )
  }
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          onClick={logout}
          className={classNames(
            btnStyles,
            getActiveStyles(active),
            'border-t dark:border-gray-800'
          )}
          type={'button'}
        >
          {logoutText}
        </button>
      )}
    </Menu.Item>
  )
}

// TODO: USE POPOVER
const AuthMenuComponent = ({
  authenticated,
  icon,
  className,
  dashboard,
}: AuthMenuComponentProps) => {
  const { t, lang } = useTranslation('menu')
  const router = useRouter()

  if (
    dashboard ||
    authenticated ||
    (!authenticated && LOGGIN_ROUTES.includes(router?.pathname))
  ) {
    return (
      <div className={className}>
        <MenuList
          settings={icon === 'settings'}
          more={icon === 'more'}
          dashboard={dashboard}
        >
          <div className='max-w-sm'>
            {UserManager.user.firstName ? (
              <div className='text-sm px-4 py-2 text-gray-800 dark:text-gray-100 truncate'>
                <div className='truncate'>
                  {UserManager.user.firstName + ' ' + UserManager.user.lastName}
                </div>
              </div>
            ) : null}
            {UserManager.user.email ? (
              <div className='text-sm px-4 py-2 text-gray-700 dark:text-gray-200'>
                <div className='truncate'>{UserManager.user.email}</div>
              </div>
            ) : null}
          </div>
          {router?.pathname !== '/profile' ? (
            <Menu.Item>
              {({ active }) => (
                <Link
                  href={`/profile`}
                  className={classNames(btnStyles, getActiveStyles(active))}
                >
                  {t('profile')}
                </Link>
              )}
            </Menu.Item>
          ) : null}
          {router?.pathname !== '/dashboard' ? (
            <Menu.Item>
              {({ active }) => (
                <Link
                  href={'/dashboard'}
                  className={classNames(btnStyles, getActiveStyles(active))}
                >
                  {t('dashboard')}
                </Link>
              )}
            </Menu.Item>
          ) : null}
          {router?.pathname !== '/payments' &&
          process.env.NEXT_PUBLIC_ENABLE_PAYMENTS ? (
            <Menu.Item>
              {({ active }) => (
                <Link
                  href={routeParseAbsolute('/payments', lang)}
                  className={classNames(btnStyles, getActiveStyles(active))}
                >
                  {t('payments')}
                </Link>
              )}
            </Menu.Item>
          ) : null}
          {router?.pathname !== '/settings' ? (
            <Menu.Item>
              {({ active }) => (
                <Link
                  href={'/settings'}
                  className={classNames(btnStyles, getActiveStyles(active))}
                >
                  {t('settings')}
                </Link>
              )}
            </Menu.Item>
          ) : null}
          {/* {router?.pathname !== '/dashboard/tokens' ? (
            <Menu.Item>
              {({ active }) => (
                <Link
                  href={'/dashboard/tokens'}
                  className={classNames(btnStyles, getActiveStyles(active))}
                >
                  {t('dash/tokens:header')}
                </Link>
              )}
            </Menu.Item>
          ) : null} */}

          <Menu.Item>
            {({ active }) => (
              <Link
                href={'/api-info'}
                className={classNames(btnStyles, getActiveStyles(active))}
              >
                {t('common:api-info')}
              </Link>
            )}
          </Menu.Item>

          <Menu.Item disabled>
            {({ active, close }) => (
              <ThemeSelect active={active} close={close} />
            )}
          </Menu.Item>

          {authenticated ? null : (
            <Menu.Item>
              {({ active }) => (
                <Link
                  href={routeParseAbsolute('/register', lang)}
                  className={classNames(
                    btnStyles,
                    getActiveStyles(active),
                    'border-t dark:border-gray-800'
                  )}
                >
                  {t('common:register')}
                </Link>
              )}
            </Menu.Item>
          )}

          <LogoutButton logoutText={t('logout')} />
        </MenuList>
      </div>
    )
  }

  // when not authed return login or register
  return (
    <div className='space-x-3'>
      <NavItem
        route={router?.pathname}
        href={routeParseAbsolute('/login', lang)}
        name={t('common:login')}
      />
      <NavItem
        route={router?.pathname}
        href={routeParseAbsolute('/register', lang)}
        name={t('common:register')}
        className='border-2 px-6 py-2 rounded dark:hover:border-blue-600'
      />
    </div>
  )
}

export const AuthMenu = memo(AuthMenuComponent)
