import { Button } from '@app/components/general'

const btnStyle =
  'border-2 flex flex-col items-center place-content-center gap-3 py-4 px-2 rounded-xl w-full relative font-semibold md:min-h-[35vh] md:text-2xl'

const selectedCheckStyle =
  'flex items-center place-content-center rounded-full border-blue-50 bg-blue-500 h-8 w-8'

const selectedTextStyle =
  'border-blue-400 dark:border-blue-700 text-blue-800 bg-blue-50 font-bold dark:bg-blue-800 dark:text-blue-100'

// Initial step form
export const StepOne = ({ active }: { active: boolean }) => {
  const multiSelected = false
  const selected = !multiSelected

  return (
    <div className={`px-5 ${!active ? ' hidden' : ''}`}>
      <div className='flex flex-1 flex-col md:flex-row gap-3 items-center h-full w-full md:gap-6'>
        <Button
          className={`${btnStyle}${selected ? ` ${selectedTextStyle}` : ' text-gray-500 dark:text-gray-300'}`}
        >
          {selected ? (
            <div className='absolute top-3 right-3'>
              <div className={selectedCheckStyle}>
                <svg
                  width='19'
                  height='15'
                  viewBox='0 0 19 15'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className={selected ? 'fill-blue-400' : 'fill-gray-400'}
                >
                  <path
                    d='M6.09193 10.8018L16.1457 0.48105C16.4581 0.16035 16.8558 0 17.3386 0C17.8214 0 18.219 0.16035 18.5314 0.48105C18.8438 0.801749 19 1.20991 19 1.70554C19 2.20117 18.8438 2.60933 18.5314 2.93003L7.28475 14.4752C6.94395 14.8251 6.54634 15 6.09193 15C5.63752 15 5.23991 14.8251 4.8991 14.4752L0.46861 9.92711C0.156203 9.60641 0 9.19825 0 8.70262C0 8.207 0.156203 7.79883 0.46861 7.47813C0.781016 7.15743 1.17862 6.99708 1.66143 6.99708C2.14425 6.99708 2.54185 7.15743 2.85426 7.47813L6.09193 10.8018Z'
                    fill='white'
                  />
                </svg>
              </div>
            </div>
          ) : null}
          <svg
            width='64'
            height='64'
            viewBox='0 0 64 64'
            xmlns='http://www.w3.org/2000/svg'
            className={selected ? 'fill-blue-400' : 'fill-gray-400'}
          >
            <path d='M32.0007 60.6663C16.1873 60.6663 3.33398 47.813 3.33398 31.9997C3.33398 16.1863 16.1873 3.33301 32.0007 3.33301C47.814 3.33301 60.6673 16.1863 60.6673 31.9997C60.6673 47.813 47.814 60.6663 32.0007 60.6663ZM32.0007 7.33301C18.4007 7.33301 7.33398 18.3997 7.33398 31.9997C7.33398 45.5997 18.4007 56.6663 32.0007 56.6663C45.6007 56.6663 56.6673 45.5997 56.6673 31.9997C56.6673 18.3997 45.6007 7.33301 32.0007 7.33301Z' />
            <path d='M24.0006 58H21.334C20.2406 58 19.334 57.0933 19.334 56C19.334 54.9067 20.1873 54.0267 21.2806 54C17.094 39.7067 17.094 24.2933 21.2806 10C20.1873 9.97333 19.334 9.09333 19.334 8C19.334 6.90667 20.2406 6 21.334 6H24.0006C24.6406 6 25.254 6.32 25.6273 6.82667C26.0006 7.36 26.1073 8.02667 25.894 8.64C20.8806 23.7067 20.8806 40.2933 25.894 55.3867C26.1073 56 26.0006 56.6667 25.6273 57.2C25.254 57.68 24.6406 58 24.0006 58Z' />
            <path d='M40.0004 58.0002C39.7871 58.0002 39.5737 57.9735 39.3604 57.8935C38.3204 57.5468 37.7337 56.4002 38.1071 55.3602C43.1204 40.2935 43.1204 23.7068 38.1071 8.61349C37.7604 7.57349 38.3204 6.42682 39.3604 6.08015C40.4271 5.73349 41.5471 6.29349 41.8937 7.33349C47.2004 23.2268 47.2004 40.7202 41.8937 56.5868C41.6271 57.4668 40.8271 58.0002 40.0004 58.0002Z' />
            <path d='M32 45.8668C24.56 45.8668 17.1467 44.8268 10 42.7201C9.97333 43.7868 9.09333 44.6668 8 44.6668C6.90667 44.6668 6 43.7601 6 42.6668V40.0001C6 39.3601 6.32 38.7468 6.82667 38.3734C7.36 38.0001 8.02667 37.8934 8.64 38.1068C23.7067 43.1201 40.32 43.1201 55.3867 38.1068C56 37.8934 56.6667 38.0001 57.2 38.3734C57.7333 38.7468 58.0267 39.3601 58.0267 40.0001V42.6668C58.0267 43.7601 57.12 44.6668 56.0267 44.6668C54.9333 44.6668 54.0533 43.8134 54.0267 42.7201C46.8533 44.8268 39.44 45.8668 32 45.8668Z' />
            <path d='M56.0001 26.0003C55.7867 26.0003 55.5734 25.9736 55.3601 25.8936C40.2934 20.8803 23.6801 20.8803 8.61341 25.8936C7.54674 26.2403 6.42674 25.6803 6.08007 24.6403C5.76007 23.5736 6.32007 22.4536 7.36007 22.107C23.2534 16.8003 40.7467 16.8003 56.6134 22.107C57.6534 22.4536 58.2401 23.6003 57.8667 24.6403C57.6267 25.467 56.8267 26.0003 56.0001 26.0003Z' />
          </svg>
          Single Site
        </Button>
        <Button
          className={`${btnStyle}${multiSelected ? ` ${selectedTextStyle}` : ' text-gray-500 dark:text-gray-300'}`}
        >
          {multiSelected ? (
            <div className='absolute top-3 right-3'>
              <div className={selectedCheckStyle}>
                <svg
                  width='19'
                  height='15'
                  viewBox='0 0 19 15'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className={selected ? 'fill-blue-400' : 'fill-gray-400'}
                >
                  <path
                    d='M6.09193 10.8018L16.1457 0.48105C16.4581 0.16035 16.8558 0 17.3386 0C17.8214 0 18.219 0.16035 18.5314 0.48105C18.8438 0.801749 19 1.20991 19 1.70554C19 2.20117 18.8438 2.60933 18.5314 2.93003L7.28475 14.4752C6.94395 14.8251 6.54634 15 6.09193 15C5.63752 15 5.23991 14.8251 4.8991 14.4752L0.46861 9.92711C0.156203 9.60641 0 9.19825 0 8.70262C0 8.207 0.156203 7.79883 0.46861 7.47813C0.781016 7.15743 1.17862 6.99708 1.66143 6.99708C2.14425 6.99708 2.54185 7.15743 2.85426 7.47813L6.09193 10.8018Z'
                    fill='white'
                  />
                </svg>
              </div>
            </div>
          ) : null}
          <svg
            width='64'
            height='64'
            viewBox='0 0 64 64'
            xmlns='http://www.w3.org/2000/svg'
            className={multiSelected ? 'fill-blue-400' : 'fill-gray-400'}
          >
            <path d='M31.9997 60.6663C16.1863 60.6663 3.33301 47.813 3.33301 31.9997C3.33301 16.1863 16.1863 3.33301 31.9997 3.33301C47.813 3.33301 60.6663 16.1863 60.6663 31.9997C60.6663 33.093 59.7597 33.9997 58.6663 33.9997C57.573 33.9997 56.6663 33.093 56.6663 31.9997C56.6663 18.3997 45.5997 7.33301 31.9997 7.33301C18.3997 7.33301 7.33301 18.3997 7.33301 31.9997C7.33301 45.5997 18.3997 56.6663 31.9997 56.6663C33.093 56.6663 33.9997 57.573 33.9997 58.6663C33.9997 59.7597 33.093 60.6663 31.9997 60.6663Z' />
            <path d='M24.0006 58H21.334C20.2406 58 19.334 57.0933 19.334 56C19.334 54.9067 20.1873 54.0267 21.2806 54C17.094 39.7067 17.094 24.2933 21.2806 10C20.214 9.97333 19.334 9.09333 19.334 8C19.334 6.90667 20.2406 6 21.334 6H24.0006C24.6406 6 25.254 6.31999 25.6273 6.82666C26.0006 7.35999 26.1073 8.02664 25.894 8.63997C20.8807 23.7066 20.8807 40.3201 25.894 55.3867C26.1073 56.0001 26.0006 56.6667 25.6273 57.2C25.254 57.7334 24.6406 58 24.0006 58Z' />
            <path d='M43.8932 33.9999C42.7999 33.9999 41.8932 33.0932 41.8932 31.9999C41.8932 24.0532 40.6132 16.1865 38.1065 8.63988C37.7599 7.59988 38.3199 6.45318 39.3599 6.10651C40.3999 5.75985 41.5466 6.31993 41.8932 7.35993C44.5332 15.3066 45.8932 23.5999 45.8932 31.9999C45.8932 33.0932 44.9866 33.9999 43.8932 33.9999Z' />
            <path d='M32 45.8935C24.5333 45.8935 17.1467 44.8269 10 42.7202C9.97333 43.7869 9.09333 44.6668 8 44.6668C6.90667 44.6668 6 43.7601 6 42.6668V40.0001C6 39.3601 6.31999 38.7468 6.82666 38.3735C7.33333 38.0002 8.02668 37.8934 8.64001 38.1067C16.1867 40.6134 24.0533 41.8935 32 41.8935C33.0933 41.8935 34 42.8002 34 43.8935C34 44.9869 33.0933 45.8935 32 45.8935Z' />
            <path d='M56.0002 26.0003C55.7868 26.0003 55.5735 25.9737 55.3601 25.8937C40.2935 20.8804 23.6801 20.8804 8.61348 25.8937C7.57348 26.2404 6.42682 25.6803 6.08015 24.6403C5.73349 23.6003 6.29349 22.4536 7.33349 22.107C23.2268 16.8003 40.7202 16.8003 56.5868 22.107C57.6268 22.4536 58.1868 23.6003 57.8402 24.6403C57.6268 25.467 56.8268 26.0003 56.0002 26.0003Z' />
            <path d='M42.1871 60.6662C41.1738 60.6662 40.2138 60.2928 39.5205 59.5995C38.6938 58.7728 38.3205 57.5729 38.5071 56.3195L39.0138 52.7196C39.1471 51.7863 39.7071 50.6662 40.3738 49.9995L49.8138 40.5596C51.0938 39.2796 52.3471 38.6129 53.7071 38.4795C55.3871 38.3195 57.0138 39.0129 58.5605 40.5596C60.1071 42.1063 60.8005 43.733 60.6405 45.413C60.5071 46.7463 59.8138 48.0262 58.5605 49.3062L49.1205 58.7463C48.4538 59.413 47.3605 59.9728 46.4271 60.1062L42.8271 60.6128C42.5871 60.6395 42.4005 60.6662 42.1871 60.6662ZM54.1604 42.4528C54.1338 42.4528 54.1071 42.4528 54.0805 42.4528C53.7071 42.4795 53.2005 42.7996 52.6405 43.3863L43.2005 52.8262C43.1205 52.9062 42.9871 53.1728 42.9871 53.2795L42.5071 56.6128L45.8405 56.1329C45.9471 56.1062 46.2138 55.9729 46.2938 55.8929L55.7338 46.4528C56.2938 45.8928 56.6405 45.3862 56.6671 45.0129C56.7205 44.4796 56.1871 43.8396 55.7338 43.3863C55.3071 42.9596 54.6938 42.4528 54.1604 42.4528Z' />
            <path d='M55.7873 51.2529C55.6006 51.2529 55.4139 51.2263 55.2539 51.173C51.7339 50.1863 48.9339 47.3864 47.9472 43.8664C47.6539 42.7997 48.2673 41.7064 49.3339 41.4131C50.4006 41.1197 51.4939 41.733 51.8139 42.7996C52.4272 44.9863 54.1606 46.7197 56.3473 47.333C57.4139 47.6263 58.0272 48.7463 57.7339 49.7863C57.4672 50.6663 56.6672 51.2529 55.7873 51.2529Z' />
          </svg>
          Multiple Sites
        </Button>
      </div>
    </div>
  )
}
