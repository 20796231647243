import { memo } from 'react'
import type { Issue } from '@app/types'
import { WithSyntaxDashboard } from '@app/components/adhoc/with-syntax-dashboard'
import { classNames } from '@app/utils/classes'
import { Recurrence } from './cells/reccurence'
import { IssueColor } from './cells/issue-color'
import { CodeLink } from './cells/code-link'
import { Selector } from './cells/selector'
import { IssueMessage } from './cells/message'
import Zoom from 'react-medium-image-zoom'
import dynamic from 'next/dynamic'
import { Translate } from 'next-translate'

const ZoomStyle = dynamic(
  () => import('./zoom-style').then((mod) => mod.ZoomStyle),
  {
    loading: () => null,
  }
)

type CellIssue = Partial<Issue> & {
  hidden?: boolean //the entire section is hidden
  count?: number // if grouping display counter
  t?: Translate
}

// Display issue UI without events
export const FeedIssueCardComponent = ({
  message,
  code,
  context,
  type: issueType,
  selector,
  recurrence,
  runnerExtras,
  clipBase64,
  clip,
  t,
}: // runnerExtra
CellIssue) => {
  return (
    <div>
      <div className='flex flex-col gap-y-0.5 place-content-between h-[inherit] overflow-hidden bg-white dark:bg-gray-900'>
        <div className='flex gap-x-2 items-center rtl:flex-row-reverse p-3 border-b border-t'>
          <IssueColor issueType={issueType} />
          <Selector selector={selector} />
          <Recurrence recurrence={recurrence} />
        </div>

        <div className='flex items-center gap-3 px-4 text-sm text-muted-foreground py-2'>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6.65973 15.1667C6.52639 15.1667 6.41973 15.14 6.33973 15.1067C6.07306 15.0067 5.61973 14.68 5.61973 13.6467V9.34667H4.05973C3.16639 9.34667 2.84639 8.92667 2.73306 8.68C2.61973 8.42667 2.51973 7.91334 3.10639 7.24L8.15306 1.50667C8.83306 0.733338 9.38639 0.786671 9.65306 0.886671C9.91973 0.986671 10.3731 1.31334 10.3731 2.34667V6.64667H11.9331C12.8264 6.64667 13.1464 7.06667 13.2597 7.31334C13.3731 7.56667 13.4731 8.08 12.8864 8.75334L7.83973 14.4867C7.36639 15.0267 6.95306 15.1667 6.65973 15.1667ZM9.28639 1.82667C9.26639 1.85334 9.12639 1.92 8.90639 2.17334L3.85973 7.90667C3.67306 8.12 3.64639 8.25334 3.64639 8.28C3.65973 8.28667 3.77973 8.35334 4.05973 8.35334H6.11973C6.39306 8.35334 6.61973 8.58 6.61973 8.85334V13.6533C6.61973 13.9867 6.67973 14.1333 6.70639 14.1733C6.72639 14.1467 6.86639 14.08 7.08639 13.8267L12.1331 8.09334C12.3197 7.88 12.3464 7.74667 12.3464 7.72C12.3331 7.71334 12.2131 7.64667 11.9331 7.64667H9.87306C9.59973 7.64667 9.37306 7.42 9.37306 7.14667V2.34667C9.37973 2.01334 9.31306 1.87334 9.28639 1.82667Z'
              fill='#395165'
            />
          </svg>
          <p className='text-muted-foreground text-xs'>Techniques</p>
          <CodeLink
            code={code}
            helpUrl={runnerExtras?.helpUrl}
            issueType={issueType}
          />
        </div>

        <div className='space-y-3 pb-2 px-4'>
          <div className='flex items-center gap-3 text-muted-foreground text-xs'>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M10.666 15.1666H5.33268C3.31935 15.1666 2.16602 14.0133 2.16602 11.9999V5.49992C2.16602 3.39992 3.23268 2.33325 5.33268 2.33325C5.60602 2.33325 5.83268 2.55992 5.83268 2.83325C5.83268 3.09992 5.93935 3.35325 6.12602 3.53992C6.31268 3.72658 6.56602 3.83325 6.83268 3.83325H9.16602C9.71935 3.83325 10.166 3.38659 10.166 2.83325C10.166 2.55992 10.3927 2.33325 10.666 2.33325C12.766 2.33325 13.8327 3.39992 13.8327 5.49992V11.9999C13.8327 14.0133 12.6793 15.1666 10.666 15.1666ZM4.89933 3.34659C3.846 3.43325 3.16602 3.90659 3.16602 5.49992V11.9999C3.16602 13.4799 3.85268 14.1666 5.33268 14.1666H10.666C12.146 14.1666 12.8327 13.4799 12.8327 11.9999V5.49992C12.8327 3.90659 12.1527 3.43992 11.0994 3.34659C10.8727 4.19992 10.0927 4.83325 9.16602 4.83325H6.83268C6.29935 4.83325 5.79935 4.62658 5.41935 4.24658C5.16602 3.99325 4.99267 3.68659 4.89933 3.34659Z'
                fill='#395165'
              />
              <path
                d='M9.16732 4.83325H6.83398C6.30065 4.83325 5.80065 4.62658 5.42065 4.24658C5.04065 3.86658 4.83398 3.36659 4.83398 2.83325C4.83398 1.73325 5.73398 0.833252 6.83398 0.833252H9.16732C9.70065 0.833252 10.2006 1.03992 10.5806 1.41992C10.9606 1.79992 11.1673 2.29992 11.1673 2.83325C11.1673 3.93325 10.2673 4.83325 9.16732 4.83325ZM6.83398 1.83325C6.28065 1.83325 5.83398 2.27992 5.83398 2.83325C5.83398 3.09992 5.94065 3.35325 6.12732 3.53992C6.31399 3.72658 6.56732 3.83325 6.83398 3.83325H9.16732C9.72065 3.83325 10.1673 3.38659 10.1673 2.83325C10.1673 2.56659 10.0606 2.31325 9.87398 2.12659C9.68732 1.93992 9.43398 1.83325 9.16732 1.83325H6.83398Z'
                fill='#395165'
              />
              <path
                d='M8.00065 9.16675H5.33398C5.06065 9.16675 4.83398 8.94008 4.83398 8.66675C4.83398 8.39341 5.06065 8.16675 5.33398 8.16675H8.00065C8.27398 8.16675 8.50065 8.39341 8.50065 8.66675C8.50065 8.94008 8.27398 9.16675 8.00065 9.16675Z'
                fill='#395165'
              />
              <path
                d='M10.6673 11.8333H5.33398C5.06065 11.8333 4.83398 11.6066 4.83398 11.3333C4.83398 11.0599 5.06065 10.8333 5.33398 10.8333H10.6673C10.9407 10.8333 11.1673 11.0599 11.1673 11.3333C11.1673 11.6066 10.9407 11.8333 10.6673 11.8333Z'
                fill='#395165'
              />
            </svg>
            <p className='text-muted-foreground text-xs'>Description</p>
          </div>
          <IssueMessage message={message} clip={!!clipBase64} />
        </div>
        {clipBase64 ? (
          <>
            <ZoomStyle />
            <div className={'max-h-[3rem] overflow-clip p-4 border rounded-md'}>
              <Zoom>
                <img
                  src={`data:image/png;base64,${clipBase64}`}
                  width={clip?.width ?? 300}
                  height={clip?.height ?? 50}
                  alt={context}
                />
              </Zoom>
            </div>
          </>
        ) : null}
        {context ? (
          <WithSyntaxDashboard t={t}>
            {context.replaceAll('\n', '')}
          </WithSyntaxDashboard>
        ) : null}
      </div>
    </div>
  )
}

export const FeedIssueCard = memo(FeedIssueCardComponent)

// list item wrapper of issue cell
const FeedIssueComponent = ({
  message,
  code,
  context,
  type: issueType = 'warning',
  selector,
  recurrence,
  hidden,
  runnerExtras,
  clipBase64,
  clip,
  t,
}: CellIssue) => (
  <li className={classNames(!hidden ? 'visible' : 'hidden', `h-[inherit]`)}>
    <FeedIssueCard
      selector={selector}
      type={issueType}
      context={context}
      code={code}
      message={message}
      recurrence={recurrence}
      runnerExtras={runnerExtras}
      clipBase64={clipBase64}
      clip={clip}
      t={t}
    />
  </li>
)

// Generic issue UI display to re-use across application
export const FeedIssue = memo(FeedIssueComponent)
