// the drawer cell themed styles
export const getDrawerThemeStyles = ({
  active,
  className,
  nohover,
}: {
  active?: boolean
  className?: string
  nohover?: boolean
}) =>
  `flex relative items-center gap-3 rounded-lg px-3 py-3.5 transition-all ${nohover ? '' : 'hover:text-primary'}${active ? ` text-primary bg-muted fill-primary` : ' fill-[#255769] dark:fill-white text-muted-foreground'}${className ? ` ${className}` : ''}`
