import {
  Dispatch,
  FC,
  memo,
  ReactElement,
  SetStateAction,
  SyntheticEvent,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react'
import ms from 'ms'
import { Pages, Website } from '@app/types'
import { SyncStatus } from './render/block-views/sync-status'
import { CrawlDuration } from './render/block-views/crawl-duration'
import { ErrorBlock } from './render/block-views/errors'
import { WarningBlock } from './render/block-views/warnings'
import { LeaderboardsBlock } from './render/block-views/leaderboards'
import { Translate } from 'next-translate'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { useInteractiveContext } from '@app/components/providers/interactive'
import { fetcher } from '@app/utils/fetcher'
import { toast } from '@app/@/components/ui/use-toast'
import { useWasmContext } from '@app/components/providers'
import { useWebsiteContext } from '@app/components/providers/website'
import { useWebsiteLiveData } from '@app/data/formatters/use-live-data'
import { Timer } from '../timer'
import { Button } from '@app/@/components/ui/button'
import { SpinnerLoader } from '@app/components/svgs/spinner-loader'
import { formatDateTime } from '@app/utils/format-date'
import { DownArrow, UpArrow } from './render/block-views/icons/prev'

const SyncCell = ({
  title,
  description,
  icon,
  greater,
  neutral,
  changeValue,
}: {
  title?: string | ReactElement
  description?: string | number | ReactElement
  icon: ReactElement
  greater?: boolean
  neutral?: boolean
  changeValue?: string | number
}) => {
  const { t } = useTranslation('dashboard')

  const textColor = greater
    ? 'text-green-600 dark:text-green-700'
    : neutral
      ? 'text-gray-600 dark:text-gray-700'
      : 'text-red-600 dark:text-red-700'

  return (
    <div className='px-4 pt-2 pb-4 border-t'>
      <div className='flex items-center place-content-between'>
        <div className='text-black dark:text-white'>
          <p>{title}</p>
          <p className='text-base font-semibold'>{description}</p>
        </div>
        <div>{icon}</div>
      </div>
      <div className='font-light flex gap-2 items-center text-xs pt-2 text-muted-foreground'>
        <div
          className={`flex items-center gap-1 ${textColor} ${neutral ? 'invisible' : ''}`}
        >
          {greater ? <UpArrow /> : neutral ? null : <DownArrow />}
          <p className='text-xs'>{changeValue || 'n/a'}</p>
        </div>
        <div className={`${neutral ? 'invisible' : ''}`}>{t('vs-prev')}</div>
      </div>
    </div>
  )
}

const calculateChanges = (
  prevValue: number | undefined,
  currentValue: number | undefined
) => {
  if (prevValue === undefined || currentValue === undefined) {
    return { changeValue: 'n/a', greater: false, neutral: true }
  }

  const difference = currentValue - prevValue
  return {
    changeValue: Math.abs(difference),
    greater: difference > 0,
    neutral: difference === 0,
  }
}

const SyncDetailsWrapper: FC<{
  website: Website
  lastSync?: boolean
  t: Translate
}> = ({ website, lastSync, t }) => {
  const [prevWebsite, setPrevWebsite] = useState<Website | null>(null)

  useEffect(() => {
    if (!website.scanning) {
      setPrevWebsite(website)
    }
  }, [website])

  const errorChange = calculateChanges(
    prevWebsite?.issuesInfo?.errorCount,
    website.issuesInfo?.errorCount
  )

  const warningChange = calculateChanges(
    prevWebsite?.issuesInfo?.warningCount,
    website.issuesInfo?.warningCount
  )

  const crawlDuration = website?.crawlDuration
  const prevCrawlDuration = prevWebsite?.crawlDuration

  const crawlDurationChange = useMemo(
    () => calculateChanges(prevCrawlDuration, crawlDuration),
    [crawlDuration, prevCrawlDuration]
  )

  return (
    <div className='border flex-1 rounded-lg'>
      <div className='flex items-center gap-3 py-4 px-4'>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
          className='fill-black dark:fill-white'
        >
          <title>{t('sync')}</title>
          <path d='M15.4897 20.7604C15.2997 20.7604 15.1097 20.6904 14.9597 20.5404C14.6697 20.2504 14.6697 19.7704 14.9597 19.4804L19.9697 14.4704C20.2597 14.1804 20.7397 14.1804 21.0297 14.4704C21.3197 14.7604 21.3197 15.2404 21.0297 15.5304L16.0197 20.5404C15.8697 20.6804 15.6797 20.7604 15.4897 20.7604Z' />
          <path d='M20.5 15.7402H3.5C3.09 15.7402 2.75 15.4002 2.75 14.9902C2.75 14.5802 3.09 14.2402 3.5 14.2402H20.5C20.91 14.2402 21.25 14.5802 21.25 14.9902C21.25 15.4002 20.91 15.7402 20.5 15.7402Z' />
          <path d='M3.49945 9.76043C3.30945 9.76043 3.11945 9.69043 2.96945 9.54043C2.67945 9.25043 2.67945 8.77043 2.96945 8.48043L7.97945 3.47043C8.26945 3.18043 8.74945 3.18043 9.03945 3.47043C9.32945 3.76043 9.32945 4.24043 9.03945 4.53043L4.02945 9.54043C3.88945 9.68043 3.68945 9.76043 3.49945 9.76043Z' />
          <path d='M20.5 9.75977H3.5C3.09 9.75977 2.75 9.41977 2.75 9.00977C2.75 8.59977 3.09 8.25977 3.5 8.25977H20.5C20.91 8.25977 21.25 8.59977 21.25 9.00977C21.25 9.41977 20.91 9.75977 20.5 9.75977Z' />
        </svg>
        <h3 className='text-xl font-bold'>
          {lastSync ? t('last-sync') : t('new-sync')}
        </h3>
      </div>

      <SyncCell
        title={t(lastSync ? 'last-sync' : 'new-sync')}
        description={formatDateTime(website?.lastScanDate, 'MM/dd/yy') || ''}
        neutral
        icon={
          <div className='p-2 bg-blue-100 dark:bg-blue-900 rounded-full'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              className='fill-blue-500'
            >
              <title>Date Icon</title>
              <path d='M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z' />
              <path d='M15.7106 15.9298C15.5806 15.9298 15.4506 15.8998 15.3306 15.8198L12.2306 13.9698C11.4606 13.5098 10.8906 12.4998 10.8906 11.6098V7.50977C10.8906 7.09977 11.2306 6.75977 11.6406 6.75977C12.0506 6.75977 12.3906 7.09977 12.3906 7.50977V11.6098C12.3906 11.9698 12.6906 12.4998 13.0006 12.6798L16.1006 14.5298C16.4606 14.7398 16.5706 15.1998 16.3606 15.5598C16.2106 15.7998 15.9606 15.9298 15.7106 15.9298Z' />
            </svg>
          </div>
        }
      />

      <SyncCell
        title={t('num-errors')}
        description={website.issuesInfo?.errorCount || 0}
        {...errorChange}
        icon={
          <div className='p-2 bg-red-100 dark:bg-red-900 rounded-full'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              className='fill-red-500'
            >
              <title>Errors Icon</title>

              <path d='M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z' />
              <path d='M9.16937 15.5804C8.97937 15.5804 8.78938 15.5104 8.63938 15.3604C8.34938 15.0704 8.34938 14.5904 8.63938 14.3004L14.2994 8.64035C14.5894 8.35035 15.0694 8.35035 15.3594 8.64035C15.6494 8.93035 15.6494 9.41035 15.3594 9.70035L9.69937 15.3604C9.55937 15.5104 9.35937 15.5804 9.16937 15.5804Z' />
              <path d='M14.8294 15.5804C14.6394 15.5804 14.4494 15.5104 14.2994 15.3604L8.63938 9.70035C8.34938 9.41035 8.34938 8.93035 8.63938 8.64035C8.92937 8.35035 9.40937 8.35035 9.69937 8.64035L15.3594 14.3004C15.6494 14.5904 15.6494 15.0704 15.3594 15.3604C15.2094 15.5104 15.0194 15.5804 14.8294 15.5804Z' />
            </svg>
          </div>
        }
      />

      <SyncCell
        title={t('num-warnings')}
        description={website.issuesInfo?.warningCount || 0}
        {...warningChange}
        icon={
          <div className='p-2 bg-yellow-100 dark:bg-yellow-900 rounded-full'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              className='fill-yellow-500'
            >
              <title>Warning Icon</title>
              <path d='M12 14.75C11.59 14.75 11.25 14.41 11.25 14V9C11.25 8.59 11.59 8.25 12 8.25C12.41 8.25 12.75 8.59 12.75 9V14C12.75 14.41 12.41 14.75 12 14.75Z' />
              <path d='M12 17.9996C11.94 17.9996 11.87 17.9896 11.8 17.9796C11.74 17.9696 11.68 17.9496 11.62 17.9196C11.56 17.8996 11.5 17.8696 11.44 17.8296C11.39 17.7896 11.34 17.7496 11.29 17.7096C11.11 17.5196 11 17.2596 11 16.9996C11 16.7396 11.11 16.4796 11.29 16.2896C11.34 16.2496 11.39 16.2096 11.44 16.1696C11.5 16.1296 11.56 16.0996 11.62 16.0796C11.68 16.0496 11.74 16.0296 11.8 16.0196C11.93 15.9896 12.07 15.9896 12.19 16.0196C12.26 16.0296 12.32 16.0496 12.38 16.0796C12.44 16.0996 12.5 16.1296 12.56 16.1696C12.61 16.2096 12.66 16.2496 12.71 16.2896C12.89 16.4796 13 16.7396 13 16.9996C13 17.2596 12.89 17.5196 12.71 17.7096C12.66 17.7496 12.61 17.7896 12.56 17.8296C12.5 17.8696 12.44 17.8996 12.38 17.9196C12.32 17.9496 12.26 17.9696 12.19 17.9796C12.13 17.9896 12.06 17.9996 12 17.9996Z' />
              <path d='M18.0605 22.1598H5.94046C3.99046 22.1598 2.50046 21.4498 1.74046 20.1698C0.990464 18.8898 1.09046 17.2398 2.04046 15.5298L8.10046 4.62984C9.10046 2.82984 10.4805 1.83984 12.0005 1.83984C13.5205 1.83984 14.9005 2.82984 15.9005 4.62984L21.9605 15.5398C22.9105 17.2498 23.0205 18.8898 22.2605 20.1798C21.5005 21.4498 20.0105 22.1598 18.0605 22.1598ZM12.0005 3.33984C11.0605 3.33984 10.1405 4.05984 9.41046 5.35984L3.36046 16.2698C2.68046 17.4898 2.57046 18.6098 3.04046 19.4198C3.51046 20.2298 4.55046 20.6698 5.95046 20.6698H18.0705C19.4705 20.6698 20.5005 20.2298 20.9805 19.4198C21.4605 18.6098 21.3405 17.4998 20.6605 16.2698L14.5905 5.35984C13.8605 4.05984 12.9405 3.33984 12.0005 3.33984Z' />
            </svg>
          </div>
        }
      />

      <SyncCell
        title={t('crawl-duration')}
        description={ms(website.crawlDuration || 0)}
        {...crawlDurationChange}
        icon={
          <div className='p-2 bg-orange-100 dark:bg-orange-900 rounded-full'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              className='fill-orange-500'
            >
              <title>Duration Icon</title>
              <path d='M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z' />
              <path d='M12 22.75C6.76 22.75 2.5 18.49 2.5 13.25C2.5 8.01 6.76 3.75 12 3.75C17.24 3.75 21.5 8.01 21.5 13.25C21.5 13.66 21.16 14 20.75 14C20.34 14 20 13.66 20 13.25C20 8.84 16.41 5.25 12 5.25C7.59 5.25 4 8.84 4 13.25C4 17.66 7.59 21.25 12 21.25C12.41 21.25 12.75 21.59 12.75 22C12.75 22.41 12.41 22.75 12 22.75Z' />
              <path d='M15 2.75H9C8.59 2.75 8.25 2.41 8.25 2C8.25 1.59 8.59 1.25 9 1.25H15C15.41 1.25 15.75 1.59 15.75 2C15.75 2.41 15.41 2.75 15 2.75Z' />
              <path d='M16.1504 22.0198C15.8004 22.0198 15.4804 21.9398 15.1904 21.7698C14.5304 21.3898 14.1504 20.6198 14.1504 19.6598V17.3498C14.1504 16.3898 14.5304 15.6198 15.1904 15.2398C15.8504 14.8598 16.7004 14.9198 17.5304 15.3898L19.5304 16.5498C20.3604 17.0298 20.8404 17.7398 20.8404 18.4998C20.8404 19.2598 20.3604 19.9698 19.5304 20.4498L17.5304 21.6098C17.0704 21.8798 16.6004 22.0198 16.1504 22.0198ZM16.1604 16.4798C16.0804 16.4798 16.0004 16.4998 15.9404 16.5298C15.7604 16.6298 15.6504 16.9398 15.6504 17.3398V19.6498C15.6504 20.0498 15.7604 20.3598 15.9404 20.4598C16.1204 20.5598 16.4404 20.5098 16.7804 20.3098L18.7804 19.1498C19.1304 18.9498 19.3404 18.6998 19.3404 18.4998C19.3404 18.2998 19.1304 18.0498 18.7804 17.8498L16.7804 16.6898C16.5504 16.5498 16.3304 16.4798 16.1604 16.4798Z' />
            </svg>
          </div>
        }
      />
    </div>
  )
}

const SyncDetails = memo(SyncDetailsWrapper)

const LeaderboardRank = ({
  rank,
  t,
}: {
  rank: { sectorRank: number; globalRank: number; label?: string; date: string }
  t: Translate
}) => (
  <div className='rounded-lg border p-4 flex-1'>
    <h3 className='text-xl font-bold mb-4'>{t('leaderboard-rank')}</h3>
    {/* <div className='mb-2'>
      <p className='text-gray-500'>{rank.date}</p>
      <p className='text-2xl font-bold'>{rank.globalRank}/100</p>
    </div> */}
    <div>
      <p className=''>
        {t('sector-rank')} (
        {t(
          rank?.label
            ? `leaderboards-categories:tags.${rank?.label}`
            : 'leaderboards-categories:tags.government'
        )}
        )
      </p>
      <p className='text-2xl font-bold'>{rank.sectorRank}/100</p>
    </div>
  </div>
)

const WebsiteDetailsHeader: FC<{
  website: Website
  detailsView: boolean
  setDetailsView: Dispatch<SetStateAction<boolean>>
  t: Translate
  selectedView?: boolean
  pagesCounter?: number
}> = ({
  website,
  detailsView,
  setDetailsView,
  t,
  selectedView,
  pagesCounter,
}) => {
  const { setSelectedWebsite } = useInteractiveContext()
  const { removeActiveCrawl, isWebsiteScanning } = useWebsiteContext()
  const [manualAudit, setRequestManualAudit] = useState<boolean>(
    !!website?.manualAuditPrompted
  )

  const isLiveData = isWebsiteScanning(website)

  const pageCount = pagesCounter ?? website?.issuesInfo?.pageCount ?? 0
  const toggleCompare = () => setDetailsView((d) => !d)
  const toggleSelected = (_e: SyntheticEvent<HTMLButtonElement>) => {
    setSelectedWebsite(website)
  }

  const onPromptManualAudit = async () => {
    await fetcher('/prompt-manual-audit', { url: website.url }, 'POST')
    toast({ title: t('r-audit-c') })
    setRequestManualAudit(true)
  }

  const onStopWebsiteCrawl = async () => {
    if (isLiveData) {
      const res = await fetcher(
        '/scanning/website',
        { domain: website.domain },
        'DELETE'
      )
      if (res?.data?.shutdown) {
        removeActiveCrawl(website.domain)
      }
    }
  }

  return (
    <div className='py-4 border-b'>
      <div className='flex items-center flex-1 px-4 gap-3 flex-wrap'>
        <div className='flex flex-1 items-center gap-3'>
          <div className='rounded p-2 overflow-auto border'>
            <img
              height={24}
              width={24}
              src={
                website.iconUrl ||
                `https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${encodeURI(
                  `http://${website.domain}`
                )}&size=24`
              }
              alt={`${website.domain} favicon`}
              loading='lazy'
            />
          </div>
          <div className='flex-1'>
            <h2 className='text-sm md:text-xl font-bold truncate'>
              {website.url}
            </h2>
            <p className='text-gray-500 text-sm'>
              {pageCount} {pageCount === 1 ? 'Page' : 'Pages'}
            </p>
          </div>
        </div>
        <div className='flex flex-1 md:flex-0 items-center gap-3 place-content-center md:place-content-end text-xs md:text-sm border-t md:border-t-0 pt-5 md:pt-0'>
          {selectedView ? (
            <button
              className='flex text-primary/80 px-4 py-2 rounded-lg gap-1 items-center place-content-center min-w-[8.25rem] hover:bg-gray-200 dark:hover:bg-gray-300'
              onClick={onPromptManualAudit}
              type='button'
              disabled={manualAudit}
            >
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z'
                  fill='#006CF2'
                />
                <path
                  d='M12 16.25C11.59 16.25 11.25 15.91 11.25 15.5V9.5C11.25 9.09 11.59 8.75 12 8.75C12.41 8.75 12.75 9.09 12.75 9.5V15.5C12.75 15.91 12.41 16.25 12 16.25Z'
                  fill='#006CF2'
                />
                <path
                  d='M14.9999 12.2504C14.8099 12.2504 14.6199 12.1804 14.4699 12.0304L11.9999 9.56043L9.52994 12.0304C9.23994 12.3204 8.75994 12.3204 8.46994 12.0304C8.17994 11.7404 8.17994 11.2604 8.46994 10.9704L11.4699 7.97043C11.7599 7.68043 12.2399 7.68043 12.5299 7.97043L15.5299 10.9704C15.8199 11.2604 15.8199 11.7404 15.5299 12.0304C15.3799 12.1804 15.1899 12.2504 14.9999 12.2504Z'
                  fill='#006CF2'
                />
              </svg>
              {manualAudit ? t('r-audit-pending') : t('r-audit')}
            </button>
          ) : detailsView ? (
            <button
              className='bg-blue-500 text-white px-4 py-2 rounded-lg gap-1 items-center min-w-[8.25rem] flex place-content-center'
              onClick={toggleCompare}
              type='button'
            >
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <title>{t('back')}</title>
                <path
                  d='M15.1309 19.0596H7.13086C6.72086 19.0596 6.38086 18.7196 6.38086 18.3096C6.38086 17.8996 6.72086 17.5596 7.13086 17.5596H15.1309C17.4709 17.5596 19.3809 15.6496 19.3809 13.3096C19.3809 10.9696 17.4709 9.05957 15.1309 9.05957H4.13086C3.72086 9.05957 3.38086 8.71957 3.38086 8.30957C3.38086 7.89957 3.72086 7.55957 4.13086 7.55957H15.1309C18.3009 7.55957 20.8809 10.1396 20.8809 13.3096C20.8809 16.4796 18.3009 19.0596 15.1309 19.0596Z'
                  fill='white'
                />
                <path
                  d='M6.43055 11.5599C6.24055 11.5599 6.05055 11.4899 5.90055 11.3399L3.34055 8.77988C3.05055 8.48988 3.05055 8.00988 3.34055 7.71988L5.90055 5.15988C6.19055 4.86988 6.67055 4.86988 6.96055 5.15988C7.25055 5.44988 7.25055 5.92988 6.96055 6.21988L4.93055 8.24988L6.96055 10.2799C7.25055 10.5699 7.25055 11.0499 6.96055 11.3399C6.82055 11.4899 6.62055 11.5599 6.43055 11.5599Z'
                  fill='white'
                />
              </svg>
              {t('back')}
            </button>
          ) : (
            <>
              <button
                className='flex text-primary/80 px-4 py-2 rounded-lg gap-1 items-center place-content-center min-w-[8.25rem] hover:bg-gray-200 dark:hover:bg-gray-300'
                onClick={toggleCompare}
                type='button'
              >
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                  className='fill-primary/80'
                >
                  <path d='M15.4897 20.7604C15.2997 20.7604 15.1097 20.6904 14.9597 20.5404C14.6697 20.2504 14.6697 19.7704 14.9597 19.4804L19.9697 14.4704C20.2597 14.1804 20.7397 14.1804 21.0297 14.4704C21.3197 14.7604 21.3197 15.2404 21.0297 15.5304L16.0197 20.5404C15.8697 20.6804 15.6797 20.7604 15.4897 20.7604Z' />
                  <path d='M20.5 15.7402H3.5C3.09 15.7402 2.75 15.4002 2.75 14.9902C2.75 14.5802 3.09 14.2402 3.5 14.2402H20.5C20.91 14.2402 21.25 14.5802 21.25 14.9902C21.25 15.4002 20.91 15.7402 20.5 15.7402Z' />
                  <path d='M3.49945 9.76043C3.30945 9.76043 3.11945 9.69043 2.96945 9.54043C2.67945 9.25043 2.67945 8.77043 2.96945 8.48043L7.97945 3.47043C8.26945 3.18043 8.74945 3.18043 9.03945 3.47043C9.32945 3.76043 9.32945 4.24043 9.03945 4.53043L4.02945 9.54043C3.88945 9.68043 3.68945 9.76043 3.49945 9.76043Z' />
                  <path d='M20.5 9.75977H3.5C3.09 9.75977 2.75 9.41977 2.75 9.00977C2.75 8.59977 3.09 8.25977 3.5 8.25977H20.5C20.91 8.25977 21.25 8.59977 21.25 9.00977C21.25 9.41977 20.91 9.75977 20.5 9.75977Z' />
                </svg>
                {t('compare')}
              </button>
              <button
                className='flex bg-blue-500 text-white px-4 py-2 rounded-lg gap-1 items-center place-content-center min-w-[8.25rem] hover:bg-blue-400'
                onClick={toggleSelected}
                type='button'
              >
                {t('site-details')}
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.90961 20.6695C8.71961 20.6695 8.52961 20.5995 8.37961 20.4495C8.08961 20.1595 8.08961 19.6795 8.37961 19.3895L14.8996 12.8695C15.3796 12.3895 15.3796 11.6095 14.8996 11.1295L8.37961 4.60953C8.08961 4.31953 8.08961 3.83953 8.37961 3.54953C8.66961 3.25953 9.14961 3.25953 9.43961 3.54953L15.9596 10.0695C16.4696 10.5795 16.7596 11.2695 16.7596 11.9995C16.7596 12.7295 16.4796 13.4195 15.9596 13.9295L9.43961 20.4495C9.28961 20.5895 9.09961 20.6695 8.90961 20.6695Z'
                    fill='white'
                  />
                </svg>
              </button>
            </>
          )}
        </div>
      </div>
      <div className={`py-2 ${isLiveData ? '' : 'hidden'}`}>
        <div className='border-t py-4 flex place-content-center items-center flex-col'>
          <SpinnerLoader t={t} />

          <p>{t('syncing')}</p>

          <div className='pt-2'>
            <Button
              variant={'destructive'}
              onClick={onStopWebsiteCrawl}
              type={'button'}
            >
              {t('dash/website-cell:sync-stop', { url: website?.url })}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

// the new sync is from the real time data.
export const WebsiteGroupDetails: FC<{
  website: Website
  selectedView?: boolean
}> = ({ website, selectedView }) => {
  const { t } = useTranslation('dashboard')
  const [detailsView, setDetailsView] = useState<boolean>(false)
  const { feed } = useWasmContext()
  const { isWebsiteScanning } = useWebsiteContext()
  // TODO: always capture old and new values in the state for the sections.

  const isLiveData = isWebsiteScanning(website)

  // defer feed data items
  const items: Pages[] = useDeferredValue(
    feed?.get_data_item(
      website.domain || '',
      !!(website.tld || website.subdomains)
    ) ?? []
  )

  const issues = (items.length ? items : website.issues) as Pages[]

  const issuesInfo = website.issuesInfo

  // format the issues with the live data
  const { errorCount, warningCount } = useWebsiteLiveData({
    issues,
    issuesInfo,
    url: website.url,
  })

  const pagesCounter = issuesInfo?.pageCount || 0

  return (
    <li className='pb-4 rounded-lg border bg-white dark:bg-black'>
      <WebsiteDetailsHeader
        website={website}
        setDetailsView={setDetailsView}
        detailsView={detailsView}
        selectedView={selectedView}
        pagesCounter={pagesCounter}
        t={t}
      />
      {detailsView ? (
        <div className='grid grid-cols-1 md:grid-cols-3 px-4 py-4 gap-4'>
          <SyncDetails website={website} lastSync t={t} />
          <SyncDetails website={website} t={t} />
          <LeaderboardRank
            t={t}
            rank={{
              date: formatDateTime(website?.lastScanDate) || '',
              globalRank: 0,
              sectorRank: website?.issuesInfo?.accessScoreAverage || 0,
              label: website?.label,
            }}
          />
        </div>
      ) : (
        <>
          <div className='grid md:grid-cols-3 gap-3 px-5 py-6'>
            <div className='grid gap-3'>
              <SyncStatus website={website} t={t} isLiveData={isLiveData} />
              <CrawlDuration
                count={website.crawlDuration || 0}
                t={t}
                scanning={website?.scanning}
              >
                {isLiveData ? (
                  <Timer
                    onlyTime
                    stop={!!website.crawlDuration}
                    duration={website.crawlDuration}
                    t={t}
                    noui
                  />
                ) : null}
              </CrawlDuration>
            </div>
            <div className='grid gap-3'>
              <ErrorBlock
                count={errorCount}
                t={t}
                scanning={website?.scanning}
              />
              <WarningBlock
                count={warningCount}
                t={t}
                scanning={website?.scanning}
              />
            </div>
            <LeaderboardsBlock website={website} t={t} />
          </div>
        </>
      )}
    </li>
  )
}

export const WebsiteGroup: FC<{ websites: Website[] }> = ({ websites }) => {
  return (
    <ul className='space-y-4 pb-10'>
      {websites
        .map((website) => (
          <WebsiteGroupDetails key={website.url} website={website} />
        ))
        .toReversed()}
    </ul>
  )
}
