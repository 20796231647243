import { Link } from '@app/components/stateless/typo/link'
import { Logo } from './logo'

/// The initial home btn logo
export const HomeBtn = ({
  openDrawer,
  whiteBottom,
  whiteFill,
}: {
  openDrawer?: boolean
  whiteBottom?: boolean
  whiteFill?: boolean
}) => {
  const fillColor = whiteFill ? 'fill-white' : 'fill-[#002D66] dark:fill-white'

  return (
    <Link href='/dashboard' className='flex items-center gap-2 font-semibold'>
      <Logo className='h-6 w-6' whiteBottom={whiteBottom} />
      <svg
        width='104'
        height='15'
        viewBox='0 0 104 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={`${openDrawer ? 'hidden' : ''}`}
      >
        <title>Muneer</title>
        <path
          d='M3.67549 0.545451H7.94436L6.98242 4.96594C7.95154 2.12453 8.92782 1.44943 9.93762 1.00091C10.945 0.552387 11.9883 0.328125 13.0627 0.328125C13.8739 0.328125 14.52 0.446031 14.9985 0.677228C15.4795 0.910737 15.8289 1.23442 16.0442 1.64595C16.2596 2.05979 16.3888 2.51525 16.4271 3.01926C16.9176 2.4274 17.4584 1.93494 18.0519 1.5396C18.6453 1.14425 19.2866 0.843694 19.9733 0.637928C20.6601 0.432163 21.354 0.328125 22.0527 0.328125C22.9381 0.328125 23.6177 0.476087 24.0891 0.77202C24.5605 1.06795 24.8955 1.45867 25.0941 1.94188C25.2927 2.42739 25.3908 2.95452 25.3908 3.5302C25.3908 3.74521 25.3717 4.02497 25.3334 4.36483C25.2951 4.70469 25.2209 5.12778 25.106 5.62947L23.0697 14.7248H18.3749L20.1552 6.8317C20.2294 6.42248 20.2677 6.07337 20.2677 5.789C20.2677 5.27112 20.16 4.89658 19.9422 4.66539C19.7245 4.43419 19.4373 4.31628 19.0808 4.31628C18.7602 4.31628 18.3964 4.42031 17.992 4.6307C17.5852 4.84109 17.2048 5.12084 16.8458 5.46995C16.4869 5.81905 16.1759 6.2144 15.9126 6.65136L14.1586 14.7248H9.46383L11.2178 6.78546C11.2752 6.57045 11.3111 6.37624 11.3303 6.20747C11.3494 6.03638 11.359 5.87222 11.359 5.71039C11.359 5.24568 11.2513 4.89658 11.0336 4.66539C10.8158 4.43419 10.5287 4.31628 10.1721 4.31628C9.66243 4.31628 9.10729 4.54054 8.50429 4.98444C7.90128 5.43065 7.40117 5.98551 7.00635 6.65136L5.22606 14.7248H0.53125L3.6707 0.545451H3.67549Z'
          className={fillColor}
        />
        <path
          d='M40.7466 10.2529C40.7466 10.1442 40.7561 10.0518 40.7753 9.97083C40.7944 9.88991 40.8135 9.78587 40.8327 9.66102L42.869 0.544922H38.1742L36.449 8.28308C36.2647 9.11308 35.8053 9.87372 35.1042 10.3847C35.0994 10.387 35.097 10.3893 35.0922 10.3939C34.7141 10.6667 34.3576 10.8656 34.0178 10.9881C33.678 11.1106 33.3478 11.173 33.0271 11.173C32.1418 11.173 31.6991 10.6621 31.6991 9.64021C31.6991 9.35352 31.7374 9.0206 31.8116 8.64375L33.5919 0.544922H28.9545L26.8894 9.67489C26.8129 10.0356 26.7578 10.3731 26.7195 10.6875C26.6813 11.002 26.6621 11.3048 26.6621 11.5915C26.6621 13.8619 27.8107 14.9947 30.1126 14.9947C31.2804 14.9947 32.3883 14.7774 33.4339 14.3474C34.4796 13.9151 36.047 12.7567 36.2767 10.2899L36.1642 13.1174C36.1546 13.7879 36.3796 14.2595 36.8414 14.537C37.2936 14.8075 37.8871 14.9439 38.6217 14.9439C38.8849 14.9439 39.1816 14.9254 39.5119 14.8884C39.8421 14.8514 40.2225 14.7982 40.658 14.7242L41.4788 10.9002C40.9882 10.8817 40.7442 10.6667 40.7442 10.2552L40.7466 10.2529Z'
          className={fillColor}
        />
        <path
          d='M46.2599 0.544474H50.5288L49.4329 5.15223C50.1435 2.96511 50.9763 1.88773 51.5888 1.51088C52.2014 1.13403 52.8786 0.842717 53.6252 0.636952C54.3694 0.431186 55.147 0.327148 55.9582 0.327148C56.8436 0.327148 57.5279 0.475111 58.0089 0.771043C58.4899 1.06698 58.8297 1.46694 59.0259 1.96864C59.2245 2.47034 59.3226 2.99978 59.3226 3.55697C59.3226 3.91532 59.2939 4.26906 59.2365 4.62048C59.179 4.9719 59.1144 5.30713 59.0379 5.6285L57.0302 14.7238H52.3354L54.1157 6.83072C54.1731 6.63421 54.2042 6.45156 54.2138 6.28279C54.2234 6.11401 54.2282 5.96604 54.2282 5.84119C54.2282 5.30713 54.1109 4.91872 53.874 4.67828C53.6371 4.43783 53.3117 4.31761 52.8977 4.31761C52.5771 4.31761 52.2134 4.41472 51.809 4.60661C51.4046 4.7985 51.0026 5.07593 50.6078 5.43428C50.2129 5.79264 49.8732 6.19955 49.5908 6.65501L47.8081 14.7284H43.1133L46.2527 0.549096L46.2599 0.544474Z'
          className={fillColor}
        />
        <path
          d='M82.6216 9.06907C85.5601 9.06907 87.7783 8.6691 89.2642 7.87147C90.755 7.07384 91.4896 5.91092 91.4705 4.38733C91.492 3.52728 91.2719 2.78976 90.822 2.1794C90.3674 1.56904 89.7284 1.10895 88.8981 0.794525C88.0678 0.482409 87.0891 0.325195 85.9573 0.325195C84.6005 0.325195 83.3443 0.551769 82.1837 1.01185C81.0256 1.46962 80.011 2.09385 79.1424 2.88455C78.2762 3.67524 77.5918 4.59309 77.0941 5.64273C76.594 6.69468 76.3355 7.81829 76.3164 9.02283C76.3332 10.1904 76.5988 11.2238 77.1085 12.1324C77.6181 13.0387 78.336 13.7439 79.2692 14.2433C80.2024 14.7473 81.3079 14.997 82.5929 14.997C83.6123 14.997 84.6101 14.8398 85.5888 14.5184C86.5699 14.2016 87.4744 13.7531 88.3023 13.1797C89.1326 12.6064 89.8098 11.9382 90.3386 11.173L86.6345 10.0262C86.2947 10.5719 85.8448 10.9903 85.2897 11.2839C84.7345 11.5753 84.1172 11.7186 83.44 11.7186C82.8729 11.7186 82.4063 11.6238 82.0378 11.4273C81.6717 11.2331 81.406 10.9487 81.2457 10.5765C81.0854 10.2043 80.9945 9.74416 80.9777 9.19391C80.9777 9.14305 80.9801 9.08988 80.9825 9.03901C81.5592 9.06213 82.1048 9.0737 82.6168 9.0737L82.6216 9.06907ZM81.5999 6.28315H81.6023C81.7674 5.90861 81.966 5.56875 82.1957 5.25432C82.5714 4.73875 83.0164 4.32953 83.5261 4.02898C84.0358 3.72842 84.579 3.57583 85.1652 3.57583C85.5242 3.57583 85.8353 3.629 86.0985 3.73304C86.3641 3.83708 86.5699 3.97579 86.7206 4.14919C86.8714 4.32259 86.948 4.53991 86.948 4.79885C86.948 5.12947 86.8091 5.41153 86.5387 5.64504C86.266 5.88086 85.8113 6.04964 85.1796 6.15368C84.9116 6.19761 84.6077 6.23229 84.2607 6.25541C83.6936 6.29702 83.1313 6.41955 82.6168 6.65306C81.9899 6.93743 81.3031 7.42063 81.0615 8.21133C81.0615 8.2067 81.0639 8.19746 81.0639 8.19053C81.0997 8.01944 81.2553 7.23568 81.4491 6.66C81.4635 6.6207 81.4754 6.57908 81.4898 6.53977C81.4922 6.53746 81.4922 6.53516 81.4922 6.53053C81.5209 6.45424 81.5544 6.37793 81.5855 6.30626C81.5879 6.29702 81.5903 6.2924 81.5951 6.28546C81.5951 6.28315 81.5975 6.28083 81.5975 6.27621L81.5999 6.28315Z'
          className={fillColor}
        />
        <path
          d='M66.7877 9.06907C69.7261 9.06907 71.9443 8.6691 73.4303 7.87147C74.921 7.07384 75.6556 5.91092 75.6365 4.38733C75.658 3.52728 75.4379 2.78976 74.988 2.1794C74.5334 1.56904 73.8945 1.10895 73.0642 0.794525C72.2338 0.482409 71.2551 0.325195 70.1233 0.325195C68.7666 0.325195 67.5103 0.551769 66.3497 1.01185C65.1916 1.46962 64.177 2.09385 63.3084 2.88455C62.4422 3.67524 61.7578 4.59309 61.2601 5.64273C60.76 6.69468 60.5016 7.81829 60.4824 9.02283C60.4992 10.1904 60.7648 11.2238 61.2745 12.1324C61.7842 13.0387 62.502 13.7439 63.4352 14.2433C64.3685 14.7473 65.474 14.997 66.759 14.997C67.7783 14.997 68.7761 14.8398 69.7548 14.5184C70.7359 14.2016 71.6404 13.7531 72.4683 13.1797C73.2987 12.6064 73.9759 11.9382 74.5047 11.173L70.8005 10.0262C70.4607 10.5719 70.0108 10.9903 69.4557 11.2839C68.9005 11.5753 68.2832 11.7186 67.606 11.7186C67.0389 11.7186 66.5723 11.6238 66.2038 11.4273C65.8377 11.2331 65.5721 10.9487 65.4118 10.5765C65.2514 10.2043 65.1605 9.74416 65.1438 9.19391C65.1438 9.14305 65.1461 9.08988 65.1485 9.03901C65.7252 9.06213 66.2708 9.0737 66.7829 9.0737L66.7877 9.06907ZM65.7659 6.28315H65.7683C65.9334 5.90861 66.132 5.56875 66.3617 5.25432C66.7374 4.73875 67.1825 4.32953 67.6921 4.02898C68.2018 3.72842 68.745 3.57583 69.3313 3.57583C69.6902 3.57583 70.0013 3.629 70.2645 3.73304C70.5301 3.83708 70.7359 3.97579 70.8867 4.14919C71.0374 4.32259 71.114 4.53991 71.114 4.79885C71.114 5.12947 70.9752 5.41153 70.7048 5.64504C70.432 5.88086 69.9773 6.04964 69.3456 6.15368C69.0776 6.19761 68.7737 6.23229 68.4268 6.25541C67.8597 6.29702 67.2973 6.41955 66.7829 6.65306C66.1559 6.93743 65.4692 7.42063 65.2275 8.21133C65.2275 8.2067 65.2299 8.19746 65.2299 8.19053C65.2658 8.01944 65.4213 7.23568 65.6152 6.66C65.6295 6.6207 65.6415 6.57908 65.6558 6.53977C65.6582 6.53746 65.6582 6.53516 65.6582 6.53053C65.6869 6.45424 65.7204 6.37793 65.7515 6.30626C65.7539 6.29702 65.7563 6.2924 65.7611 6.28546C65.7611 6.28315 65.7635 6.28083 65.7635 6.27621L65.7659 6.28315Z'
          className={fillColor}
        />
        <path
          d='M94.6961 0.544835H99.0224L98.0581 4.84972C98.8118 2.60018 99.891 1.6777 100.776 1.1367C101.662 0.595698 102.521 0.325195 103.349 0.325195H103.787C103.873 0.325195 103.942 0.343689 104 0.38068L103.124 4.42432C102.107 4.42432 101.154 4.57459 100.267 4.87284C100.257 4.87515 100.25 4.87978 100.24 4.8821C99.008 5.30287 98.1179 6.34557 97.8427 7.58016L96.2515 14.7242H91.5566L94.6961 0.544835Z'
          className={fillColor}
        />
      </svg>
    </Link>
  )
}
