import { classNames } from '@app/utils/classes'

type StyleProps = {
  spacing?: boolean
  className?: string
  disabled?: boolean
  borderLess?: boolean
}

export const selectFocusStyles =
  'focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300'

// Select input style
export const getSelectStyle = ({
  spacing = true,
  disabled,
  className,
  borderLess = true,
}: StyleProps) => {
  return classNames(
    spacing ? 'px-2 ltr:text-left rtl:text-right' : '',
    `appearance-none text-xs capitalize min-h-[1.5rem] m-0 border rounded py-2.5 space-y-0.5 px-1 bg-gray-100 min-w-[115px] md:min-w-[11rem]`,
    'dark:border-gray-700 dark:bg-gray-900',
    borderLess ? '' : 'border-transparent',
    disabled
      ? 'text-gray-400 dark:text-gray-600 opacity-80'
      : `hover:border-blue-600 dark:hover:border-blue-600 hover:text-blue-600 ${selectFocusStyles}`,
    className || ''
  )
}
