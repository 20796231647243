import { getStepBtnStyle } from './styles'

export const CompletedCheck = ({ active }: { active?: boolean }) => {
  return (
    <div
      className={`${getStepBtnStyle(active)} bg-green-100 border-green-100 dark:bg-green-900 dark:border-green-900`}
    >
      <svg
        width='28'
        height='28'
        viewBox='0 0 28 28'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <mask
          id='mask0_836_144429'
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='28'
          height='28'
        >
          <rect width='28' height='28' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask0_836_144429)'>
          <path
            d='M11.141 17.675L21.0285 7.7875C21.2618 7.55417 21.5341 7.4375 21.8452 7.4375C22.1563 7.4375 22.4285 7.55417 22.6618 7.7875C22.8952 8.02083 23.0118 8.29792 23.0118 8.61875C23.0118 8.93958 22.8952 9.21667 22.6618 9.45L11.9577 20.1833C11.7243 20.4167 11.4521 20.5333 11.141 20.5333C10.8299 20.5333 10.5577 20.4167 10.3243 20.1833L5.30768 15.1667C5.07435 14.9333 4.96254 14.6562 4.97226 14.3354C4.98199 14.0146 5.10351 13.7375 5.33685 13.5042C5.57018 13.2708 5.84726 13.1542 6.1681 13.1542C6.48893 13.1542 6.76601 13.2708 6.99935 13.5042L11.141 17.675Z'
            fill='#08AF04'
          />
        </g>
      </svg>
    </div>
  )
}
