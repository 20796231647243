import React from 'react'
import { containerStyle } from '@app/styles/container'
import { Translate } from 'next-translate'

export const LeaderBoardSvgPattern = ({
  t,
  className,
  height,
  width,
}: {
  t: Translate
  className?: string
  height?: number
  width?: number
}) => {
  return (
    <svg
      width={width ?? '284'}
      height={height ?? '144'}
      viewBox='0 0 284 144'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      {t('common:logo')}
      <path
        d='M197.729 48.0725C217.069 55.3759 238.693 45.5521 246.028 26.1302C253.362 6.70838 243.63 -14.9568 224.29 -22.2603C204.95 -29.5637 183.326 -19.7399 175.991 -0.318022C168.657 19.1038 178.389 40.769 197.729 48.0725Z'
        fill='white'
        fillOpacity='0.05'
      />
      <path
        d='M319.956 54.3835C303.382 98.2744 263.207 126.463 219.394 129.862C211.787 115.751 201.172 103.004 187.631 92.6947C164.103 74.7465 135.879 67.1768 108.437 69.2825C92.815 40.4186 89.3258 5.12878 101.832 -27.9883L147.348 -10.7998C134.058 24.3919 151.752 63.7832 186.796 77.0168C221.839 90.2503 261.153 72.391 274.444 37.1962L319.956 54.3835Z'
        fill='white'
        fillOpacity='0.05'
      />
      <path
        d='M209.562 256.622L170.87 227.102C193.62 197.172 187.881 154.307 158.099 131.594C128.299 108.842 85.5825 114.734 62.8288 144.663L24.1366 115.144C45.218 87.4079 76.2832 71.6938 108.436 69.285C121.283 93.071 142.388 112.474 169.531 122.725C185.947 128.924 202.928 131.148 219.393 129.861C240.556 169.065 238.255 218.834 209.562 256.622Z'
        fill='white'
        fillOpacity='0.05'
      />
      <path
        d='M219.393 129.861C202.931 131.149 185.95 128.925 169.53 122.724C142.384 112.473 121.283 93.0705 108.436 69.2844C135.882 67.1768 164.104 74.7497 187.629 92.6966C201.172 103.003 211.787 115.749 219.393 129.861Z'
        fill='white'
        fillOpacity='0.05'
      />
    </svg>
  )
}

// The leaderboard intro banner.
export const LeaderboardBanner = ({
  sector,
  t,
}: {
  sector?: boolean
  t: Translate
}) => {
  return (
    <header className='relative bg-gradient-to-r from-[#07253F] to-[#1261A5]'>
      <div className={`${containerStyle} w-full py-8 text-white`}>
        <span className='text-xs'>
          {t('home')} / {t('leaderboard')}
        </span>
        <h1 className='text-3xl'>
          {t(sector ? 'sectors-title' : 'leaderboard')}
        </h1>
        <p className='text-sm'>{t(sector ? 'sectors-subtitle' : 'subtitle')}</p>
        <div className='absolute right-0 top-0'>
          <LeaderBoardSvgPattern t={t} />
        </div>
      </div>
    </header>
  )
}
