import React, { memo, useEffect, useState } from 'react'
import { useWebsiteContext } from '@app/components/providers/website'
import { HideElementsInput } from '../../inputs/hide-elements-input'

export const HideElementsBoxWrapper = ({
  hideElements: cu,
  url,
  activeSubscription,
}: {
  url?: string
  hideElements?: string[]
  activeSubscription?: boolean
}) => {
  const currentHideElements = cu ? cu.join(',') : ''
  const [hideElements, setHideElements] = useState<string>(
    currentHideElements || ''
  )
  const { updateWebsite } = useWebsiteContext()

  const onChangeHideElements = (e: React.ChangeEvent<any>) => {
    const nextValue = e?.target?.value
    const intValue = nextValue && isNaN(nextValue[0])
    if (nextValue && !intValue) {
      return
    }
    setHideElements(nextValue)
  }

  useEffect(() => {
    if (hideElements === currentHideElements) {
      return
    }
    const debounce = setTimeout(async () => {
      if (currentHideElements !== hideElements) {
        try {
          await updateWebsite({
            variables: { url, hideElements: hideElements.split(',') },
          })
        } catch (e) {
          console.error(e)
        }
      }
    }, 2300)

    return () => clearTimeout(debounce)
  }, [hideElements, currentHideElements, url, updateWebsite])

  return (
    <HideElementsInput
      activeSubscription={activeSubscription}
      hideElements={hideElements}
      url={url}
      onChangeHideElements={onChangeHideElements}
    />
  )
}

export const HideElementsBox = memo(HideElementsBoxWrapper)
