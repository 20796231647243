import { memo, useState } from 'react'
import { useWebsiteContext } from '@app/components/providers/website'
import { SitemapKeys, SiteMapOption } from '../../select/sitemap-input'
import { SitemapInput } from '../../inputs/sitemap-input'
import type { Translate } from 'next-translate'

const SitemapBoxWrapper = ({
  sitemap,
  url,
  domain,
  activeSubscription,
  demo,
  t,
}: {
  sitemap?: number
  url?: string
  domain?: string
  activeSubscription?: boolean
  demo?: boolean
  t?: Translate
}) => {
  const [sitemapOption, setSitemap] = useState<number>(
    typeof sitemap === 'boolean' ? 1 : (sitemap ?? 0)
  )
  const { updateWebsite } = useWebsiteContext()

  const onChangeEvent = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = SiteMapOption[event.target.value as SitemapKeys]
    setSitemap(nextValue)

    if (!demo) {
      try {
        await updateWebsite({
          variables: { url, sitemap: nextValue },
        })
      } catch (e) {
        console.error(e)
      }
    }
  }

  return (
    <SitemapInput
      onChange={onChangeEvent}
      activeSubscription={activeSubscription}
      sitemap={sitemapOption}
      domain={domain}
      t={t}
      url={url}
    />
  )
}

export const SitemapBox = memo(SitemapBoxWrapper)
