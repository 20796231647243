import { memo, useCallback } from 'react'
import { NavBarTitle } from './navigation'
import { useInputHeader } from './hooks'

import { Link } from '@app/components/stateless/typo/link'
import { Button } from './buttons/button'

import { InputHeaders } from './forms/input-headers'
import { useWebsiteData } from '@app/data'
import { GrClose } from 'react-icons/gr'
import { AppManager } from '@app/managers'
import { FeedIssue } from './feed/issue'
import { HeadlessFullScreenModal } from '../modal/headless-full'
import { fetcher } from '@app/utils/fetcher'
import { LineChart } from './charts/line-chart'
import { Dialog } from '@headlessui/react'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { ActionsForm } from './forms/input-form'
import { ModalOptions } from './cells/enums'
import { WebhookForm } from './forms/input-webhooks'
import { SnackBar } from './snack-bar'
import { type Translate } from 'next-translate'

export const defaultModalState = {
  open: false,
  data: null,
  title: '',
  url: '',
  error: '',
}

const UpperInput = ({ data, url }: any) => {
  const { t } = useTranslation('forms')

  const { customFields, removeFormField, addFormField, updateFormField } =
    useInputHeader(data)

  const customHeaders = customFields?.map((item: any) => ({
    key: item?.key,
    value: item?.value,
  }))

  const { updateWebsite } = useWebsiteData('', url, customHeaders, true)

  const inputProps = {
    customHeader: true,
    customFields,
    removeFormField,
    addFormField,
    updateFormField,
  }

  // todo: translate
  const onUpdateWebsite = async () => {
    try {
      await updateWebsite({
        variables: { url, customHeaders, filter: '' },
      })
      // AppManager.toggleSnack(
      //   true,
      //   'Headers updated successfully.',
      //   'message',
      //   true
      // )
      // todo find website and apply headers
    } catch (e) {
      AppManager.toggleSnack(true, e, 'error')
    }
  }

  return (
    <div className='p-4 space-y-6 container mx-auto'>
      <InputHeaders {...inputProps} />
      <Button
        onClick={onUpdateWebsite}
        className='capitalize hover:border-blue-600 hover:text-blue-600'
      >
        {t('save')}
      </Button>
    </div>
  )
}

interface FullScreenModalProps {
  open: boolean
  role?: number
  handleClose(x?: any): any
  handleClickOpen?(data: any, title: any, url: any, error: any): any
  handleClickOpenPlayer?(x?: any): any
  refetch(x?: any): any
  error?: string
  title?: string | number
  url: string
  data?: any
}

// handle the type of modal that should be displayed.
const handleModelType = (title: string | ModalOptions) => {
  const issuesModal = title === 'Issues'
  const headerModal = title === ModalOptions.Headers
  const actionsModal = title === ModalOptions.Actions
  const verifyModal = title === ModalOptions.VerifyDns
  const analyticsModal = title === ModalOptions.Analytics
  const cancelModal = title === ModalOptions.Cancel
  const webhooksModal = title === ModalOptions.Webhooks

  return {
    issuesModal,
    headerModal,
    verifyModal,
    analyticsModal,
    actionsModal,
    cancelModal,
    webhooksModal,
  }
}

// todo: split props into component
const Body = ({
  data,
  title = 'Issues',
  // onPress,
  url,
  error,
  refetch,
  t,
}: { t: Translate } & Partial<
  FullScreenModalProps & { onDnsStatusEvent?(): any }
>) => {
  const {
    issuesModal,
    headerModal,
    actionsModal,
    analyticsModal,
    webhooksModal,
  } = handleModelType(title)

  if (analyticsModal) {
    const analytics = data?.data

    return (
      <div className='py-6 container mx-auto max-h-[80vh] overflow-visible'>
        <LineChart title={url} data={analytics} />
      </div>
    )
  }

  if (webhooksModal) {
    return <WebhookForm webhooks={data} url={url} />
  }

  // custom headers
  if (headerModal) {
    return <UpperInput data={data} url={url} />
  }

  // custom actions add form updating
  if (actionsModal) {
    return <ActionsForm customFields={data} url={url} />
  }

  // TODO: remove for different way of determine issues content.
  const isFlatIssues =
    data && Array.isArray(data) && data?.some((o) => 'domain' in o == false)

  if (issuesModal && isFlatIssues) {
    return (
      <ul className='list-none py-2'>
        {data?.map((item: any, listIndex: number) => {
          return (
            <FeedIssue
              key={`${listIndex} ${item?.selector} ${item?.code}`}
              error={error}
              t={t}
              {...item}
            />
          )
        })}
      </ul>
    )
  }

  return (
    <div className='container mx-auto'>
      <p>{t('dashboard:empty-analytics')}</p>
      {refetch ? (
        <Button
          aria-label='refetch data'
          aria-haspopup='true'
          onClick={() => refetch()}
        >
          {t('vercel/deployment-status:reload')}
        </Button>
      ) : null}
    </div>
  )
}

const FullScreenModalWrapper = (props: FullScreenModalProps) => {
  const { t } = useTranslation('common')
  const {
    handleClose,
    open,
    data,
    title = 'Issues',
    // onPress,
    refetch,
    url,
    error,
  } = props

  const issuesModal = title === 'Issues'
  const issueCount = data?.length

  // check to see if the dns was confirmed
  const onDnsStatusEvent = useCallback(async () => {
    try {
      const domain = new URL(url).hostname
      const source = await fetcher('/website/dns', { domain }, 'POST')

      AppManager.toggleSnack(
        open,
        `${domain} is ${!source?.data?.verified ? 'not ' : ''}verified`,
        !source?.data?.verified ? 'error' : 'message'
      )

      if (source?.data?.verified) {
        refetch && refetch()
        handleClose && handleClose()
      }
    } catch (e) {
      console.error(e)
    }
  }, [url, refetch, handleClose, open])

  return (
    <HeadlessFullScreenModal open={open} onClose={handleClose}>
      <div className='flex w-full py-1.5 px-3 place-items-center place-content-between border-b border-gray-300 dark:border-gray-600'>
        <div className={'flex gap-x-2 place-items-center'}>
          <Button
            onClick={handleClose}
            aria-label='close'
            iconButton
            border={false}
            className={
              'hover:text-gray-600 dark:hover:text-gray-400 hover:border-gray-600 border border-transparent'
            }
          >
            <>
              <span className='sr-only'>{t('close-modal')}</span>
              <GrClose className='grIcon' />
            </>
          </Button>
          <Dialog.Title>
            <NavBarTitle
              title={
                [
                  ModalOptions.Actions,
                  ModalOptions.Headers,
                  ModalOptions.Webhooks,
                ].includes(title as ModalOptions)
                  ? t(
                      `dashboard:${ModalOptions[title as number].toLowerCase()}`
                    )
                  : (title as string)
              }
            />
          </Dialog.Title>
        </div>
        {url ? (
          <div className={'flex gap-x-1 place-items-center'}>
            <div className={'text-right'}>
              <Link
                href={`/website-details?url=${encodeURIComponent(url)}`}
                className={'hover:text-blue-600 hover:underline'}
              >
                {url}
              </Link>
              {issueCount && issuesModal ? (
                <p className='truncate max-w-[50vw]'>
                  {issueCount} {issuesModal && error ? 'issue' : 'page'}
                  {issueCount === 1 ? '' : 's'}
                </p>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      <Body {...props} onDnsStatusEvent={onDnsStatusEvent} t={t} />
      <SnackBar snackID={'fs-dynamic'} />
    </HeadlessFullScreenModal>
  )
}

export const FullScreenModal = memo(FullScreenModalWrapper)
