import { FC, memo, useMemo, useState } from 'react'
import { Website } from '@app/types'
import { Header4 } from '../header'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import ms from 'ms'
import { formatDateTime } from '@app/utils/format-date'
import { ArrowUpIcon, ArrowDownIcon } from '@radix-ui/react-icons'

type SortConfig = {
  key: keyof Website | null
  direction: 'ascending' | 'descending'
}

// Columns with translation keys
const columns = [
  { key: 'url', label: 'site-url' },
  { key: 'issuesInfo.errorCount', label: 'total-errors' },
  { key: 'issuesInfo.warningCount', label: 'total-warnings' },
  { key: 'issuesInfo.pageCount', label: 'total-pages' },
  { key: 'issuesInfo.accessScoreAverage', label: 'global-rank' },
  { key: 'issuesInfo.accessScore', label: 'sector-rank' },
  { key: 'crawlDuration', label: 'crawl-duration' },
  { key: 'lastScanDate', label: 'last-sync' },
]

// Helper function to access nested values
const accessValue = (obj: any, key: string) =>
  key.split('.').reduce((acc, part) => acc && acc[part], obj)

const WebsiteTableComponent: FC<{ websites: Website[] }> = ({ websites }) => {
  const { t } = useTranslation('dashboard')

  // State for sorting
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: null,
    direction: 'ascending',
  })

  // Sorting function
  const sortedWebsites = useMemo(
    () =>
      [...websites].sort((a, b) => {
        if (sortConfig.key) {
          const order = sortConfig.direction === 'ascending' ? 1 : -1
          const key = sortConfig.key
          const aValue = accessValue(a, key)
          const bValue = accessValue(b, key)

          // Different sort logic based on the type of value
          if (typeof aValue === 'number' && typeof bValue === 'number') {
            return (aValue - bValue) * order
          } else {
            return aValue.toString().localeCompare(bValue.toString()) * order
          }
        }
        return 0
      }),
    [websites, sortConfig]
  )

  // Function to request a sort
  const requestSort = (key: keyof Website) => {
    let direction: 'ascending' | 'descending' = 'ascending'
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }

  return (
    <div className='mx-auto py-4'>
      <div className='overflow-hidden border rounded-lg '>
        <div className='bg-blue-600 text-white px-6 relative py-0.5'>
          <Header4 className='text-lg xl:text-lg'>{t('site-details')}</Header4>
        </div>
        <div className='overflow-x-auto w-full'>
          <table className='min-w-full divide-y divide-gray-200'>
            <thead>
              <tr>
                {columns.map(({ key, label }) => (
                  <th
                    key={key}
                    className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer select-none'
                    onClick={() => requestSort(key as keyof Website)}
                  >
                    <div className='flex items-center'>
                      {t(label)}
                      <span
                        className={sortConfig.key === key ? '' : 'invisible'}
                      >
                        {sortConfig.direction === 'ascending' ? (
                          <ArrowUpIcon className='ml-2 h-4 w-4' />
                        ) : (
                          <ArrowDownIcon className='ml-2 h-4 w-4' />
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 overflow-auto'>
              {sortedWebsites.map((website, index) => (
                <tr key={index} className='hover:bg-gray-100'>
                  <td className='px-6 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900'>
                    {website.url}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-900'>
                    {website.issuesInfo?.errorCount || 0}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-900'>
                    {website.issuesInfo?.warningCount || 0}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm leading-5 font-semibold'>
                    {website?.issuesInfo?.pageCount || 0}
                  </td>
                  <td
                    className={`px-6 py-4 whitespace-nowrap text-sm leading-5 font-semibold text-${getScoreColor(website.issuesInfo?.accessScoreAverage || 0)}`}
                  >
                    {typeof website.issuesInfo?.accessScoreAverage !==
                    'undefined'
                      ? `${website.issuesInfo?.accessScoreAverage}/100`
                      : 'N/A'}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-900'>
                    {website.crawlDuration ? ms(website.crawlDuration) : 'N/A'}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-900'>
                    {formatDateTime(website?.lastScanDate, 'MM/dd/yy')}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const getScoreColor = (score: number) => {
  if (!score) {
    return 'gray-500'
  }

  if (score >= 75) {
    return 'green-600'
  }

  if (score >= 50) {
    return 'blue-600'
  }

  if (score >= 25) {
    return 'red-600'
  }

  return 'gray-500'
}

export const WebsiteTable = memo(WebsiteTableComponent)
