import { DashboardFormDialog } from '@app/components/general/dashboard-form-configuration'
import { Website } from '@app/types'

// Initial step form
export const StepThree = ({
  active,
  website,
}: {
  active: boolean
  website?: Website
}) => {
  return (
    <div className={`px-5 ${!active ? ' hidden' : ''}`}>
      <DashboardFormDialog website={website} />
    </div>
  )
}
