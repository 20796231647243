import React from 'react'
import Draggable from 'react-draggable'
import { NavBarTitle } from './navigation'
import { GrClose } from 'react-icons/gr'
import { issueExtractor } from '@app/utils'
import { FeedIssue } from './feed/issue'
import { Button } from './buttons'
import { HeadlessModal } from '../modal/headless'
import { useInteractiveContext } from '../providers/interactive'
import { classNames } from '@app/utils/classes'
import { useTranslation } from '@app/lib/hooks/useTranslation'

export function IssueModal({ issue }: any) {
  const { miniPlayer, setMiniPlayerContent } = useInteractiveContext()
  const { open, title } = miniPlayer
  const { t } = useTranslation('dashboard')

  const pageIssues = issueExtractor(issue)

  const onModalCloseEvent = () => setMiniPlayerContent(false)

  return (
    <HeadlessModal open={open} hideBackdrop onClose={onModalCloseEvent}>
      <Draggable allowAnyClick handle={'.appBar'}>
        <div className='rounded border'>
          <div
            className={`rounded-t text-black dark:text-white bg-white dark:bg-black`}
          >
            <div className='flex place-items-center py-2 px-2'>
              <Button
                onClick={onModalCloseEvent}
                className='hover:border-gray-600 hover:text-gray-600 dark:hover:text-gray-600 z-10'
                iconButton
              >
                <>
                  <span className='sr-only'>Close issue modal</span>
                  <GrClose className='grIcon' title='Close' />
                </>
              </Button>
              <div
                className={`flex flex-1 appBar space-x-2 place-items-center place-content-between`}
              >
                <NavBarTitle title={title} flex />
                {issue?.pageUrl ? (
                  <p className='line-clamp-1 text-lg'>{issue.pageUrl}</p>
                ) : null}
              </div>
            </div>
          </div>
          {pageIssues?.length ? (
            <ul
              className={classNames(
                pageIssues?.length === 1
                  ? 'max-h-auto'
                  : 'max-h-[50vh] overflow-y-auto',
                `list-none bg-white dark:bg-black z-100`
              )}
            >
              {pageIssues?.map((item: any, i) => (
                <FeedIssue
                  {...item}
                  t={t}
                  url={issue?.pageUrl}
                  key={`${item?.selector}-${item?.code}-${i}`}
                />
              ))}
            </ul>
          ) : null}
        </div>
      </Draggable>
    </HeadlessModal>
  )
}
