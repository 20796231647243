import { Translate } from 'next-translate'

export const NotificationsText = ({
  t,
  textHidden,
}: {
  t: Translate
  textHidden?: boolean
}) => {
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M12 10.5204C11.59 10.5204 11.25 10.1804 11.25 9.77043V6.44043C11.25 6.03043 11.59 5.69043 12 5.69043C12.41 5.69043 12.75 6.03043 12.75 6.44043V9.77043C12.75 10.1904 12.41 10.5204 12 10.5204Z' />
        <path d='M12.0208 20.3502C9.44084 20.3502 6.87084 19.9402 4.42084 19.1202C3.51084 18.8202 2.82084 18.1702 2.52084 17.3502C2.22084 16.5302 2.32084 15.5902 2.81084 14.7702L4.08084 12.6502C4.36084 12.1802 4.61084 11.3002 4.61084 10.7502V8.65023C4.61084 4.56023 7.93084 1.24023 12.0208 1.24023C16.1108 1.24023 19.4308 4.56023 19.4308 8.65023V10.7502C19.4308 11.2902 19.6808 12.1802 19.9608 12.6502L21.2308 14.7702C21.7008 15.5502 21.7808 16.4802 21.4708 17.3302C21.1608 18.1802 20.4808 18.8302 19.6208 19.1202C17.1708 19.9502 14.6008 20.3502 12.0208 20.3502ZM12.0208 2.75023C8.76084 2.75023 6.11084 5.40023 6.11084 8.66023V10.7602C6.11084 11.5702 5.79084 12.7402 5.37084 13.4302L4.10084 15.5602C3.84084 15.9902 3.78084 16.4502 3.93084 16.8502C4.08084 17.2502 4.42084 17.5502 4.90084 17.7102C9.50084 19.2402 14.5608 19.2402 19.1608 17.7102C19.5908 17.5702 19.9208 17.2502 20.0708 16.8302C20.2308 16.4102 20.1808 15.9502 19.9508 15.5602L18.6808 13.4402C18.2608 12.7502 17.9408 11.5802 17.9408 10.7702V8.67023C17.9308 5.40023 15.2808 2.75023 12.0208 2.75023Z' />
        <path d='M11.9999 22.9003C10.9299 22.9003 9.87992 22.4603 9.11992 21.7003C8.35992 20.9403 7.91992 19.8903 7.91992 18.8203H9.41992C9.41992 19.5003 9.69992 20.1603 10.1799 20.6403C10.6599 21.1203 11.3199 21.4003 11.9999 21.4003C13.4199 21.4003 14.5799 20.2403 14.5799 18.8203H16.0799C16.0799 21.0703 14.2499 22.9003 11.9999 22.9003Z' />
      </svg>
      <span className={`${textHidden ? 'hidden' : ''}`}>
        {t('notifications')}
      </span>
    </>
  )
}
