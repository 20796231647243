import { useEffect, useState } from 'react'
import { Card, CardContent } from '@app/@/components/ui/card'
import { type Translate } from 'next-translate'
import { DownArrow, UpArrow } from './icons/prev'

export const ErrorBlock = ({
  count,
  t,
  scanning,
}: {
  count: number
  t: Translate
  scanning?: boolean
}) => {
  const [prevCount, setPrevCount] = useState<number | null>(null)
  const [change, setChange] = useState({
    changeValue: 0,
    greater: false,
    neutral: true,
  })

  useEffect(() => {
    if (prevCount !== null && !scanning) {
      const difference = count - prevCount
      setChange({
        changeValue: Math.abs(difference),
        greater: difference > 0,
        neutral: difference === 0,
      })
    }
    setPrevCount(count)
  }, [count, scanning])

  const textColor = change.greater
    ? 'text-green-600 dark:text-green-700'
    : change.neutral
      ? 'text-gray-600 dark:text-gray-700'
      : 'text-red-600 dark:text-red-700'

  return (
    <Card>
      <div className='flex gap-3 flex-1 pt-6'>
        <div className='flex-1'>
          <CardContent className='space-y-3'>
            <h4 className='font-bold text-lg flex gap-2 items-center'>
              {t('num-errors')}
            </h4>

            <h5 className='text-gray-600 dark:text-gray-300 leading-8 text-base'>
              {count || 0}
            </h5>
            <div
              className={`font-light flex gap-2 items-center text-xs pt-2 text-muted-foreground ${change.neutral ? 'invisible' : ''}`}
            >
              <div className={`flex items-center gap-1 ${textColor} `}>
                {change.greater ? <UpArrow /> : <DownArrow />}
                <p className='text-xs'>{change.changeValue}</p>
              </div>
              {t('vs-prev')}
            </div>
          </CardContent>
        </div>
        <div className='px-4'>
          <svg
            width='48'
            height='48'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              width='48'
              height='48'
              rx='24'
              className='fill-red-100 dark:fill-red-900'
            />
            <path
              d='M24 34.75C18.07 34.75 13.25 29.93 13.25 24C13.25 18.07 18.07 13.25 24 13.25C29.93 13.25 34.75 18.07 34.75 24C34.75 29.93 29.93 34.75 24 34.75ZM24 14.75C18.9 14.75 14.75 18.9 14.75 24C14.75 29.1 18.9 33.25 24 33.25C29.1 33.25 33.25 29.1 33.25 24C33.25 18.9 29.1 14.75 24 14.75Z'
              fill='#ED0505'
              className='fill-red-500 dark:fill-red-300'
            />
            <path
              d='M21.1694 27.5804C20.9794 27.5804 20.7894 27.5104 20.6394 27.3604C20.3494 27.0704 20.3494 26.5904 20.6394 26.3004L26.2994 20.6404C26.5894 20.3504 27.0694 20.3504 27.3594 20.6404C27.6494 20.9304 27.6494 21.4104 27.3594 21.7004L21.6994 27.3604C21.5594 27.5104 21.3594 27.5804 21.1694 27.5804Z'
              className='fill-red-500 dark:fill-red-300'
            />
            <path
              d='M26.8294 27.5804C26.6394 27.5804 26.4494 27.5104 26.2994 27.3604L20.6394 21.7004C20.3494 21.4104 20.3494 20.9304 20.6394 20.6404C20.9294 20.3504 21.4094 20.3504 21.6994 20.6404L27.3594 26.3004C27.6494 26.5904 27.6494 27.0704 27.3594 27.3604C27.2094 27.5104 27.0194 27.5804 26.8294 27.5804Z'
              className='fill-red-500 dark:fill-red-300'
            />
          </svg>
        </div>
      </div>
    </Card>
  )
}
