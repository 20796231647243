import React, { FC, PropsWithChildren, ReactElement } from 'react'
import { classNames } from '@app/utils/classes'
import { GrCircleInformation } from 'react-icons/gr'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@app/@/components/ui/tooltip'

const styles = {
  infoContainer:
    'py-2.5 flex flex-col text-base justify-start gap-y-0.5 w-auto items-center',
  infoBorder: 'space-y-0.5',
  p: 'text-base',
  row: 'flex place-items-center space-x-2',
}

type PlacesType = 'top' | 'right' | 'bottom' | 'left'

type BaseProps = PropsWithChildren<{
  title?: string
  icon?: any
  className?: string
  toolTip?: string
  toolTipPosition?: PlacesType | undefined
  notrunc?: boolean
  noPx?: boolean
  tipID?: string
  flex?: boolean
  simpleClass?: string
  labelTip?: ReactElement
  noClass?: boolean
}>

export const InfoBlock: FC<BaseProps> = ({
  children,
  title,
  icon,
  className,
  toolTip,
  // toolTipPosition,
  // tipID,
  notrunc,
  noPx,
  flex,
  simpleClass,
  labelTip,
  noClass,
}) => {
  return (
    <div
      className={
        noClass
          ? undefined
          : simpleClass
            ? simpleClass
            : classNames(
                styles.infoContainer,
                icon ? styles.infoBorder : '',
                className || '',
                notrunc ? '' : 'truncate',
                noPx ? 'px-2.5' : 'px-4',
                flex ? 'flex-1' : ''
              )
      }
    >
      <div>
        {labelTip ? (
          <div className='flex gap-2 items-center text-sm pb-1'>
            {labelTip}
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger type='button'>
                  <GrCircleInformation className='grIcon w-3.5 h-3.5' />
                </TooltipTrigger>
                <TooltipContent>
                  <p>{toolTip}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        ) : null}
        {title || icon ? (
          <div className={styles.row}>
            {icon}
            {title ? <p className={styles.p}>{title}</p> : null}
          </div>
        ) : null}
        <div className='text-sm flex items-center gap-2'>
          {children}
          {toolTip && !labelTip ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger type='button'>
                  <GrCircleInformation className='grIcon' />
                </TooltipTrigger>
                <TooltipContent>
                  <p>{toolTip}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : null}
        </div>
      </div>
    </div>
  )
}
