import React from 'react'
import dynamic from 'next/dynamic'
import { NavbarMenu } from './navbar-menu'
import { MarketingNavMenu } from './marketing-nav-menu'

const AuthMenu = dynamic(
  () => import('../auth-menu').then((mod) => mod.AuthMenu),
  {
    loading: () => <p>...</p>,
  }
)

type NavbarInnerProps = {
  loading?: boolean
  authenticated?: boolean
  marketingLinks?: boolean
  title?: string
  authPage?: boolean
}

// the inner content of the navbar
export const NavbarInner = ({
  loading,
  authenticated,
  marketingLinks,
  authPage,
}: NavbarInnerProps) => {
  // marketing links
  if (marketingLinks) {
    return (
      <div className={`${loading ? 'hidden' : ''}`}>
        <MarketingNavMenu authPage={authPage} authenticated={authenticated} />
      </div>
    )
  }

  // graphql required authentication menu
  if (authenticated && !marketingLinks) {
    return (
      <div className={`${loading ? 'hidden' : ''}`}>
        <AuthMenu authenticated={authenticated} />
      </div>
    )
  }

  return (
    <div className={`${loading ? 'hidden' : ''}`}>
      <NavbarMenu authenticated={authenticated} />
    </div>
  )
}
