import { forwardRef, memo, useImperativeHandle } from 'react'
import { InnerWrapper } from '../../list-wrapper'
import { PagesList } from './pages-list'
import type { Analytic, Pages } from '@app/types'
import { usePagesData } from '@app/data/external/pages/pages'
import { PagingSearch } from './paging-search'
import {
  dataListStyle,
  pagingListStyle,
} from '@app/components/general/cells/card/head-style'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { usePaging } from '@app/data/external/analytics/use-paging'
import { classNames } from '@app/utils'

type PagesPagingProps = {
  pageUrl?: string
  liveData?: Analytic[]
  open?: boolean
  handleMainClick?(): any // modal
  extraActive?: boolean
  blockLive?: boolean
}

// paging issues for website dashboard cell
const RenderInnerPagesWrapper = (props: PagesPagingProps, ref: any) => {
  const { t } = useTranslation('dashboard')

  const {
    liveData,
    pageUrl,
    open: defaultOpen,
    handleMainClick,
    extraActive,
    blockLive,
  } = props

  const {
    data,
    loading,
    onLoadMorePages: onLoadMore,
    onSearchEvent,
    searchVisible,
    search,
    refetch,
  } = usePagesData(pageUrl, false)

  const {
    issueSource,
    searchRef,
    issueIndex,
    issueList,
    stats,
    blocked,
    onPrevSelect,
    onLoadEvent,
    onSearchSubmit,
    setSelectedIndex,
    setIndex,
  } = usePaging({
    liveData,
    search,
    filterActive: undefined,
    data,
    onLoadMore,
    collection: 'pages',
    blockLive,
  })

  const emptyData = !!data?.length

  useImperativeHandle(ref, () => {
    return {
      setSelectedIndex,
      setIndex,
      refetch: async () => {
        if (!emptyData) {
          try {
            await refetch()
          } catch (e) {
            console.error(e)
          }
        }
      },
    }
  }, [setSelectedIndex, setIndex, refetch, emptyData])

  return (
    <div className='flex flex-col place-content-around'>
      <PagingSearch
        searchVisible={searchVisible}
        onLoadEvent={onLoadEvent}
        onPrevSelect={onPrevSelect}
        onSearchEvent={onSearchEvent}
        onSearchSubmit={onSearchSubmit}
        loading={loading}
        blocked={blocked}
        ref={searchRef}
        issueIndex={issueIndex}
        source={issueSource}
        pages
        t={t}
      >
        <div className={dataListStyle}>
          <InnerWrapper data={issueSource.length} loading={loading}>
            <ul className={classNames(pagingListStyle, 'pr-4')}>
              {(issueList as Pages[]).map((page) => (
                <PagesList
                  key={page?._id ?? page.url ?? page.pageUrl}
                  open={defaultOpen}
                  handleMainClick={handleMainClick}
                  pageLoadTime={page.pageLoadTime}
                  online={page.online}
                  domain={page.domain}
                  url={page.url ?? page.pageUrl}
                  totalTTL={stats.totalTTL}
                  t={t}
                  extraActive={extraActive}
                />
              ))}
            </ul>
          </InnerWrapper>
        </div>
      </PagingSearch>
    </div>
  )
}

export const RenderInnerPagesPaging = memo(forwardRef(RenderInnerPagesWrapper))
