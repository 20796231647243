import React, { memo } from 'react'
import { useWebsiteContext } from '@app/components/providers/website'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { IgnoreInput } from '../../select/ignore-input'
import { InfoBlock } from '../info-block'
import { useKayleRules } from '@app/data/formatters/use-kayle-rules'
// import { useWebsiteContext } from '@app/components/providers/website'

export const IgnoreRulesBoxWrapper = ({
  url,
  websiteIgnore,
  runners,
  demo,
  lang,
  activeSubscription,
}: {
  url?: string
  domain?: string
  activeSubscription?: boolean
  demo?: boolean
  runners?: string[]
  lang?: string
  websiteIgnore?: string[]
}) => {
  const { t } = useTranslation('dashboard')

  const { ignore, setIgnore, ignoreList, onLoadRulesEvent } = useKayleRules({
    runners,
    lang: lang || 'en',
    websiteIgnore,
  })

  const { updateWebsite } = useWebsiteContext()

  const onIgnoreEvent = async (next: string[]) => {
    setTimeout(async () => {
      try {
        if (!demo) {
          await updateWebsite({
            variables: { url, ignore: next || [] },
          })
        }
      } catch (e) {
        console.error(e)
      }
    }, 300)
  }

  return (
    <InfoBlock>
      <IgnoreInput
        t={t}
        onLoadRulesEvent={onLoadRulesEvent}
        ignoreList={ignoreList}
        setIgnore={setIgnore}
        cb={onIgnoreEvent}
        ignore={ignore}
        disabled={!activeSubscription}
      />
    </InfoBlock>
  )
}

export const IgnoreRulesBox = memo(IgnoreRulesBoxWrapper)
