import { classNames } from '@app/utils/classes'
import React, { FC, PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

type HeaderProps = PropsWithChildren<{
  id?: string
  className?: string
  itemProp?: string
  style?: React.CSSProperties
  paddingLess?: boolean
  small?: boolean
}>

// h1
export const Header: FC<HeaderProps> = ({
  children,
  id,
  className,
  style,
  small,
}) => {
  const baseSize = small
    ? 'text-lg md:text-xl lg:text-2xl xl:text-3xl'
    : 'text-3xl md:text-3xl lg:text-4xl xl:text-5xl'
  return (
    <h1
      id={id}
      style={style}
      className={classNames(
        `font-black ${baseSize} sm:leading-[1.1em]`,
        className || ''
      )}
    >
      {children}
    </h1>
  )
}

const h2Css = `text-2xl md:text-2xl lg:text-3xl xl:text-4xl py-3 sm:leading-[1.1em]`
const h3Css = `text-xl md:text-2xl lg:text-3xl xl:text-4xl`
const h4Css = `text-lg md:text-xl lg:text-2xl xl:text-3xl py-2 sm:leading-[1.1em]`

export const Header2: FC<
  HeaderProps & { nobold?: boolean; head3?: boolean }
> = ({ children, className, itemProp, style, nobold, head3 }) => {
  return (
    <h2
      itemProp={itemProp}
      style={style}
      className={classNames(
        !nobold ? 'font-bold' : 'font-semibold',
        head3 ? h3Css : h2Css,
        className || ''
      )}
    >
      {children}
    </h2>
  )
}

export const Header3: FC<
  HeaderProps & { bold?: boolean; header4?: boolean }
> = ({ children, className, itemProp, style, bold, paddingLess, header4 }) => {
  return (
    <h3
      itemProp={itemProp}
      style={style}
      className={twMerge(
        bold ? 'font-bold' : 'font-semibold',
        header4 ? h4Css : h3Css,
        className || '',
        paddingLess ? '' : 'py-2.5'
      )}
    >
      {children}
    </h3>
  )
}

export const Header4: FC<HeaderProps & { bold?: boolean }> = ({
  children,
  className,
  itemProp,
  style,
  bold,
}) => {
  return (
    <h4
      itemProp={itemProp}
      style={style}
      className={classNames(
        bold ? 'font-semibold' : 'font-medium',
        `text-base md:text-lg lg:text-xl xl:text-2xl py-2 sm:leading-[1.1em]`,
        className || ''
      )}
    >
      {children}
    </h4>
  )
}

export const Header5: FC<HeaderProps> = ({ children, style }) => {
  return (
    <h5
      style={style}
      className={`font-medium text-base md:text-lg lg:text-xl xl:text-2xl py-2 sm:leading-[1.1em]`}
    >
      {children}
    </h5>
  )
}
