import React, { memo, Fragment, ReactElement } from 'react'
import { useWebsiteContext } from '../providers/website'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@app/@/components/ui/tooltip'
import { type FormDialogProps } from '@app/data/local/useWebsiteForm'
import { buttonVariants } from '@app/@/components/ui/button'

export function FormDialogWrapper({
  buttonTitle = 'add-new',
  icon,
  translation,
}: FormDialogProps & { buttonTitle?: string | ReactElement }) {
  const { t } = useTranslation(translation || 'dashboard')
  const { addWebsiteForm } = useWebsiteContext()

  return (
    <Fragment>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger
            onClick={addWebsiteForm.handleClickOpen}
            className={`${buttonVariants()} bg-blue-700 dark:bg-blue-500 flex items-center text-white`}
            type='button'
          >
            {icon ? (
              <svg
                width='25'
                height='24'
                viewBox='0 0 25 24'
                xmlns='http://www.w3.org/2000/svg'
                className='fill-white dark:fill-white'
              >
                <title>Add</title>
                <path
                  d='M18.5 12.75H6.5C6.09 12.75 5.75 12.41 5.75 12C5.75 11.59 6.09 11.25 6.5 11.25H18.5C18.91 11.25 19.25 11.59 19.25 12C19.25 12.41 18.91 12.75 18.5 12.75Z'
                  className='fill-white'
                />
                <path
                  d='M12.5 18.75C12.09 18.75 11.75 18.41 11.75 18V6C11.75 5.59 12.09 5.25 12.5 5.25C12.91 5.25 13.25 5.59 13.25 6V18C13.25 18.41 12.91 18.75 12.5 18.75Z'
                  className='fill-white'
                />
              </svg>
            ) : null}
            {typeof buttonTitle === 'string'
              ? t(buttonTitle || 'add-new')
              : buttonTitle}
          </TooltipTrigger>
          <TooltipContent>
            <p>{t('add-tip')}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </Fragment>
  )
}

export const FormDialog = memo(FormDialogWrapper)
