import React, { memo, useState } from 'react'
import { useWebsiteContext } from '@app/components/providers/website'
import { AppManager } from '@app/managers'
import { Standard } from '../../select/select-input'
import { StandardInput } from '../../inputs/standard-input'

type StandardKey = keyof typeof Standard

export const StandardBoxWrapper = ({
  standard: prevStandard,
  url,
  domain,
  activeSubscription,
}: {
  standard?: StandardKey
  url?: string
  domain?: string
  activeSubscription?: boolean
}) => {
  const [standard, setStandard] = useState<StandardKey>(
    prevStandard || 'WCAG2AA'
  )
  const { updateWebsite } = useWebsiteContext()

  const onStandardChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event?.target?.value as StandardKey
    setStandard(value)

    try {
      await updateWebsite({
        variables: { url, standard: value },
      })
    } catch (e) {
      AppManager.toggleSnack(true, 'Error, an issue has occurred.', 'error')
    }
  }

  return (
    <StandardInput
      standard={standard}
      url={url}
      domain={domain}
      onChange={onStandardChange}
      activeSubscription={activeSubscription}
    />
  )
}

export const StandardBox = memo(StandardBoxWrapper)
