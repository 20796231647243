import { memo } from 'react'
import { InfoBlock } from '../cells'
import { inputStyle } from '../cells/blocks/input-style'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { InputBoxProps } from '@app/types/dashboard'
import { Input } from '@app/@/components/ui/input'

interface HideElements extends InputBoxProps {
  url?: string
  hideElements?: string
  activeSubscription?: boolean
  onChangeHideElements?(e: React.ChangeEvent<any>): void
  translation?: string
  id?: string
  text?: string
}

export const HideElementsBoxWrapperInput = ({
  hideElements,
  url,
  activeSubscription,
  onChangeHideElements,
  translation,
  id,
  text,
  dialog,
  defaultValue,
}: HideElements) => {
  const { t } = useTranslation(translation || 'dashboard')

  const hideElementsText = text || t('hide-elements')
  const hideLabel = `${url || id}-hide-elements`

  return (
    <InfoBlock
      toolTip={t('dashboard-inputs:hide-tip')}
      toolTipPosition={'left'}
      tipID={'hide-id'}
      noPx={dialog}
      labelTip={<label htmlFor={hideLabel}>{hideElementsText}</label>}
      noClass
    >
      <Input
        className={inputStyle(activeSubscription, dialog)}
        onChange={onChangeHideElements}
        value={hideElements}
        id={hideLabel}
        defaultValue={defaultValue}
        placeholder={hideElementsText}
        type='text'
        name={'hide_selector'}
      />
    </InfoBlock>
  )
}

export const HideElementsInput = memo(HideElementsBoxWrapperInput)
