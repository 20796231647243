import {
  FunctionComponent,
  Fragment,
  memo,
  useState,
  SyntheticEvent,
  useRef,
  useCallback,
} from 'react'
import Trans from 'next-translate/Trans'
import { GrGithub } from 'react-icons/gr'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { REST_API, companyName } from '@app/configs/app-config'
import { GoogleLoginButton } from '../google-login'
import { useRouter } from 'next/router'
import { useMutation } from '@apollo/client'
import { REGISTER, LOGIN } from '@app/mutations'
import { AppManager, UserManager } from '@app/managers'
import { LinearBottom } from '../loaders'
import { Header, Header2 } from '../header'
import { Button } from '../buttons'
import { FormControl } from '../form-control'
import { TextField } from '../text-field'
import { classNames } from '@app/utils/classes'
import { routeParseAbsolute } from '@app/lib/router-handler'
import { Link } from '@app/components/stateless/typo/link'
import { SnackBar } from '../snack-bar'
import { PasswordFields } from './password-fields'
import { twMerge } from 'tailwind-merge'
import { P } from '@app/components/stateless/typo/p'
import { DashboardBase } from '@app/components/dashboard/dashboard-base'
import { HomeBtn } from '../navigation/home-btn'

const clientID = process.env.GITHUB_CLIENT_ID
  ? process.env.GITHUB_CLIENT_ID.trim()
  : ''

const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID

const HIDE_3RD_PARTY = process.env.NEXT_PUBLIC_HIDE_3RD_PARTY === 'true'

const redirectGithub = `${REST_API}/github/callback`

interface SignOnProps {
  loginView?: boolean
  googleLoginSkeleton?: boolean
}

const minW = 'min-w-[265px]'

const linkText =
  'underline text-xs capitalize hover:text-blue-600 dark:hover:text-blue-600'

const btnCss = `${minW} md:rounded-sm font-medium py-1.5 bg-gray-500 dark:bg-gray-600 text-white`

const btnCssHover =
  'hover:bg-gray-700 dark:hover:bg-gray-50 hover:text-white dark:hover:text-gray-900 hover:border-gray-700 focus:border-gray-800'

export const btnAuthCss = classNames(
  'rounded place-items-center place-content-center',
  `inline-flex gap-x-2 border-2 font-semibold ${minW} text-sm min-h-[40px] border-gray-700 dark:border-gray-700`,
  btnCssHover
)

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

const isValidEmail = (email: string) => emailPattern.test(email)

// determine if alias email
const isInvalidEmail = (email: string) => {
  const atIndex = email.indexOf('@')
  const plusIndex = email.lastIndexOf('+')

  if (plusIndex < 0) {
    return false
  }

  // return if plus is after the index to include invalid
  if (plusIndex >= atIndex) {
    return true
  }

  return true
}

const SignOnFormWrapper: FunctionComponent<SignOnProps> = ({
  loginView,
  googleLoginSkeleton = false,
}) => {
  const router = useRouter()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const { t, lang } = useTranslation('common')
  const { t: c } = useTranslation('auth')
  const { t: q } = useTranslation(loginView ? 'login' : 'register')

  const [signOnMutation, { loading }] = useMutation(
    loginView ? LOGIN : REGISTER
  )

  const domainMap = useRef<Record<string, null>>({})

  const onDisposableEvent = useCallback(async () => {
    if (!loginView) {
      // add additional abusive
      domainMap.current['dogemn.com'] = null
      domainMap.current['cyclesat.com'] = null
      domainMap.current['fectode.com'] = null
      domainMap.current['jthoven.com'] = null
      domainMap.current['marikuza.com'] = null
      domainMap.current['mitigado.com'] = null
      domainMap.current['lieboe.com'] = null
      domainMap.current['snowlash.com'] = null
      domainMap.current['momoshe.com'] = null
      domainMap.current['raotus.com'] = null
    }
  }, [loginView])

  const onSuccessAuth = useCallback(
    (data: any) => {
      const user = data && data[loginView ? 'login' : 'register']

      if (user) {
        UserManager.setUser(user)

        const p = router?.query?.plan
        const plan = p && (String(p).toLowerCase() as string)

        const urlRoute = plan
          ? `/payments?plan=${router?.query?.plan}`
          : '/dashboard'

        // use window location to navigation
        window.location.href = routeParseAbsolute(urlRoute, lang)
      } else {
        AppManager.toggleSnack(true, c('auth-error'), 'error', true)
      }
    },
    [loginView, router?.query?.plan, c, lang]
  )

  const submit = useCallback(
    async (e: any) => {
      if (e && e?.preventDefault) {
        e?.preventDefault()
      }

      let data = null

      if (!password || !email) {
        return AppManager.toggleSnack(
          true,
          c(!password ? 'password-short' : 'generic-error'),
          'error'
        )
      }

      // base checking email validation include invalid emails with alias checking
      if (!isValidEmail(email) || isInvalidEmail(email)) {
        return AppManager.toggleSnack(true, c('invalid-email'), 'error')
      }

      if (!loginView) {
        await onDisposableEvent()

        const domain = email.split('@').pop()

        // validate temp emails todo one shot object check
        if (!domain || domainMap.current.hasOwnProperty(domain)) {
          return AppManager.toggleSnack(true, c('temp-email'), 'error')
        }
      }

      try {
        const res = await signOnMutation({
          variables: {
            email: email.trim().toLowerCase(),
            password,
            language: lang,
            application: companyName.toLowerCase(),
          },
        })

        if (res) {
          data = res.data
        }

        onSuccessAuth(data)
      } catch (e: unknown) {
        const errorMessage =
          e && e instanceof Error ? e.message : c('auth-error')

        AppManager.toggleSnack(
          true,
          errorMessage ===
            'Temporary emails are not allowed. Use a real email address to sign up.'
            ? c('auth:temp-email')
            : errorMessage,
          'error',
          true
        )
      }
    },
    [
      c,
      email,
      loginView,
      password,
      onDisposableEvent,
      signOnMutation,
      onSuccessAuth,
      lang,
    ]
  )

  // on google authentication
  const onGoogleAuth = useCallback(
    async (response: any) => {
      let userInfo = null
      let resData = null

      const gheaders = new Headers()

      gheaders.append(
        'Authorization',
        `${response.token_type || 'Bearer'} ${response.access_token}`
      )

      try {
        userInfo = await fetch(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          {
            method: 'GET',
            headers: gheaders,
            redirect: 'follow',
          }
        )
      } catch (e) {
        console.error(e)
      }

      // successful response
      if (userInfo && userInfo.ok) {
        try {
          resData = await userInfo.json()
        } catch (e) {
          console.error(e)
        }
      }

      // VALID GOOGLE AUTH
      if (resData) {
        try {
          const res = await signOnMutation({
            variables: {
              email: resData?.email,
              googleId: resData?.sub,
              password: '',
              language: lang,
              application: companyName.toLowerCase(),
            },
          })

          res && onSuccessAuth(res.data)
        } catch (e: unknown) {
          if (e instanceof Error) {
            console.error(e.message)
            AppManager.toggleSnack(true, e.message, 'error', true)
          }
        }
      } else {
        AppManager.toggleSnack(true, c('google-error'), 'error', true)
      }
    },
    [c, onSuccessAuth, signOnMutation, lang]
  )

  const onChangeEmailEvent = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setEmail(e.currentTarget.value),
    [setEmail]
  )

  const onChangePasswordEvent = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setPassword(e.currentTarget.value),
    [setPassword]
  )

  const formEmailDisabled = !email && !password
  const ghText = q('auth-github')

  const hidden3rdPartyAuth = HIDE_3RD_PARTY || (!clientID && !GOOGLE_CLIENT_ID)

  return (
    <Fragment>
      <div className='container mx-auto max-w-xl rounded place-content-center py-20'>
        <div className='pb-2 text-center'>
          <Header2 className='max-w-auto'>{q('header')}</Header2>
          <P className='text-balance'>{q('header-details')}</P>
        </div>
        <div className={'text-center p-3'}>
          {hidden3rdPartyAuth ? null : (
            <div className={'space-y-2 flex flex-col place-items-center'}>
              {GOOGLE_CLIENT_ID ? (
                <GoogleLoginButton
                  loginView={loginView}
                  onSuccess={onGoogleAuth}
                  skeleton={googleLoginSkeleton}
                  className={btnAuthCss}
                  round
                />
              ) : null}
              {clientID ? (
                <a
                  href={`https://github.com/login/oauth/authorize?client_id=${clientID}&redirect_uri=${redirectGithub}${
                    router?.query?.plan ? `?plan=${router.query.plan}` : ''
                  }`}
                  target='_parent'
                  rel='noreferrer'
                  className={classNames(btnAuthCss, 'm-0')}
                >
                  <GrGithub className='grIcon text-lg' />
                  <span className='min-w-[120.61px] rendering-legibility'>
                    {ghText}
                  </span>
                </a>
              ) : null}
            </div>
          )}

          {hidden3rdPartyAuth ? null : (
            <hr className={'my-6 dark:border-gray-700'} role='presentation' />
          )}

          <form autoComplete={loginView ? 'on' : 'off'} onSubmit={submit}>
            <div>
              <FormControl htmlFor='email'>{c('email')}</FormControl>
              <TextField
                id='email'
                aria-describedby='my-email-text'
                placeholder={c('email')}
                type='email'
                minLength={5}
                maxLength={254}
                onChange={onChangeEmailEvent}
                value={email}
                autoFocus={false}
                onFocus={onDisposableEvent}
                autoComplete='email'
                className={minW}
                required
              />
              <p
                id='my-email-text'
                className={'text-center text-xs text-gray-600 py-2'}
              >
                {c('email-safe')}
              </p>
            </div>
            <div>
              <FormControl htmlFor='password'>{c('password')}</FormControl>
              <PasswordFields
                id='password'
                aria-describedby='my-password-text'
                className={minW}
                placeholder={c('password')}
                minLength={6}
                onChange={onChangePasswordEvent}
                value={password}
                type='password'
                autoFocus={false}
                autoComplete='current-password'
                required
                t={c}
              />
              <p
                id='my-password-text'
                className={'text-center text-xs text-gray-600 py-2'}
              >
                {c('password-safe')}
              </p>
            </div>
            <div className='py-3'>
              <Button
                className={twMerge(
                  btnCss,
                  formEmailDisabled
                    ? ''
                    : `bg-gray-600 dark:bg-gray-300 dark:text-gray-700 ${btnCssHover}`
                )}
                border={false}
                round={false}
                type='submit'
                disabled={formEmailDisabled}
              >
                {t(loginView ? 'login' : 'register')}
              </Button>
            </div>
          </form>

          <p className='text-xs'>
            {c('forgot-password')}{' '}
            <Link
              href={routeParseAbsolute('/reset-password', lang)}
              className={linkText}
              shouldPrefetch={false}
            >
              {t('auth:reset')}
            </Link>{' '}
            {t('or')}
            <Link
              href={routeParseAbsolute(
                loginView ? '/register' : '/login',
                lang
              )}
              shouldPrefetch={false}
              className={linkText}
            >
              {t(`common:${loginView ? 'register' : 'login'}`)}
            </Link>
          </p>
        </div>

        {!loginView ? (
          <Trans
            i18nKey='auth:account-aggree'
            values={{ companyName: companyName }}
            components={[
              <p
                className={'text-sm px-3 text-center py-4 line-clamp-3'}
                key={'auth-1'}
              />,
              <Link
                key={'auth-2'}
                href={routeParseAbsolute(`/terms-of-service`, lang)}
                className={linkText}
                shouldPrefetch={false}
              />,
              <Link
                key={'auth-3'}
                href={routeParseAbsolute(`/privacy`, lang)}
                className={linkText}
                shouldPrefetch={false}
              />,
            ]}
          />
        ) : null}
      </div>
      <LinearBottom loading={loading} />
    </Fragment>
  )
}

const SignOnFormMemo = memo(SignOnFormWrapper)

export const SignOnForm = (props: SignOnProps) => {
  const { t: c } = useTranslation('auth')

  return (
    <div className='md:flex h-[100vh]'>
      <div className='hidden md:flex flex-1 pt-10 container mx-auto overflow-hidden bg-primary-gradient'>
        <div>
          <HomeBtn whiteFill />
          <div className='pl-12 md:pt-20 '>
            <Header small className='w-1/2  text-white'>
              {c('header')}
            </Header>
            <p className='w-2/3 text-white'>{c('header-subtitle')}</p>
            <div className='flex gap-2.5 items-center py-2'>
              <span className='w-3 h-3 rounded-full bg-gray-300'></span>
              <span className='w-3 h-3 rounded-full bg-gray-300'></span>
              <span className='w-8 h-3 rounded-full bg-gray-50'></span>
            </div>

            <div className='relative pt-10'>
              <div className='absolute w-[100vw]'>
                <div className='p-6 rounded-xl bg-blue-500 bg-opacity-90'>
                  <div className='p-6 rounded-xl bg-blue-300 bg-opacity-80'>
                    <div className='p-1 rounded-xl bg-white dark:bg-black'>
                      <div className='bg-white dark:bg-black rounded-xl'>
                        <DashboardBase defaultOpenMenu={true} mock />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='md:flex-1 pt-10 md:pt-40 bg-gray-50 dark:bg-gray-900'>
        {GOOGLE_CLIENT_ID ? (
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <SignOnFormMemo
              loginView={props && props.loginView}
              googleLoginSkeleton={props && props.googleLoginSkeleton}
            />
          </GoogleOAuthProvider>
        ) : (
          <SignOnFormMemo
            loginView={props && props.loginView}
            googleLoginSkeleton={false}
          />
        )}
        <SnackBar topLevel />
      </div>
    </div>
  )
}
