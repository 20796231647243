import { P } from '@app/components/stateless/typo/p'
import { getStepBtnStyle } from './styles'
import { CompletedCheck } from './completed-check'

export const StepTwoMenuItem = ({
  active,
  completed,
}: {
  active?: boolean
  completed?: boolean
}) => {
  return (
    <li className='flex gap-2 min-w-[10rem]'>
      <div>
        {completed && !active ? (
          <CompletedCheck />
        ) : (
          <div className={getStepBtnStyle(active)}>
            <svg
              width='28'
              height='28'
              viewBox='0 0 28 28'
              xmlns='http://www.w3.org/2000/svg'
              className={active ? 'fill-blue-700' : 'fill-gray-700'}
            >
              <path d='M14 26.5414C13.1833 26.5414 12.355 26.2264 11.7367 25.6081L9.74164 23.6364C9.25164 23.1464 8.57501 22.878 7.88668 22.878H7C4.585 22.878 2.625 20.9297 2.625 18.5381V5.80969C2.625 3.41803 4.585 1.46973 7 1.46973H21C23.415 1.46973 25.375 3.41803 25.375 5.80969V18.5381C25.375 20.9297 23.415 22.878 21 22.878H20.1133C19.425 22.878 18.7484 23.1581 18.2584 23.6364L16.2633 25.6081C15.645 26.2264 14.8167 26.5414 14 26.5414ZM7 3.20805C5.55333 3.20805 4.375 4.37468 4.375 5.79801V18.5264C4.375 19.9614 5.55333 21.1164 7 21.1164H7.88668C9.04168 21.1164 10.1616 21.583 10.9783 22.3881L12.9733 24.3597C13.545 24.9197 14.4667 24.9197 15.0384 24.3597L17.0333 22.3881C17.85 21.583 18.97 21.1164 20.125 21.1164H21C22.4467 21.1164 23.625 19.9497 23.625 18.5264V5.79801C23.625 4.36301 22.4467 3.20805 21 3.20805H7Z' />
              <path d='M13.9996 12.5421C12.0163 12.5421 10.4062 10.9321 10.4062 8.94875C10.4062 6.96542 12.0163 5.35547 13.9996 5.35547C15.9829 5.35547 17.593 6.96542 17.593 8.94875C17.593 10.9321 15.9829 12.5421 13.9996 12.5421ZM13.9996 7.10547C12.9846 7.10547 12.1563 7.93375 12.1563 8.94875C12.1563 9.96375 12.9846 10.7921 13.9996 10.7921C15.0146 10.7921 15.843 9.96375 15.843 8.94875C15.843 7.93375 15.0146 7.10547 13.9996 7.10547Z' />
              <path d='M18.6673 19.1451C18.189 19.1451 17.7923 18.7484 17.7923 18.2701C17.7923 16.6601 16.089 15.3418 14.0007 15.3418C11.9123 15.3418 10.209 16.6601 10.209 18.2701C10.209 18.7484 9.81232 19.1451 9.33398 19.1451C8.85565 19.1451 8.45898 18.7484 8.45898 18.2701C8.45898 15.6917 10.944 13.5918 14.0007 13.5918C17.0573 13.5918 19.5423 15.6917 19.5423 18.2701C19.5423 18.7484 19.1457 19.1451 18.6673 19.1451Z' />
            </svg>
          </div>
        )}
      </div>
      <div>
        <h4 className='xl:text-lg leading-tight'>Step 2/5</h4>
        <P className='text-xs md:text-sm text-muted-foreground'>
          General Information
        </P>
      </div>
    </li>
  )
}
