import { memo } from 'react'
import { InfoBlock } from '../cells'
import { labelStyle, selectStyle } from '../cells/blocks/input-style'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { FormControl } from '../form-control'
import { inputContainerStyle } from './css'

const SubDomainsInputWrapper = ({
  subdomains,
  url,
  domain,
  activeSubscription,
  translation,
  onChange,
  defaultChecked,
}: {
  subdomains?: boolean
  url?: string
  domain?: string
  activeSubscription?: boolean
  translation?: string
  onChange?(x: any): void
  defaultChecked?: boolean
}) => {
  const { t } = useTranslation(translation || 'dashboard')

  const labelId = `${domain || url}-subdomains-form`

  const textBase = t('subdomains')

  return (
    <InfoBlock toolTip={t('dashboard-inputs:subdomains-tip')}>
      <div className={inputContainerStyle}>
        <FormControl
          htmlFor={labelId}
          visible
          disabled={!activeSubscription}
          className={labelStyle}
        >
          {textBase}
        </FormControl>

        <input
          checked={subdomains}
          type='checkbox'
          id={labelId}
          disabled={!activeSubscription}
          onChange={onChange}
          name={'subdomains'}
          defaultChecked={defaultChecked}
          className={selectStyle(activeSubscription)}
        ></input>
      </div>
    </InfoBlock>
  )
}

export const SubDomainsInput = memo(SubDomainsInputWrapper)
