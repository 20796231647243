import { memo, ChangeEvent } from 'react'
import { Standard, WCAGSelectInput } from '../select/select-input'
import { InfoBlock } from '../cells'
// import { useWebsiteContext } from '@app/components/providers/website'

type StandardKey = keyof typeof Standard

const StandardInputWrapper = ({
  standard,
  url,
  domain,
  activeSubscription,
  onChange,
  defaultValue,
}: {
  standard?: StandardKey
  url?: string
  domain?: string
  activeSubscription?: boolean
  onChange?(
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void
  defaultValue?: StandardKey
}) => {
  const label = `${domain || url}-standard-type`

  return (
    <InfoBlock>
      <WCAGSelectInput
        standard={standard}
        onStandardChange={onChange}
        disabled={!activeSubscription}
        label={label}
        defaultValue={defaultValue}
        borderLess
      />
    </InfoBlock>
  )
}

export const StandardInput = memo(StandardInputWrapper)
