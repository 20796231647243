import { Link } from '@app/components/stateless/typo/link'
import { DOMAIN_NAME } from '@app/configs'
import { apiRoute } from '@app/configs/api-route'
import { classNames } from '@app/utils/classes'
import { Translate } from 'next-translate'
import { memo, useMemo } from 'react'
import { GrDocumentCsv, GrDocumentExcel } from 'react-icons/gr'
import ms from 'ms'

type CellHeaderProps = {
  url?: string
  totalIssues?: number
  warningCount?: number
  errorCount?: number
  domain: string
  online?: boolean
  duration?: number
  handleMainClick?(data: any, name: string, _mini: boolean, url: string): any
  totalTTL: number
  extraActive?: boolean
  demo?: boolean
  t?: Translate
}

const textDirection = 'ltr:text-left rtl:text-right'

const ListCellPagesHeaderW = ({
  url = '',
  online,
  duration,
  totalTTL: _totalTTL,
  extraActive,
  demo,
  t,
}: CellHeaderProps) => {
  const [u, link] = useMemo(() => {
    if (typeof window !== 'undefined' && url) {
      try {
        return [
          url.startsWith('https://')
            ? url.replace('https://', '')
            : url.replace('http://', ''),
          `${DOMAIN_NAME}/website-details?url=${encodeURI(url)}`,
        ]
      } catch (e) {
        console.error(e)
      }
    }
    return ['', '']
  }, [url])

  // return a small single row of the page and issues with a dropdown
  return (
    <>
      <li
        className={classNames(
          'flex place-items-center text-xs md:text-sm row-bg ltr:after:left-[4px] rtl:after:right-[4px] relative hover:after:bg-transparent'
        )}
      >
        {demo ? (
          <div
            className={classNames(
              `px-4 py-2 place-items-center flex-1 max-w-2/3 md:w-auto line-clamp-1 truncate text-xs md:text-sm hover:text-blue-600`,
              textDirection
            )}
          >
            {u}
          </div>
        ) : (
          <Link
            className={classNames(
              `px-4 py-2 place-items-center flex-1 max-w-2/3 md:w-auto line-clamp-1 truncate text-xs md:text-sm hover:text-blue-600`,
              textDirection
            )}
            href={link}
          >
            {u}
          </Link>
        )}
        {extraActive ? (
          <div className='grid grid-cols-2 auto-cols-max place-items-center text-right'>
            <Link
              href={`${apiRoute}/report?download=true&q=${url}`}
              className='w-[2.2rem] md:w-[2.25rem] hover:text-blue-700'
            >
              <>
                <span className='sr-only'>Download Excel</span>
                <GrDocumentExcel className='grIcon text-xs' />
              </>
            </Link>
            <Link
              href={`${apiRoute}/report?csv=true&q=${url}`}
              className={'hover:text-blue-700'}
            >
              <>
                <span className='sr-only'>Download CSV</span>
                <GrDocumentCsv className='grIcon text-xs' />
              </>
            </Link>
          </div>
        ) : (
          <div className='grid grid-cols-2 auto-cols-max place-items-center text-right'>
            <div className='w-[2.7rem] md:w-[3.8rem]'>
              {!!online || typeof online === 'undefined'
                ? t
                  ? t('dashboard-modal:yes')
                  : 'true'
                : t
                  ? t('dashboard-modal:no')
                  : 'false'}
            </div>
            <div className='w-[3.9rem] md:w-[4.2rem] truncate'>
              {duration ? ms(duration) : 'N/A'}
            </div>
          </div>
        )}
      </li>
    </>
  )
}

export const ListCellPagesHeader = memo(ListCellPagesHeaderW)
