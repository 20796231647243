import { useQuery, useMutation, useSubscription } from '@apollo/client'

import {
  UPDATE_USER,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  CONFIRM_EMAIL,
  FILTER_EMAIL_DATES,
} from '@app/mutations'
import { GET_USER } from '@app/queries'
import { UserManager } from '@app/managers'
import {
  EMAIL_VERIFIED_SUBSCRIPTION,
  ROLE_ADJUSTED_SUBSCRIPTION,
} from '@app/subscriptions'
import { GET_USER_PROFILE, GET_USER_SETTINGS } from '@app/queries/user'
import { User } from '@app/types'
import { useAuthContext } from '@app/components/providers/auth'
import { useCallback } from 'react'
import { toast } from '@app/@/components/ui/use-toast'
import { useTranslation } from '@app/lib/hooks/useTranslation'

export const useUserData = (skip?: boolean, query?: 'profile' | 'settings') => {
  const { t } = useTranslation('dashboard')

  const profileQuery = query === 'profile'
  const settingsQuery = query === 'settings'

  const { onRoleAdjustedEvent, reloadAccount } = useAuthContext()

  const onCompletedUserEvent = useCallback(
    async ({ user }: { user: User }) => {
      if (user?.role !== UserManager.jwtParsed?.audience) {
        await reloadAccount()
      }
    },
    [reloadAccount]
  )

  // GENERAL USER QUERY
  const { data, loading } = useQuery(GET_USER, {
    skip: skip || profileQuery || settingsQuery,
    ssr: false,
    onCompleted: onCompletedUserEvent,
    onError: () => {},
  })

  // PROFILE PAGE QUERY
  const {
    data: profile,
    loading: profileLoading,
    refetch,
  } = useQuery(GET_USER_PROFILE, {
    skip: !profileQuery,
    ssr: false,
  })

  // SETTINGS PAGE QUERY
  const {
    data: settings,
    loading: settingsLoading,
    refetch: refetchSettings,
  } = useQuery(GET_USER_SETTINGS, {
    skip: !settingsQuery,
    ssr: false,
  })

  const [updateUser, { data: updateUserData, loading: updateUserLoading }] =
    useMutation(UPDATE_USER)

  const [
    forgotPassword,
    { data: forgotPasswordData, loading: forgotPasswordLoading },
  ] = useMutation(FORGOT_PASSWORD)

  const [
    resetPassword,
    { data: resetPasswordData, loading: resetPasswordLoading },
  ] = useMutation(RESET_PASSWORD)

  const [confirmEmail] = useMutation(CONFIRM_EMAIL)

  const [
    filterEmailDates,
    { data: filterEmailDatesData, loading: filterEmailDatesLoading },
  ] = useMutation(FILTER_EMAIL_DATES)

  const { data: emailVerified } = useSubscription(EMAIL_VERIFIED_SUBSCRIPTION, {
    skip,
  })

  useSubscription(ROLE_ADJUSTED_SUBSCRIPTION, {
    onData: onRoleAdjustedEvent,
  })

  const sendConfirmEmail = async () => {
    try {
      await confirmEmail()
      toast({
        title: t('email-conf-link'),
      })
    } catch (e) {
      console.error(e)
    }
  }

  // perform action silent
  const onFilterEmailDates = async (
    dates: number[],
    morning: boolean = false
  ) => {
    await filterEmailDates({
      variables: {
        emailFilteredDates: dates,
        morning,
      },
    }).catch((e: any) => {
      // TODO: error handling
      toast({
        title: e?.message ?? 'An error occured with filtering dates.',
      })
      console.error(e)
    })
  }

  const u = data || profile

  const dataSet = u
    ? {
        ...u,
        user: {
          ...u?.user,
          emailConfirmed: u?.user?.emailConfirmed || emailVerified,
        },
      }
    : {}

  return {
    data: dataSet as { user: User }, // allow data or profile as main source
    forgotPasswordData,
    loaders: {
      loading,
      updateUserLoading,
      forgotPasswordLoading,
      profileLoading,
      settingsLoading,
      resetPasswordLoading,
    },
    loading:
      loading ||
      updateUserLoading ||
      forgotPasswordLoading ||
      profileLoading ||
      settingsLoading ||
      resetPasswordLoading,
    updateUser,
    updateUserData,
    forgotPassword,
    resetPassword,
    resetPasswordData,
    sendConfirmEmail,
    onFilterEmailDates,
    filterEmailDatesData:
      filterEmailDatesData?.filterEmailDates?.emailFilteredDates,
    filterEmailDatesLoading,
    settings,
    refetchSettings: refetchSettings,
    refetchProfile: refetch,
  }
}
