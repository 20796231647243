type ModalVisibleProps = {
  queryModalVisible?: boolean
  sortModalVisible?: boolean
}

export const getDashboardBaseStyle = ({
  sortModalVisible,
  queryModalVisible,
}: ModalVisibleProps) => {
  // conditional display
  let sortStyle = 'hidden'
  let queryStyle = 'hidden'

  if (sortModalVisible) {
    sortStyle = 'visible'
  }

  if (queryModalVisible) {
    queryStyle = 'visible'
  }

  return {
    queryStyle,
    sortStyle,
  }
}

// display type
export const webPageStyle = ({
  sortModalVisible,
  queryModalVisible,
}: ModalVisibleProps) => {
  if (sortModalVisible || queryModalVisible) {
    return 'hidden'
  }
  return 'visible h-full'
}

const gridBase = 'grid grid-flow-row auto-rows-auto'

// dashboard cell styles
export const styles = {
  title: 'text-lg md:text-xl lg:text-3xl truncate',
  spacing: 'py-2',
  row: 'flex flex-1',
  metaBlock: 'px-2 py-1 border',
  grid:
    gridBase +
    ' grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 py-0.5 gap-y-2',
  gridSelect: gridBase + ' grid-cols-2 md:grid-cols-4 py-0.5',
  checkBoxGrid: gridBase + ' grid-cols-2 md:grid-cols-6 py-0.5',
  gridSingle: gridBase + ' py-0.5',
}

export const tableCSS =
  'rounded overflow-hidden bg-gray-50 dark:bg-[rgb(12,13,22)]'
