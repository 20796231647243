import { useQuery } from '@apollo/client'
import { GET_ISSUE } from '@app/queries'
import { GET_WEBSITE_ISSUES } from '@app/queries/websites'
import { AppManager } from '@app/managers'
import { useSearchQuery } from '@app/data/local/useSearchQuery'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

// get a single page issue
export const useIssue = (url?: string, skip?: boolean) => {
  const { data, loading, refetch, error } = useQuery(GET_ISSUE, {
    variables: { url: url ? encodeURIComponent(url) : '' },
    skip: !url || skip,
  })

  return {
    issue: data?.issue,
    loading: loading,
    refetch,
    error,
  }
}

type IssueProps = {
  url?: string | string[]
  all?: boolean
  liveData?: any
  blockLive?: boolean
}

// get issues paginated by website
export const useIssueData = ({ url, all, liveData, blockLive }: IssueProps) => {
  const { search, visible, onSearchEvent, onSearchOpenEvent } = useSearchQuery()
  const [issueIndex, setIndex] = useState<number>(0)
  // Pagination tracking
  const offsetTracking = useRef<{ last: number; next: number }>({
    last: 0,
    next: 0,
  })
  const searchRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    setIndex(0)
  }, [setIndex])

  const variables = { url, limit: 10, offset: 0, all, search }

  const {
    data,
    loading,
    refetch,
    error,
    fetchMore: fetchMorePages,
  } = useQuery(GET_WEBSITE_ISSUES, {
    variables,
    ssr: false,
  })

  const updateQuery = (prev: any, { fetchMoreResult }: any) => {
    if (!fetchMoreResult || !fetchMoreResult?.website?.issues?.length) {
      AppManager.toggleSnack(true, 'No more issues exist.')
      return prev
    }

    const issues = [
      ...(prev?.website?.issues || []),
      ...(fetchMoreResult?.website?.issues || []),
    ]

    return Object.assign({}, prev, {
      website: {
        ...prev?.website,
        issues,
      },
    })
  }

  const issues = data?.website?.issues || []

  // pages page pagination
  const onLoadMore = async () => {
    try {
      await fetchMorePages({
        query: GET_WEBSITE_ISSUES,
        variables: {
          ...variables,
          offset: Number(issues.length || 0),
        },
        updateQuery,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const { issueSource, liveDataExist } = useMemo(() => {
    const isLive = !!liveData?.length

    return {
      issueSource:
        isLive && !search && !blockLive
          ? liveData
          : data?.length
            ? data
            : liveData || [],
      liveDataExist: isLive,
    }
  }, [liveData, data, search, blockLive])

  const onPrevSelect = useCallback(() => {
    if (issueIndex) {
      if (liveDataExist) {
        offsetTracking.current.next = offsetTracking.current.last
      }
      setIndex((x: number) => x - 1)
      // setSelectedIndex(undefined)
    }
  }, [issueIndex, liveDataExist, offsetTracking, setIndex])

  const idx = (issueIndex + 1) * 10
  const blocked = issues.length < idx || issues.length < 10

  const onLoadEvent = useCallback(async () => {
    // get the next set of data
    if (idx === issues.length) {
      onLoadMore && (await onLoadMore())
    }
    setIndex((x: number) => x + 1)
    // setSelectedIndex(undefined)
  }, [idx, issues, onLoadMore, setIndex])

  const onSearchSubmit = async (e: React.FormEvent) => {
    e?.preventDefault()
    if (!search) {
      setIndex(0)
      onLoadMore && (await onLoadMore())
    } else {
      onLoadMore && (await onLoadMore())
    }
  }

  return {
    data: issues,
    loading: loading,
    refetch,
    error,
    onLoadMore,
    visible,
    onSearchEvent,
    onSearchOpenEvent,
    onSearchSubmit,
    searchRef,
    search,
    onPrevSelect,
    onLoadEvent,
    issueSource,
    // fetch more blocked
    blocked,
  }
}
