import { FC } from 'react'
import { IssueCard } from './card/issue-card'
import { PageCard } from './card/pages-card'
import type { WebsiteTableProps } from '@app/types/dashboard'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@app/@/components/ui/tabs'

const tableCSS = `rounded overflow-hidden bg-white dark:bg-black py-1 border`

/* Website table component rendering issues and pages */
export const WebsiteTable: FC<WebsiteTableProps> = ({
  issuesInfo,
  liveData,
  url,
  activeSubscription,
  handleMainClick,
  storageless,
  domain,
  blacklist,
  onSortEvent,
}) => {
  const fetchedData = issuesInfo || (liveData && liveData.length)

  return (
    <div className='py-2.5'>
      <Tabs defaultValue='issues' className='w-full'>
        <TabsList>
          <TabsTrigger value='issues'>Issues</TabsTrigger>
          <TabsTrigger value='pages'>Pages</TabsTrigger>
        </TabsList>
        <TabsContent value='issues'>
          <div className={tableCSS}>
            <IssueCard
              pageUrl={url}
              liveData={liveData}
              activeSubscription={activeSubscription}
              storageless={storageless}
              domain={domain}
              blacklist={blacklist}
              onSortEvent={onSortEvent}
              fetchedData={!!fetchedData}
            />
          </div>
        </TabsContent>
        <TabsContent value='pages'>
          <div className={tableCSS}>
            <PageCard
              pageUrl={url}
              liveData={liveData}
              handleMainClick={handleMainClick}
              fetchedData={!!fetchedData}
              storageless={storageless}
              activeSubscription={activeSubscription}
            />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  )
}
