import { toast } from '@app/@/components/ui/use-toast'
import { Analytic } from '@app/types'
import { fetcher } from '@app/utils/fetcher'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

export type LiveAnalyticsData = {
  domain?: string
  liveData?: Analytic[]
  onlyLive?: boolean
}

// todo: cache / use apollo
const getData = async (domain?: string, page: number = 0) => {
  let eventDS = null

  try {
    eventDS = await fetcher(
      `/list/analytics?limit=250&domain=${domain}&page=${page}`,
      null,
      'GET'
    )
  } catch (e) {
    if (e instanceof Error) {
      toast({
        title: e.message,
      })
    }
  }

  return eventDS
}

// recrusive get data until complete
const getDataUntil = (
  {
    domain,
    setData,
  }: { domain?: string; setData: Dispatch<SetStateAction<Analytic[]>> },
  page = 0
) => {
  queueMicrotask(async () => {
    const res = await getData(domain, page)
    const nextData: Analytic[] = res?.data

    if (nextData && nextData.length) {
      setData((x) => {
        if (x.length) {
          x.push(...nextData)
          return x
        }
        return nextData
      })
      const nextPage = page + 1
      const blocked = nextData?.length < nextPage * 10

      if (!blocked) {
        await getDataUntil({ domain, setData }, nextPage)
      }
    }
  })
}

// get the entire analytics of a website all pages recursive. Todo: stream data or use gql
export const useFullAnalytics = ({
  liveData,
  domain,
  onlyLive,
}: LiveAnalyticsData) => {
  const [analyticsData, setData] = useState<Analytic[]>(liveData ?? [])
  const data = liveData && liveData?.length ? liveData : analyticsData
  const fetched = useRef<boolean>()

  useEffect(() => {
    // live feed will already have the data
    if (!onlyLive && !fetched.current) {
      getDataUntil({ domain, setData })
      fetched.current = true
    }
  }, [domain, onlyLive])

  return {
    data,
    setData,
    fetched,
  }
}
