import { FC, PropsWithChildren } from 'react'
import Link from 'next/link'
import { getDrawerThemeStyles } from './drawer-theme-styles'

// The main drawer link btn
export const DrawerLinkButton: FC<
  PropsWithChildren<{
    active?: boolean
    href: string
    className?: string
    feedOpen?: boolean
    mock?: boolean
  }>
> = ({ children, active, href, className, mock }) => {
  if (mock) {
    return (
      <span className={getDrawerThemeStyles({ active, className })}>
        <>
          {active ? (
            <span className='absolute left-0 h-1/2 bg-blue-500 dark:bg-blue-800 w-1.5 rounded-e-md' />
          ) : null}
          {children}
        </>
      </span>
    )
  }
  return (
    <Link href={href} className={getDrawerThemeStyles({ active, className })}>
      <>
        {active ? (
          <span className='absolute left-0 h-1/2 bg-blue-500 dark:bg-blue-800 w-1.5 rounded-e-md' />
        ) : null}
        {children}
      </>
    </Link>
  )
}
