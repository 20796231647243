import { memo } from 'react'
import { GrAddCircle, GrSubtractCircle } from 'react-icons/gr'
import { Button } from '../buttons'
import { Input } from '@app/@/components/ui/input'

// dynamic headers for forms
export function InputHeadersComponent({
  customHeader,
  customFields,
  removeFormField,
  addFormField,
  updateFormField,
}: any) {
  // render if customHeader exist
  if (customHeader) {
    return (
      <ul className='list-none py-2 px-1'>
        {customFields?.map((item: any, index: number) => {
          const inputKeyName = 'Key'
          const inputValueName = 'Value'

          return (
            <li className={`flex gap-1`} key={index}>
              <Input
                autoFocus
                value={item.key}
                placeholder={inputKeyName}
                required
                className='w-20 max-w-[45vw] md:w-auto'
                onChange={(event: any) =>
                  updateFormField(
                    event.target.value,
                    index,
                    inputKeyName.toLowerCase()
                  )
                }
              />
              <Input
                className='w-20 max-w-[45vw] md:w-auto'
                value={item?.value}
                placeholder={inputValueName}
                onChange={(event: any) =>
                  updateFormField(
                    event.target.value,
                    index,
                    inputValueName.toLowerCase()
                  )
                }
                required
              />
              {customFields?.length > 1 ? (
                <Button
                  onClick={() => removeFormField(index)}
                  iconButton
                  border={false}
                  className='hover:text-blue-600 active:text-blue-700 dark:hover:text-blue-600'
                >
                  <>
                    <span className='sr-only'>Remove Header</span>
                    <GrSubtractCircle className='grIcon' />
                  </>
                </Button>
              ) : null}
              {index === customFields?.length - 1 ? (
                <Button
                  onClick={addFormField}
                  iconButton
                  border={false}
                  className='hover:text-blue-600 active:text-blue-700 dark:hover:text-blue-600'
                >
                  <>
                    <span className='sr-only'>Add Header</span>
                    <GrAddCircle className='grIcon' />
                  </>
                </Button>
              ) : null}
            </li>
          )
        })}
      </ul>
    )
  }
  return null
}

export const InputHeaders = memo(InputHeadersComponent)
