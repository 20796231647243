import { ChangeEventHandler } from 'react'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { InfoBlock } from '../cells'
import { inputStyle } from '../cells/blocks/input-style'
import { InputBoxProps } from '@app/types/dashboard'
import { Input } from '@app/@/components/ui/input'

interface CrawlLimitProps extends InputBoxProps {
  url?: string
  value?: number
  activeSubscription?: boolean
  demo?: boolean
  tooltip?: boolean
  translation?: string
  id?: string
}

interface CrawlLimitInputProps extends CrawlLimitProps {
  onChange?: ChangeEventHandler<HTMLInputElement>
}

export const CrawlLimitBoxInput = ({
  value,
  url,
  activeSubscription,
  tooltip = true,
  translation,
  onChange,
  id,
  dialog,
  defaultValue,
}: CrawlLimitInputProps) => {
  const { t } = useTranslation(translation || 'dashboard')

  const crawlD = `${url || id}-crawl-limit-id`
  const crawlDl = `${url || id}-crawl-limit-list-id`

  const crawlText = t('crawl-limit')

  return (
    <InfoBlock
      tipID={`${url || id}-limit-id-tip`}
      toolTip={tooltip ? t(`dashboard-inputs:climit-tip`) : undefined}
      noPx={dialog}
      labelTip={<label htmlFor={crawlD}>{crawlText}</label>}
      noClass
    >
      <Input
        className={inputStyle(activeSubscription, dialog)}
        onChange={onChange}
        defaultValue={defaultValue}
        id={crawlD}
        placeholder={crawlText}
        type='number'
        max={1000000}
        value={value}
        min={0}
        list={crawlDl}
        name={'crawl_limit'}
      />
      <datalist id={crawlDl}>
        <option value='0'></option>
        <option value='250'></option>
        <option value='500'></option>
        <option value='1000'></option>
        <option value='1500'></option>
        <option value='5000'></option>
        <option value='10000'></option>
        <option value='20000'></option>
        <option value='50000'></option>
        <option value='100000'></option>
        <option value='200000'></option>
        <option value='500000'></option>
        <option value='1000000'></option>
      </datalist>
    </InfoBlock>
  )
}
