import React from 'react'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { inputStyle } from '../cells/blocks/input-style'
import { InfoBlock } from '../cells'
import { InputBoxProps } from '@app/types/dashboard'
import { Input } from '@app/@/components/ui/input'

interface AgentProps extends InputBoxProps {
  ua?: string
  id?: string
}

// the agent input
export const AgentInput = ({
  activeSubscription,
  onChangeUA,
  ua,
  translation,
  tooltip = true,
  url,
  id,
  dialog,
  defaultValue,
}: AgentProps & { onChangeUA?(x: any): any }) => {
  const { t } = useTranslation(translation || 'dashboard')

  const agentLabel = t('user-agent')

  const uaLabel = `${url || id}-ua-id`

  return (
    <InfoBlock
      tipID={`${url}-agent-id`}
      toolTip={tooltip ? t('dashboard-inputs:agent-tip') : undefined}
      noPx={dialog}
      labelTip={<label htmlFor={uaLabel}>{agentLabel}</label>}
      noClass
    >
      <Input
        className={inputStyle(activeSubscription, dialog)}
        onChange={onChangeUA}
        value={ua}
        id={uaLabel}
        placeholder={agentLabel}
        type='text'
        name={'user_agent'}
        defaultValue={defaultValue}
      />
    </InfoBlock>
  )
}
