import { memo } from 'react'
import {
  SitemapKeys,
  SiteMapOption,
  SitemapSelectInput,
} from '@app/components/general/select/sitemap-input'
import { InfoBlock } from '../cells'
import { inputContainerStyle } from './css'
import type { Translate } from 'next-translate'

const SitemapInputWrapper = ({
  sitemap,
  url,
  domain,
  t,
  activeSubscription,
  onChange,
  defaultValue,
}: {
  sitemap?: number
  url?: string
  domain?: string
  activeSubscription?: boolean
  onChange?: any
  t?: Translate
  defaultValue?: SitemapKeys | number
}) => {
  const labelId = `${domain || url}-sitemap-form`

  return (
    <InfoBlock>
      <div className={inputContainerStyle}>
        <SitemapSelectInput
          onSitemapChange={onChange}
          sitemap={SiteMapOption[sitemap || 0] as SitemapKeys}
          disabled={!activeSubscription}
          label={t ? t('sitemap') : undefined}
          labelOptions={t ? t('sitemap-options') : undefined}
          id={labelId}
          t={t}
          borderLess
          defaultValue={defaultValue}
        />
      </div>
    </InfoBlock>
  )
}

export const SitemapInput = memo(SitemapInputWrapper)
