import { FC, PropsWithChildren } from 'react'
import { getDrawerThemeStyles } from './drawer-theme-styles'
import { ThemeSwitcher } from '../../themes/theme-switcher'

// The main drawer link btn
export const DrawerThemeButton: FC<
  PropsWithChildren<{
    active?: boolean
    className?: string
    openDrawer?: boolean
    mock?: boolean
  }>
> = ({ children, active, className, openDrawer }) => {
  return (
    <div className={getDrawerThemeStyles({ active, className, nohover: true })}>
      <>
        {active ? (
          <span className='absolute left-0 h-1/2 bg-blue-500 dark:bg-blue-800 w-1.5 rounded-e-md' />
        ) : null}
        <div className='flex gap-3 items-center'>{children}</div>
        {openDrawer ? null : <ThemeSwitcher />}
      </>
    </div>
  )
}
