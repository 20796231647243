import { FC, PropsWithChildren } from 'react'
import { Button } from '../general'
import { useToast } from '@app/@/components/ui/use-toast'
// import { LightAsync } from 'react-syntax-highlighter'

// Syntax highlight light build
const WithSyntaxDashboard: FC<PropsWithChildren<any>> = ({ children, t }) => {
  const { toast } = useToast()

  const onCopyClickEvent = (
    _e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(children)
      toast({ title: t('dashboard:copied'), description: children })
    }
  }

  return (
    <div className='bg-black py-2 px-3 rounded-b dark:bg-gray-800'>
      <div className='flex items-center gap-3 place-content-between py-1'>
        <div className='flex items-center gap-2'>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8.00065 15.1866C7.54065 15.1866 7.10733 14.9533 6.80066 14.5466L5.80066 13.2133C5.78066 13.1866 5.70065 13.1532 5.66732 13.1466H5.33398C2.55398 13.1466 0.833984 12.3932 0.833984 8.64657V5.31323C0.833984 2.36657 2.38732 0.813232 5.33398 0.813232H10.6673C13.614 0.813232 15.1673 2.36657 15.1673 5.31323V8.64657C15.1673 11.5932 13.614 13.1466 10.6673 13.1466H10.334C10.2807 13.1466 10.234 13.1733 10.2006 13.2133L9.20064 14.5466C8.89398 14.9533 8.46065 15.1866 8.00065 15.1866ZM5.33398 1.81323C2.94732 1.81323 1.83398 2.92657 1.83398 5.31323V8.64657C1.83398 11.6599 2.86732 12.1466 5.33398 12.1466H5.66732C6.00732 12.1466 6.39398 12.3399 6.60065 12.6132L7.60065 13.9466C7.83398 14.2532 8.16732 14.2532 8.40066 13.9466L9.40066 12.6132C9.62066 12.3199 9.96732 12.1466 10.334 12.1466H10.6673C13.054 12.1466 14.1673 11.0332 14.1673 8.64657V5.31323C14.1673 2.92657 13.054 1.81323 10.6673 1.81323H5.33398Z'
              fill='white'
            />
            <path
              d='M5.33362 8.96653C5.20695 8.96653 5.08029 8.91985 4.98029 8.81985L3.64695 7.48651C3.45362 7.29318 3.45362 6.97318 3.64695 6.77985L4.98029 5.44651C5.17362 5.25318 5.49362 5.25318 5.68695 5.44651C5.88028 5.63985 5.88028 5.95985 5.68695 6.15318L4.70695 7.1332L5.68695 8.11318C5.88028 8.30651 5.88028 8.62651 5.68695 8.81985C5.58695 8.91985 5.46029 8.96653 5.33362 8.96653Z'
              fill='white'
            />
            <path
              d='M10.6663 8.96653C10.5396 8.96653 10.413 8.91985 10.313 8.81985C10.1196 8.62651 10.1196 8.30651 10.313 8.11318L11.293 7.1332L10.313 6.15318C10.1196 5.95985 10.1196 5.63985 10.313 5.44651C10.5063 5.25318 10.8263 5.25318 11.0196 5.44651L12.353 6.77985C12.5463 6.97318 12.5463 7.29318 12.353 7.48651L11.0196 8.81985C10.9196 8.91985 10.793 8.96653 10.6663 8.96653Z'
              fill='white'
            />
            <path
              d='M7.33292 9.18676C7.26625 9.18676 7.1996 9.17339 7.13293 9.14672C6.8796 9.04006 6.75959 8.74673 6.87292 8.48673L8.20625 5.37341C8.31292 5.12007 8.60625 5.00006 8.86625 5.1134C9.11958 5.22006 9.23959 5.51339 9.12626 5.77339L7.79292 8.88675C7.71292 9.07342 7.52625 9.18676 7.33292 9.18676Z'
              fill='white'
            />
          </svg>
          <p className='text-white text-xs'>Code</p>
        </div>
        <Button className='flex items-center gap-2' onClick={onCopyClickEvent}>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7.60065 15.1667H5.06732C2.14065 15.1667 0.833984 13.8601 0.833984 10.9334V8.40008C0.833984 5.47342 2.14065 4.16675 5.06732 4.16675H7.06732C7.34065 4.16675 7.56732 4.39341 7.56732 4.66675C7.56732 4.94008 7.34065 5.16675 7.06732 5.16675H5.06732C2.68065 5.16675 1.83398 6.01342 1.83398 8.40008V10.9334C1.83398 13.3201 2.68065 14.1667 5.06732 14.1667H7.60065C9.98732 14.1667 10.834 13.3201 10.834 10.9334V8.93341C10.834 8.66008 11.0607 8.43341 11.334 8.43341C11.6073 8.43341 11.834 8.66008 11.834 8.93341V10.9334C11.834 13.8601 10.5273 15.1667 7.60065 15.1667Z'
              fill='white'
            />
            <path
              d='M11.3331 9.43341H9.19974C7.32641 9.43341 6.56641 8.67341 6.56641 6.80007V4.66674C6.56641 4.46674 6.68641 4.28007 6.87307 4.20674C7.05974 4.12674 7.27307 4.17341 7.41974 4.31341L11.6864 8.58007C11.8264 8.72007 11.8731 8.94007 11.7931 9.12674C11.7197 9.31341 11.5331 9.43341 11.3331 9.43341ZM7.56641 5.87341V6.80007C7.56641 8.12674 7.87307 8.43341 9.19974 8.43341H10.1264L7.56641 5.87341Z'
              fill='white'
            />
            <path
              d='M10.3991 1.83325H7.73242C7.45909 1.83325 7.23242 1.60659 7.23242 1.33325C7.23242 1.05992 7.45909 0.833252 7.73242 0.833252H10.3991C10.6724 0.833252 10.8991 1.05992 10.8991 1.33325C10.8991 1.60659 10.6724 1.83325 10.3991 1.83325Z'
              fill='white'
            />
            <path
              d='M4.66602 3.83325C4.39268 3.83325 4.16602 3.60659 4.16602 3.33325C4.16602 1.95325 5.28602 0.833252 6.66602 0.833252H8.41268C8.68602 0.833252 8.91268 1.05992 8.91268 1.33325C8.91268 1.60659 8.68602 1.83325 8.41268 1.83325H6.66602C5.83935 1.83325 5.16602 2.50659 5.16602 3.33325C5.16602 3.60659 4.93935 3.83325 4.66602 3.83325Z'
              fill='white'
            />
            <path
              d='M12.793 11.8333C12.5196 11.8333 12.293 11.6066 12.293 11.3333C12.293 11.0599 12.5196 10.8333 12.793 10.8333C13.553 10.8333 14.1663 10.2133 14.1663 9.45992V5.33325C14.1663 5.05992 14.393 4.83325 14.6663 4.83325C14.9396 4.83325 15.1663 5.05992 15.1663 5.33325V9.45992C15.1663 10.7666 14.0996 11.8333 12.793 11.8333Z'
              fill='white'
            />
            <path
              d='M14.666 5.83324H12.666C10.8927 5.83324 10.166 5.10658 10.166 3.33324V1.33324C10.166 1.13324 10.286 0.946578 10.4727 0.873244C10.6593 0.793244 10.8727 0.839911 11.0193 0.979911L15.0193 4.97991C15.1593 5.11991 15.206 5.33991 15.126 5.52658C15.0527 5.71324 14.866 5.83324 14.666 5.83324ZM11.166 2.53991V3.33324C11.166 4.55324 11.446 4.83324 12.666 4.83324H13.4593L11.166 2.53991Z'
              fill='white'
            />
          </svg>
          <span className='text-white text-xs'>
            {typeof t === 'function' ? t('copy-tag') : 'Copy Text'}
          </span>
        </Button>
      </div>
      <pre
        className={
          'text-sm bg-transparent text-gray-200 truncate overflow-hidden max-h-28 py-1'
        }
      >
        <code className='whitespace-pre'>{children}</code>
      </pre>
    </div>
  )
}

export { WithSyntaxDashboard }
