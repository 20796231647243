import { useState, useEffect, useMemo } from 'react'
import { Chip } from './chip'
import { GrTime } from 'react-icons/gr'
import { Translate } from 'next-translate'
import { twMerge } from 'tailwind-merge'

export const Timer = ({
  stop,
  duration,
  shutdown,
  noui,
  t,
  lang,
  onlyTime,
}: {
  stop?: boolean
  duration?: number
  shutdown?: boolean
  noui?: boolean
  lang?: string
  onlyTime?: boolean
  t: Translate
}) => {
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1)
    }, 1000)

    if (stop) {
      clearInterval(interval)
    } else if (!stop && duration) {
      setSeconds(0)
    }

    return () => clearInterval(interval)
  }, [stop, duration])

  const rtf = useMemo(() => {
    const _lang = lang === 'ar' ? 'en' : lang

    try {
      return new Intl.RelativeTimeFormat(_lang, {
        numeric: 'auto',
        style: 'narrow',
        localeMatcher: 'best fit',
      })
    } catch (e) {
      return new Intl.RelativeTimeFormat(_lang, {
        numeric: 'auto',
        style: 'narrow',
        localeMatcher: 'best fit',
      })
    }
  }, [lang])

  const timeDisplay = useMemo(() => {
    const defaultTime = duration ? Number(duration / 1000) : 0
    const defaultDuration = seconds ? seconds : defaultTime

    const parts = rtf.formatToParts(
      typeof duration !== 'undefined'
        ? Math.trunc(defaultDuration)
        : defaultDuration,
      'seconds'
    )

    return (
      parts
        .flatMap((item, index) => {
          if (item.type === 'literal' && !index) {
            return ''
          }
          return item.value
        })
        .join('') || '0'
    )
  }, [duration, seconds, rtf])

  const crawlText = t('report-extra:c-duration', { duration: timeDisplay })

  if (onlyTime) {
    return <>{crawlText}</>
  }

  if (noui) {
    return (
      <Chip
        avatar={<GrTime className={'grIcon'} />}
        label={timeDisplay}
        title={crawlText}
        borderLess={true}
        className={shutdown ? 'text-red-700 dark:text-red-600' : ''}
      />
    )
  }

  return (
    <div className='px-3 py-1 rounded-3xl bg-gray-200 dark:bg-gray-800 min-w-[3.5rem]'>
      <div
        className={twMerge(
          `justify-center flex truncate`,
          seconds > 100 || timeDisplay.length >= 4 ? 'text-[.7rem]' : 'text-sm',
          shutdown ? 'text-red-700' : ''
        )}
      >
        {timeDisplay}
      </div>
    </div>
  )
}
